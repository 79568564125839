export const countries = [
  {
    callingCode: '+376',
    cca2: 'AD',
    flag: '🇦🇩',
    name: {
      bg: 'Андора',
      by: 'Андора',
      cn: '安道尔',
      cz: 'Andorra',
      de: 'Andorra',
      ee: 'Andorra',
      el: 'Ανδόρρα',
      en: 'Andorra',
      es: 'Andorra',
      fr: 'Andorre',
      he: 'אנדורה',
      it: 'Andorra',
      jp: 'アンドラ',
      nl: 'Andorra',
      pl: 'Andora',
      pt: 'Andorra',
      ro: 'Andorra',
      ru: 'Андорра',
      ua: 'Андорра',
      zh: '安道爾',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+971',
    cca2: 'AE',
    flag: '🇦🇪',
    name: {
      bg: 'Обединени арабски емирства',
      by: 'Абяднаныя Арабскія Эміраты',
      cn: '阿拉伯联合酋长国',
      cz: 'Spojené arabské emiráty',
      de: 'Vereinigte Arabische Emirate',
      ee: 'Araabia Ühendemiraadid',
      el: 'Ηνωμένα Αραβικά Εμιράτα',
      en: 'United Arab Emirates',
      es: 'Emiratos Árabes Unidos',
      fr: 'Emirats Arabes Unis',
      he: 'איחוד האמירויות הערביות',
      it: 'Emirati Arabi Uniti',
      jp: 'アラブ首長国連邦',
      nl: 'Verenigde Arabische Emiraten',
      pl: 'Zjednoczone Emiraty Arabskie',
      pt: 'Emiratos Árabes Unidos',
      ro: 'Emiratele Arabe Unite',
      ru: 'Объединенные Арабские Эмираты',
      ua: "Об'єднані Арабські Емірати",
      zh: '阿拉伯聯合酋長國',
    },
    phoneMasks: ['# ### ####', '## ### ####'],
  },
  {
    callingCode: '+93',
    cca2: 'AF',
    flag: '🇦🇫',
    name: {
      bg: 'Афганистан',
      by: 'Афганістан',
      cn: '阿富汗',
      cz: 'Afghánistán',
      de: 'Afghanistan',
      ee: 'Afganistan',
      el: 'Αφγανιστάν',
      en: 'Afghanistan',
      es: 'Afganistán',
      fr: "L'Afghanistan",
      he: 'אפגניסטן',
      it: 'Afghanistan',
      jp: 'アフガニスタン',
      nl: 'Afghanistan',
      pl: 'Afganistan',
      pt: 'Afeganistão',
      ro: 'Afganistan',
      ru: 'Афганистан',
      ua: 'Афганістан',
      zh: '阿富汗',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+1',
    cca2: 'AG',
    flag: '🇦🇬',
    name: {
      bg: 'Антигуа и Барбуда',
      by: 'Антыгуа і Барбуда',
      cn: '安提瓜和巴布达',
      cz: 'Antigua a Barbuda',
      de: 'Antigua und Barbuda',
      ee: 'Antigua ja Barbuda',
      el: 'Αντίγκουα και Μπαρμπούντα',
      en: 'Antigua and Barbuda',
      es: 'Antigua y Barbuda',
      fr: 'Antigua-et-Barbuda',
      he: 'אנטיגואה וברבודה',
      it: 'Antigua e Barbuda',
      jp: 'アンティグアバーブーダ',
      nl: 'Antigua en Barbuda',
      pl: 'Antigua i Barbuda',
      pt: 'Antiga e Barbuda',
      ro: 'Antigua si Barbuda',
      ru: 'Антигуа и Барбуда',
      ua: 'Антигуа та Барбуда',
      zh: '安提瓜和巴布達',
    },
    phoneMasks: ['268 ### ####'],
  },
  {
    callingCode: '+1',
    cca2: 'AI',
    flag: '🇦🇮',
    name: {
      bg: 'Ангила',
      by: 'Ангілья',
      cn: '安圭拉岛',
      cz: 'Anguilla',
      de: 'Anguilla',
      ee: 'Anguilla',
      el: 'Ανγκουίλα',
      en: 'Anguilla',
      es: 'Anguila',
      fr: 'Anguilla',
      he: 'אנגווילה',
      it: 'Anguilla',
      jp: 'アンギラ',
      nl: 'Anguilla',
      pl: 'Anguilla',
      pt: 'Anguila',
      ro: 'Anguilla',
      ru: 'Ангилья',
      ua: 'Ангілья',
      zh: '安圭拉島',
    },
    phoneMasks: ['264 ### ####'],
  },
  {
    callingCode: '+355',
    cca2: 'AL',
    flag: '🇦🇱',
    name: {
      bg: 'Албания',
      by: 'Албанія',
      cn: '阿尔巴尼亚',
      cz: 'Albánie',
      de: 'Albanien',
      ee: 'Albaania',
      el: 'Αλβανία',
      en: 'Albania',
      es: 'Albania',
      fr: 'Albanie',
      he: 'אלבניה',
      it: 'Albania',
      jp: 'アルバニア',
      nl: 'Albanië',
      pl: 'Albania',
      pt: 'Albânia',
      ro: 'Albania',
      ru: 'Албания',
      ua: 'Албанія',
      zh: '阿爾巴尼亞',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+374',
    cca2: 'AM',
    flag: '🇦🇲',
    name: {
      bg: 'Армения',
      by: 'Арменія',
      cn: '亚美尼亚',
      cz: 'Arménie',
      de: 'Armenien',
      ee: 'Armeenia',
      el: 'Αρμενία',
      en: 'Armenia',
      es: 'Armenia',
      fr: 'Arménie',
      he: 'ארמניה',
      it: 'Armenia',
      jp: 'アルメニア',
      nl: 'Armenië',
      pl: 'Armenia',
      pt: 'Arménia',
      ro: 'Armenia',
      ru: 'Армения',
      ua: 'Вірменія',
      zh: '亞美尼亞',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+599',
    cca2: 'AN',
    flag: '🇦🇳',
    name: {
      bg: 'Холандски Антили',
      by: 'Нідэрландскія Антыльскія астравы',
      cn: '荷属安的列斯',
      cz: 'Nizozemské Antily',
      de: 'Niederländische Antillen',
      ee: 'Hollandi Antillid',
      el: 'Ολλανδικές Αντίλλες',
      en: 'Netherlands Antilles',
      es: 'Antillas Neerlandesas',
      fr: 'Antilles néerlandaises',
      he: 'האנטילים ההולנדיים',
      it: 'Antille Olandesi',
      jp: 'オランダ領アンティル',
      nl: 'Nederlandse Antillen',
      pl: 'Antyle Holenderskie',
      pt: 'Antilhas Neerlandesas',
      ro: 'Antilele Olandeze',
      ru: 'Нидерландские Антильские острова',
      ua: 'Нідерландські Антильські острови',
      zh: '荷屬安的列斯',
    },
    phoneMasks: ['### ####'],
  },
  {
    callingCode: '+244',
    cca2: 'AO',
    flag: '🇦🇴',
    name: {
      bg: 'Ангола',
      by: 'Ангола',
      cn: '安哥拉',
      cz: 'Angola',
      de: 'Angola',
      ee: 'Angola',
      el: 'Ανγκόλα',
      en: 'Angola',
      es: 'Angola',
      fr: "L'Angola",
      he: 'אנגולה',
      it: 'Angola',
      jp: 'アンゴラ',
      nl: 'Angola',
      pl: 'Angola',
      pt: 'Angola',
      ro: 'Angola',
      ru: 'Ангола',
      ua: 'Ангола',
      zh: '安哥拉',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+672',
    cca2: 'AQ',
    flag: '🇦🇶',
    name: {
      bg: 'Антарктида',
      by: 'Антарктыда',
      cn: '南极洲',
      cz: 'Antarktida',
      de: 'Antarktis',
      ee: 'Antarktika',
      el: 'Ανταρκτική',
      en: 'Antarctica',
      es: 'Antártida',
      fr: 'Antarctique',
      he: 'אנטארקטיקה',
      it: 'Antartide',
      jp: '南極大陸',
      nl: 'Antarctica',
      pl: 'Antarktyda',
      pt: 'Antarctica',
      ro: 'Antarctica',
      ru: 'Антарктида',
      ua: 'Антарктида',
      zh: '南極洲',
    },
    phoneMasks: [
      '10 ####',
      '11 ####',
      '12 ####',
      '13 ####',
      '14 ####',
    ],
  },
  {
    callingCode: '+54',
    cca2: 'AR',
    flag: '🇦🇷',
    name: {
      bg: 'Аржентина',
      by: 'Аргентына',
      cn: '阿根廷',
      cz: 'Argentina',
      de: 'Argentinien',
      ee: 'Argentina',
      el: 'Αργεντινή',
      en: 'Argentina',
      es: 'Argentina',
      fr: 'Argentine',
      he: 'ארגנטינה',
      it: 'Argentina',
      jp: 'アルゼンチン',
      nl: 'Argentinië',
      pl: 'Argentyna',
      pt: 'Argentina',
      ro: 'Argentina',
      ru: 'Аргентина',
      ua: 'Аргентина',
      zh: '阿根廷',
    },
    phoneMasks: ['### ### ####'],
  },
  {
    callingCode: '+1',
    cca2: 'AS',
    flag: '🇦🇸',
    name: {
      bg: 'Американска Самоа',
      by: 'Амерыканскае Самоа',
      cn: '美属萨摩亚',
      cz: 'Americká Samoa',
      de: 'Amerikanischen Samoa-Inseln',
      ee: 'Ameerika Samoa',
      el: 'Αμερικανική Σαμόα',
      en: 'American Samoa',
      es: 'Samoa Americana',
      fr: 'Samoa américaines',
      he: 'סמואה האמריקנית',
      it: 'Samoa americane',
      jp: 'アメリカ領サモア',
      nl: 'Amerikaans Samoa',
      pl: 'Samoa Amerykańskie',
      pt: 'Samoa',
      ro: 'Samoa Americană',
      ru: 'Американское Самоа',
      ua: 'Американське Самоа',
      zh: '美屬薩摩亞',
    },
    phoneMasks: ['684 ### ####'],
  },
  {
    callingCode: '+43',
    cca2: 'AT',
    flag: '🇦🇹',
    name: {
      bg: 'Австрия',
      by: 'Аўстрыя',
      cn: '奥地利',
      cz: 'Rakousko',
      de: 'Österreich',
      ee: 'Austria',
      el: 'Αυστρία',
      en: 'Austria',
      es: 'Austria',
      fr: "L'Autriche",
      he: 'אוסטריה',
      it: 'Austria',
      jp: 'オーストリア',
      nl: 'Oostenrijk',
      pl: 'Austria',
      pt: 'Áustria',
      ro: 'Austria',
      ru: 'Австрия',
      ua: 'Австрія',
      zh: '奧地利',
    },
    phoneMasks: ['### ### ####'],
  },
  {
    callingCode: '+61',
    cca2: 'AU',
    flag: '🇦🇺',
    name: {
      bg: 'Австралия',
      by: 'Аўстралія',
      cn: '澳大利亚',
      cz: 'Austrálie',
      de: 'Australien',
      ee: 'Austraalia',
      el: 'Αυστραλία',
      en: 'Australia',
      es: 'Australia',
      fr: 'Australie',
      he: 'אוסטרליה',
      it: 'Australia',
      jp: 'オーストラ リア',
      nl: 'Australië',
      pl: 'Australia',
      pt: 'Austrália',
      ro: 'Australia',
      ru: 'Австралия',
      ua: 'Австралія',
      zh: '澳大利亞',
    },
    phoneMasks: [
      '2 #### ####',
      '3 #### ####',
      '4## ### ###',
      '7 #### ####',
    ],
  },
  {
    callingCode: '+297',
    cca2: 'AW',
    flag: '🇦🇼',
    name: {
      bg: 'Аруба',
      by: 'Аруба',
      cn: '阿鲁巴岛',
      cz: 'Aruba',
      de: 'Aruba',
      ee: 'Aruba',
      el: 'Αρούμπα',
      en: 'Aruba',
      es: 'Aruba',
      fr: 'Aruba',
      he: 'ארובה',
      it: 'Aruba',
      jp: 'アルバ',
      nl: 'Aruba',
      pl: 'Aruba',
      pt: 'Aruba',
      ro: 'Aruba',
      ru: 'Аруба',
      ua: 'Аруба',
      zh: '阿魯巴島',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+358',
    cca2: 'AX',
    flag: '🇦🇽',
    name: {
      bg: 'Аландски острови',
      by: 'Аландскія астравы',
      cn: '奥兰群岛',
      cz: 'Alandy',
      de: 'Aland Islands',
      ee: 'Alandi saared',
      el: 'Νήσοι Άλαντ',
      en: 'Åland Islands',
      es: 'Islas Åland',
      fr: 'Iles Aland',
      he: 'איי אלנד',
      it: 'Isole Aland',
      jp: 'オーランド諸島',
      nl: 'Aland-eilanden',
      pl: 'Wyspy Alandzkie',
      pt: 'Ilhas Aland',
      ro: 'Insulele Aland',
      ru: 'Аландские острова',
      ua: 'Аландські острови',
      zh: '奧蘭群島',
    },
    phoneMasks: ['18 ### ##', '18 ## ## ##'],
  },
  {
    callingCode: '+994',
    cca2: 'AZ',
    flag: '🇦🇿',
    name: {
      bg: 'Азербайджан',
      by: 'Азербайджан',
      cn: '阿塞拜疆',
      cz: 'Ázerbajdžán',
      de: 'Aserbaidschan',
      ee: 'Aserbaidžaan',
      el: 'Αζερμπαϊτζάν',
      en: 'Azerbaijan',
      es: 'Azerbaiyán',
      fr: 'Azerbaïdjan',
      he: "אזרבייג'ן",
      it: 'Azerbaigian',
      jp: 'アゼルバイジャン',
      nl: 'Azerbeidzjan',
      pl: 'Azerbejdżan',
      pt: 'Azerbaijão',
      ro: 'Azerbaidjan',
      ru: 'Азербайджан',
      ua: 'Азербайджан',
      zh: '阿塞拜疆',
    },
    phoneMasks: ['## ### ## ##'],
  },
  {
    callingCode: '+387',
    cca2: 'BA',
    flag: '🇧🇦',
    name: {
      bg: 'Босна и Херцеговина',
      by: 'Боснія і Герцагавіна',
      cn: '波斯尼亚和黑塞哥维那（简称：波黑',
      cz: 'Bosna a Hercegovina',
      de: 'Bosnien und Herzegowina',
      ee: 'Bosnia ja Hertsegoviina',
      el: 'Βοσνία και Ερζεγοβίνη',
      en: 'Bosnia and Herzegovina',
      es: 'Bosnia y Herzegovina',
      fr: 'Bosnie Herzégovine',
      he: 'בוסניה והרצגובינה',
      it: 'Bosnia Erzegovina',
      jp: 'ボスニア・ヘ ルツェゴビナ',
      nl: 'Bosnië-Herzegovina',
      pl: 'Bośnia i Hercegowina',
      pt: 'Bósnia e Herzegovina',
      ro: 'Bosnia si Hertegovina',
      ru: 'Босния и Герцеговина',
      ua: 'Боснія і Герцеговина',
      zh: '波 斯尼亞和黑塞哥維那（簡稱：波黑',
    },
    phoneMasks: ['## ####', '## #####'],
  },
  {
    callingCode: '+1',
    cca2: 'BB',
    flag: '🇧🇧',
    name: {
      bg: 'Барбадос',
      by: 'Барбадас',
      cn: '巴巴多斯',
      cz: 'Barbados',
      de: 'Barbados',
      ee: 'Barbados',
      el: 'Μπαρμπάντος',
      en: 'Barbados',
      es: 'Barbados',
      fr: 'Barbade',
      he: 'ברבדוס',
      it: 'Barbados',
      jp: 'バルバドス',
      nl: 'Barbados',
      pl: 'Barbados',
      pt: 'Barbados',
      ro: 'Barbados',
      ru: 'Барбадос',
      ua: 'Барбадос',
      zh: '巴巴多斯',
    },
    phoneMasks: ['246 ### ####'],
  },
  {
    callingCode: '+880',
    cca2: 'BD',
    flag: '🇧🇩',
    name: {
      bg: 'Бангладеш',
      by: 'Бангладэш',
      cn: ' 孟加拉国',
      cz: 'Bangladéš',
      de: 'Bangladesch',
      ee: 'Bangladesh',
      el: 'Μπανγκλαντές',
      en: 'Bangladesh',
      es: 'Bangladesh',
      fr: 'Bangladesh',
      he: 'בנגלדש',
      it: 'Bangladesh',
      jp: 'バングラデシュ',
      nl: 'Bangladesh',
      pl: 'Bangladesz',
      pt: 'Bangladeche',
      ro: 'Bangladesh',
      ru: 'Бангладеш',
      ua: 'Бангладеш',
      zh: '孟加拉國',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+32',
    cca2: 'BE',
    flag: '🇧🇪',
    name: {
      bg: 'Белгия',
      by: 'Бельгія',
      cn: '比 利时',
      cz: 'Belgie',
      de: 'Belgien',
      ee: 'Belgia',
      el: 'Βέλγιο',
      en: 'Belgium',
      es: 'Bélgica',
      fr: 'Belgique',
      he: 'בלגיה',
      it: 'Belgio',
      jp: 'ベルギー',
      nl: 'Belgie',
      pl: 'Belgia',
      pt: 'Bélgica',
      ro: 'Belgia',
      ru: 'Бельгия',
      ua: 'Бельгія',
      zh: '比利時',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+226',
    cca2: 'BF',
    flag: '🇧🇫',
    name: {
      bg: 'Буркина Фасо',
      by: 'Буркіна-Фасо',
      cn: '布基纳法索',
      cz: 'Burkina Faso',
      de: 'Burkina Faso',
      ee: 'Burkina Faso',
      el: 'Μπουρκίνα Φάσο',
      en: 'Burkina Faso',
      es: 'Burkina Faso',
      fr: 'Burkina Faso',
      he: 'בורקינה פאסו',
      it: 'Burkina Faso',
      jp: 'ブルキナファソ',
      nl: 'Burkina Faso',
      pl: 'Burkina Faso',
      pt: 'Burquina Faso',
      ro: 'Burkina Faso',
      ru: 'Буркина-Фасо',
      ua: 'Буркіна-Фасо',
      zh: '布基納法索',
    },
    phoneMasks: ['## ## ####'],
  },
  {
    callingCode: '+359',
    cca2: 'BG',
    flag: '🇧🇬',
    name: {
      bg: 'България',
      by: 'Балгарыя',
      cn: '保加利亚',
      cz: 'Bulharsko',
      de: 'Bulgarien',
      ee: 'Bulgaaria',
      el: 'Βουλγαρία',
      en: 'Bulgaria',
      es: 'Bulgaria',
      fr: 'Bulgarie',
      he: 'בולגריה',
      it: 'Bulgaria',
      jp: 'ブルガリア',
      nl: 'Bulgarije',
      pl: 'Bułgaria',
      pt: 'Bulgária',
      ro: 'Bulgaria',
      ru: 'Болгария',
      ua: 'Болгарія',
      zh: '保加利亞',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+973',
    cca2: 'BH',
    flag: '🇧🇭',
    name: {
      bg: 'Бахрейн',
      by: 'Бахрэйн',
      cn: '巴林',
      cz: 'Bahrajn',
      de: 'Bahrain',
      ee: 'Bahrein',
      el: 'Μπαχρέιν',
      en: 'Bahrain',
      es: 'Bahrein',
      fr: 'Bahreïn',
      he: 'בחריין',
      it: 'Bahrein',
      jp: 'バーレーン',
      nl: 'Bahrein',
      pl: 'Bahrajn',
      pt: 'Barém',
      ro: 'Bahrain',
      ru: 'Бахрейн',
      ua: 'Бахрейн',
      zh: '巴林',
    },
    phoneMasks: ['#### ####'],
  },
  {
    callingCode: '+257',
    cca2: 'BI',
    flag: '🇧🇮',
    name: {
      bg: 'Бурунди',
      by: 'Бурундзі',
      cn: '布隆迪',
      cz: 'Burundi',
      de: 'Burundi',
      ee: 'Burundi',
      el: 'Μπουρούντι',
      en: 'Burundi',
      es: 'Burundi',
      fr: 'Burundi',
      he: 'בורונדי',
      it: 'Burundi',
      jp: 'ブルンジ',
      nl: 'Burundi',
      pl: 'Burundi',
      pt: 'Burúndi',
      ro: 'Burundi',
      ru: 'Бурунди',
      ua: 'Бурунді',
      zh: '布隆迪',
    },
    phoneMasks: ['## ## ####'],
  },
  {
    callingCode: '+229',
    cca2: 'BJ',
    flag: '🇧🇯',
    name: {
      bg: 'Бенин',
      by: 'Бенін',
      cn: '贝宁',
      cz: 'Benin',
      de: 'Benin',
      ee: 'Benin',
      el: 'Μπενίν',
      en: 'Benin',
      es: 'Benín',
      fr: 'Bénin',
      he: 'בנין',
      it: 'Benin',
      jp: 'ベナン',
      nl: 'Benin',
      pl: 'Benin',
      pt: 'Benim',
      ro: 'Benin',
      ru: 'Бенин',
      ua: 'Бенін',
      zh: '貝寧',
    },
    phoneMasks: ['## ## ####'],
  },
  {
    callingCode: '+590',
    cca2: 'BL',
    flag: '🇧🇱',
    name: {
      bg: 'Свети Бартелеми',
      by: 'Святы Бартэлемі',
      cn: '圣巴托洛缪岛',
      cz: 'Svatý Bartoloměj',
      de: 'Heiliger Barthelemy',
      ee: 'Püha Barthelemy',
      el: 'Άγιος Βαρθολομαίος',
      en: 'Saint Barthélemy',
      es: 'San Bartolomé',
      fr: 'Saint Barthélemy',
      he: "סנט ברת'למי",
      it: 'Saint Barthelemy',
      jp: 'サンバルテルミー',
      nl: 'Sint-Bartholomeus',
      pl: 'Saint Barthelemy',
      pt: 'São Bartolomeu',
      ro: 'Sfântul Barthelemy',
      ru: 'Святой Варфоломей',
      ua: 'Сен-Бартелемі',
      zh: '聖巴托洛繆島',
    },
    phoneMasks: ['690 ## ## ##'],
  },
  {
    callingCode: '+1',
    cca2: 'BM',
    flag: '🇧🇲',
    name: {
      bg: 'Бермуди',
      by: 'Бярмуды',
      cn: '百慕大',
      cz: 'Bermudy',
      de: 'Bermuda',
      ee: 'Bermuda',
      el: 'Βερμούδες',
      en: 'Bermuda',
      es: 'Bermudas',
      fr: 'Bermudes',
      he: 'ברמודה',
      it: 'Bermuda',
      jp: 'バミューダ',
      nl: 'Bermuda',
      pl: 'Bermudy',
      pt: 'Bermudas',
      ro: 'Bermuda',
      ru: 'Бермуды',
      ua: 'Бермуди',
      zh: '百慕大',
    },
    phoneMasks: ['441 ### ####'],
  },
  {
    callingCode: '+673',
    cca2: 'BN',
    flag: '🇧🇳',
    name: {
      bg: 'Бруней Дарусалам',
      by: 'Бруней-Дарусалам',
      cn: '文莱达鲁萨兰国',
      cz: 'Brunej Darussalam',
      de: 'Brunei Darussalam',
      ee: 'Brunei Darussalam',
      el: 'Μπρουνέι',
      en: 'Brunei Darussalam',
      es: 'Brunei Darussalam',
      fr: 'Brunei Darussalam',
      he: 'ברוניי דארוסלאם',
      it: 'Brunei Darussalam',
      jp: 'ブルネイダルサラーム',
      nl: 'Brunei Darussalam',
      pl: 'Brunei Darussalam',
      pt: 'Brunei',
      ro: 'Brunei Darussalam',
      ru: 'Бруней-Даруссалам',
      ua: 'Бруней-Даруссалам',
      zh: '文萊達魯薩蘭國',
    },
    phoneMasks: ['### ####'],
  },
  {
    callingCode: '+591',
    cca2: 'BO',
    flag: '🇧🇴',
    name: {
      bg: 'Боливия',
      by: 'Балівія',
      cn: '玻利维亚',
      cz: 'Bolívie',
      de: 'Bolivien',
      ee: 'Boliivia',
      el: 'Βολιβία',
      en: 'Bolivia',
      es: 'Bolivia',
      fr: 'Bolivie',
      he: 'בוליביה',
      it: 'Bolivia',
      jp: 'ボリビア',
      nl: 'Bolivia',
      pl: 'Boliwia',
      pt: 'Bolívia',
      ro: 'Bolivia',
      ru: 'Боливия',
      ua: 'Болівія',
      zh: '玻利維亞',
    },
    phoneMasks: ['# ### ####'],
  },
  {
    callingCode: '+55',
    cca2: 'BR',
    flag: '🇧🇷',
    name: {
      bg: 'Бразилия',
      by: 'Бразілія',
      cn: '巴西',
      cz: 'Brazílie',
      de: 'Brasilien',
      ee: 'Brasiilia',
      el: 'Βραζιλία',
      en: 'Brazil',
      es: 'Brasil',
      fr: 'Brésil',
      he: 'ברזיל',
      it: 'Brasile',
      jp: ' ブラジル',
      nl: 'Brazilië',
      pl: 'Brazylia',
      pt: 'Brasil',
      ro: 'Brazilia',
      ru: 'Бразилия',
      ua: 'Бразилія',
      zh: '巴西',
    },
    phoneMasks: ['## ##### ####'],
  },
  {
    callingCode: '+1',
    cca2: 'BS',
    flag: '🇧🇸',
    name: {
      bg: 'Бахамски острови',
      by: 'Багамскія астравы',
      cn: '巴哈马',
      cz: 'Bahamy',
      de: 'Bahamas',
      ee: 'Bahama',
      el: 'Μπαχάμες',
      en: 'Bahamas',
      es: 'Bahamas',
      fr: 'Bahamas',
      he: 'איי בהאמה',
      it: 'Bahamas',
      jp: 'バハマ',
      nl: 'Bahamas',
      pl: 'Bahamy',
      pt: 'Bahamas',
      ro: 'Bahamas',
      ru: 'Багамские Острова',
      ua: 'Багамські острови',
      zh: '巴哈馬',
    },
    phoneMasks: ['242 ### ####'],
  },
  {
    callingCode: '+975',
    cca2: 'BT',
    flag: '🇧🇹',
    name: {
      bg: 'Бутан',
      by: 'Бутан',
      cn: '不丹',
      cz: 'Bhútán',
      de: 'Bhutan',
      ee: 'Bhutan',
      el: 'Μπουτάν',
      en: 'Bhutan',
      es: 'Bután',
      fr: 'Bhoutan',
      he: 'בהוטן',
      it: 'Bhutan',
      jp: 'ブータン',
      nl: 'Bhutan',
      pl: 'Bhutan',
      pt: 'Butão',
      ro: 'Bhutan',
      ru: 'Бутан',
      ua: 'Бутан',
      zh: '不丹',
    },
    phoneMasks: ['# ### ###'],
  },
  {
    callingCode: '+267',
    cca2: 'BW',
    flag: '🇧🇼',
    name: {
      bg: 'Ботсвана',
      by: 'Батсвана',
      cn: '博茨瓦纳',
      cz: 'Botswana',
      de: 'Botswana',
      ee: 'Botswana',
      el: 'Μποτσουάνα',
      en: 'Botswana',
      es: 'Botsuana',
      fr: 'Botswana',
      he: 'בוצואנה',
      it: 'Botswana',
      jp: 'ボツワナ',
      nl: 'Botswana',
      pl: 'Botswana',
      pt: 'Botsuana',
      ro: 'Botswana',
      ru: 'Ботсвана',
      ua: 'Ботсвана',
      zh: '博茨瓦納',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+375',
    cca2: 'BY',
    flag: '🇧🇾',
    name: {
      bg: 'Беларус',
      by: 'Беларусь',
      cn: '白俄罗斯',
      cz: 'Bělorusko',
      de: 'Weißrussland',
      ee: 'Valgevene',
      el: 'Λευκορωσία',
      en: 'Belarus',
      es: 'Bielorrusia',
      fr: 'Biélorussie',
      he: 'בלארוס',
      it: 'Bielorussia',
      jp: 'ベラルーシ',
      nl: 'Wit-Rusland',
      pl: 'Białoruś',
      pt: 'Bielorrússia',
      ro: 'Bielorusia',
      ru: 'Белоруссия',
      ua: 'Білорусь',
      zh: '白俄羅斯',
    },
    phoneMasks: ['## ### ## ##'],
  },
  {
    callingCode: '+501',
    cca2: 'BZ',
    flag: '🇧🇿',
    name: {
      bg: 'Белиз',
      by: 'Беліз',
      cn: '伯利兹',
      cz: 'Belize',
      de: 'Belize',
      ee: 'Belize',
      el: 'Μπελίζ',
      en: 'Belize',
      es: 'Belice',
      fr: 'Belize',
      he: 'בליז',
      it: 'Belize',
      jp: 'ベリ ーズ',
      nl: 'Belize',
      pl: 'Belize',
      pt: 'Belize',
      ro: 'Belize',
      ru: 'Белиз',
      ua: 'Беліз',
      zh: '伯利茲',
    },
    phoneMasks: ['### ####'],
  },
  {
    callingCode: '+1',
    cca2: 'CA',
    flag: '🇨🇦',
    name: {
      bg: 'Канада',
      by: 'Канада',
      cn: '加拿大',
      cz: 'Kanada',
      de: 'Kanada',
      ee: 'Kanada',
      el: 'Καναδάς',
      en: 'Canada',
      es: 'Canadá',
      fr: 'Canada',
      he: 'קנדה',
      it: 'Canada',
      jp: 'カナダ',
      nl: 'Canada',
      pl: 'Kanada',
      pt: 'Canadá',
      ro: 'Canada',
      ru: 'Канада',
      ua: 'Канада',
      zh: '加拿大',
    },
    phoneMasks: [
      '204 ### ####',
      '226 ### ####',
      '236 ### ####',
      '249 ### ####',
      '250 ### ####',
      '263 ### ####',
      '289 ### ####',
      '306 ### ####',
      '343 ### ####',
      '354 ### ####',
      '365 ### ####',
      '367 ### ####',
      '368 ### ####',
      '403 ### ####',
      '416 ### ####',
      '418 ### ####',
      '431 ### ####',
      '437 ### ####',
      '438 ### ####',
      '450 ### ####',
      '468 ### ####',
      '474 ### ####',
      '506 ### ####',
      '514 ### ####',
      '519 ### ####',
      '548 ### ####',
      '579 ### ####',
      '581 ### ####',
      '584 ### ####',
      '587 ### ####',
      '604 ### ####',
      '613 ### ####',
      '639 ### ####',
      '647 ### ####',
      '672 ### ####',
      '683 ### ####',
      '705 ### ####',
      '709 ### ####',
      '742 ### ####',
      '753 ### ####',
      '778 ### ####',
      '780 ### ####',
      '782 ### ####',
      '807 ### ####',
      '819 ### ####',
      '825 ### ####',
      '867 ### ####',
      '873 ### ####',
      '902 ### ####',
      '905 ### ####',
    ],
  },
  {
    callingCode: '+61',
    cca2: 'CC',
    flag: '🇨🇨',
    name: {
      bg: 'Кокосови (Кийлинг) острови',
      by: 'Какосавыя (Кілінскія) астравы',
      cn: '科科斯（基林）群岛',
      cz: 'Kokosové (Keelingovy) ostrovy',
      de: 'Kokosinseln (Keelinginseln)',
      ee: 'Kookossaared (Keelingi) saared',
      el: 'Νησιά Κόκος',
      en: 'Cocos (Keeling) Islands',
      es: 'Islas Cocos (Keeling)',
      fr: 'Îles Cocos (Keeling)',
      he: 'איי קוקוס (קילינג).',
      it: 'Isole Cocos (Keeling)',
      jp: 'ココス（キーリング）諸島',
      nl: 'Cocos (Keeling) eilanden',
      pl: 'Wyspy Kokosowe (Keelinga)',
      pt: 'Ilhas dos Cocos',
      ro: 'Insulele Cocos (Keeling)',
      ru: 'Кокосовые (Килинг) острова',
      ua: 'Кокосові (Кілінг) острови',
      zh: '科科斯（基林）群島',
    },
    phoneMasks: ['8 9162 ####'],
  },
  {
    callingCode: '+243',
    cca2: 'CD',
    flag: '🇨🇩',
    name: {
      bg: 'Конго, Демократична република Конго',
      by: 'Конга, Дэмакратычная Рэспубліка Конга',
      cn: '刚果民主共和国',
      cz: 'Kongo, Demokratická Republika',
      de: 'Kongo, Demokratische Republik Kongo',
      ee: 'Kongo, Kongo Demokraatlik Vabariik',
      el: 'Λαϊκή Δημοκρατία του Κονγκό',
      en: 'Congo, The Democratic Republic of the',
      es: 'Congo, República Democrática del',
      fr: 'Congo, République démocratique du Congo',
      he: 'קונגו',
      it: 'Congo, Repubblica Democratica del Congo',
      jp: 'コンゴ、コンゴ民主共和国',
      nl: 'Congo, Democratische Republiek Congo',
      pl: 'Kongo, Republika Demokratyczna',
      pt: 'Congo-Kinshasa',
      ro: 'Congo, Republica Democrată Congo',
      ru: 'Конго, Демократическая Республика',
      ua: 'Конго, Демократична Республіка',
      zh: '剛果民主共和國',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+236',
    cca2: 'CF',
    flag: '🇨🇫',
    name: {
      bg: 'Централноафриканска република',
      by: 'Цэнтральна-Афрыканская Рэспубліка',
      cn: ' 中非共和国',
      cz: 'Středoafrická republika',
      de: 'Zentralafrikanische Republik',
      ee: 'Kesk-Aafrika Vabariik',
      el: 'Κεντροαφρικανική Δημοκρατία',
      en: 'Central African Republic',
      es: 'República Centroafricana',
      fr: 'République centrafricaine',
      he: 'הרפובליקה המרכז - אפריקאית',
      it: 'Repubblica Centrafricana',
      jp: '中央アフリカ共和国',
      nl: 'Centraal Afrikaanse Republiek',
      pl: 'Republika Środkowoafrykańska',
      pt: 'República Centro-Africana',
      ro: 'Republica Centrafricană',
      ru: 'Центрально-Африканская Республика',
      ua: 'Центральноафриканська Республіка',
      zh: '中非共和國',
    },
    phoneMasks: ['## ## ####'],
  },
  {
    callingCode: '+242',
    cca2: 'CG',
    flag: '🇨🇬',
    name: {
      bg: 'Конго',
      by: 'Конга',
      cn: '刚果',
      cz: 'Kongo',
      de: 'Kongo',
      ee: 'Kongo',
      el: 'Δημοκρατία του Κονγκό',
      en: 'Congo',
      es: 'Congo',
      fr: 'Congo',
      he: 'קונגו',
      it: 'Congo',
      jp: 'コンゴ',
      nl: 'Congo',
      pl: 'Kongo',
      pt: 'Congo-Brazzaville',
      ro: 'Congo',
      ru: 'Конго',
      ua: 'Конго',
      zh: '剛果',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+41',
    cca2: 'CH',
    flag: '🇨🇭',
    name: {
      bg: 'Швейцария',
      by: 'Швейцарыя',
      cn: '瑞士',
      cz: 'Švýcarsko',
      de: 'Schweiz',
      ee: 'Šveits',
      el: 'Ελβετία',
      en: 'Switzerland',
      es: 'Suiza',
      fr: 'la Suisse',
      he: 'שוויץ',
      it: 'Svizzera',
      jp: 'スイス',
      nl: 'Zwitserland',
      pl: 'Szwajcaria',
      pt: 'Suíça',
      ro: 'Elveţia',
      ru: 'Швейцария',
      ua: 'Швейцарія',
      zh: '瑞士',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+225',
    cca2: 'CI',
    flag: '🇨🇮',
    name: {
      bg: "Кот д'Ивоар",
      by: "Кот-д'Івуар",
      cn: '科特迪瓦',
      cz: 'Pobřeží slonoviny',
      de: 'Elfenbeinküste',
      ee: 'Elevandiluurannik',
      el: 'Ακτή Ελεφαντοστού',
      en: "Cote d'Ivoire",
      es: 'Costa de Marfil',
      fr: "Côte d'Ivoire",
      he: 'חוף השנהב',
      it: "Costa d'Avorio",
      jp: 'コートジボワール',
      nl: 'Ivoorkust',
      pl: 'Wybrzeże Kości Słoniowej',
      pt: 'Costa do Marfim',
      ro: 'Coasta de Fildeș',
      ru: "Кот-д'Ивуар",
      ua: "Кот-д'Івуар",
      zh: '科特迪瓦',
    },
    phoneMasks: ['## ### ###', '## ## ## ####'],
  },
  {
    callingCode: '+682',
    cca2: 'CK',
    flag: '🇨🇰',
    name: {
      bg: 'Острови Кук',
      by: 'Астравы Кука',
      cn: '库克群岛',
      cz: 'Cookovy ostrovy',
      de: 'Cookinseln',
      ee: 'Cooki saared',
      el: 'Νήσοι Κουκ',
      en: 'Cook Islands',
      es: 'Islas Cook',
      fr: 'les Îles Cook',
      he: 'איי קוק',
      it: 'Isole Cook',
      jp: 'クック諸島',
      nl: 'Cook Eilanden',
      pl: 'Wyspy Cooka',
      pt: 'Ilhas Cook',
      ro: 'Insulele Cook',
      ru: 'Острова Кука',
      ua: 'Острови Кука',
      zh: '庫克群島',
    },
    phoneMasks: ['## ###'],
  },
  {
    callingCode: '+56',
    cca2: 'CL',
    flag: '🇨🇱',
    name: {
      bg: 'Чили',
      by: 'Чылі',
      cn: '智利',
      cz: 'Chile',
      de: 'Chile',
      ee: 'Tšiili',
      el: 'Χιλή',
      en: 'Chile',
      es: 'Chile',
      fr: 'Chili',
      he: "צ'ילה",
      it: 'Chile',
      jp: 'チリ',
      nl: 'Chili',
      pl: 'Chile',
      pt: 'Chile',
      ro: 'Chile',
      ru: 'Чили',
      ua: 'Чилі',
      zh: '智利',
    },
    phoneMasks: ['# #### ####'],
  },
  {
    callingCode: '+237',
    cca2: 'CM',
    flag: '🇨🇲',
    name: {
      bg: 'Камерун',
      by: 'Камерун',
      cn: '喀麦隆',
      cz: 'Kamerun',
      de: 'Kamerun',
      ee: 'Kamerun',
      el: 'Καμερούν',
      en: 'Cameroon',
      es: 'Camerún',
      fr: 'Cameroun',
      he: 'קמרון',
      it: 'Camerun',
      jp: ' カメルーン',
      nl: 'Kameroen',
      pl: 'Kamerun',
      pt: 'Camarões',
      ro: 'Camerun',
      ru: 'Камерун',
      ua: 'Камерун',
      zh: '喀麥隆',
    },
    phoneMasks: ['#### ####', '### ### ###'],
  },
  {
    callingCode: '+86',
    cca2: 'CN',
    flag: '🇨🇳',
    name: {
      bg: 'Китай',
      by: 'Кітай',
      cn: '中国',
      cz: 'Čína',
      de: 'China',
      ee: 'Hiina',
      el: 'Κίνα',
      en: 'China',
      es: 'China',
      fr: 'Chine',
      he: 'חרסינה',
      it: 'Cina',
      jp: '中国',
      nl: 'China',
      pl: 'Chiny',
      pt: 'China',
      ro: 'China',
      ru: 'Китай',
      ua: 'Китай',
      zh: '中國',
    },
    phoneMasks: ['### #### ###', '### #### ####', '## ##### #####'],
  },
  {
    callingCode: '+57',
    cca2: 'CO',
    flag: '🇨🇴',
    name: {
      bg: 'Колумбия',
      by: 'Калумбія',
      cn: '哥伦比亚',
      cz: 'Kolumbie',
      de: 'Kolumbien',
      ee: 'Kolumbia',
      el: 'Κολομβία',
      en: 'Colombia',
      es: 'Colombia',
      fr: 'Colombie',
      he: 'קולומביה',
      it: 'Colombia',
      jp: 'コロンビア',
      nl: 'Colombia',
      pl: 'Kolumbia',
      pt: 'Colômbia',
      ro: 'Columbia',
      ru: 'Колумбия',
      ua: 'Колумбія',
      zh: '哥倫比亞',
    },
    phoneMasks: ['### ### ####'],
  },
  {
    callingCode: '+506',
    cca2: 'CR',
    flag: '🇨🇷',
    name: {
      bg: 'Коста Рика',
      by: 'Коста-Рыка',
      cn: '哥斯达黎加',
      cz: 'Kostarika',
      de: 'Costa Rica',
      ee: 'Costa Rica',
      el: 'Κόστα Ρίκα',
      en: 'Costa Rica',
      es: 'Costa Rica',
      fr: 'Costa Rica',
      he: 'קוסטה ריקה',
      it: 'Costa Rica',
      jp: 'コスタリカ',
      nl: 'Costa Rica',
      pl: 'Kostaryka',
      pt: 'Costa Rica',
      ro: 'Costa Rica',
      ru: 'Коста-Рика',
      ua: 'Коста-Ріка',
      zh: '哥斯達黎加',
    },
    phoneMasks: ['#### ####'],
  },
  {
    callingCode: '+53',
    cca2: 'CU',
    flag: '🇨🇺',
    name: {
      bg: 'Куба',
      by: 'Куба',
      cn: '古巴',
      cz: 'Kuba',
      de: 'Kuba',
      ee: 'Kuuba',
      el: 'Κούβα',
      en: 'Cuba',
      es: 'Cuba',
      fr: 'Cuba',
      he: 'קובה',
      it: 'Cuba',
      jp: 'キューバ',
      nl: 'Cuba',
      pl: 'Kuba',
      pt: 'Cuba',
      ro: 'Cuba',
      ru: 'Куба',
      ua: 'Куба',
      zh: '古巴',
    },
    phoneMasks: ['# ### ####'],
  },
  {
    callingCode: '+238',
    cca2: 'CV',
    flag: '🇨🇻',
    name: {
      bg: 'Кабо Верде',
      by: 'Каба-Вэрдэ',
      cn: '佛得角',
      cz: 'Kapverdy',
      de: 'Kap Verde',
      ee: 'Roheneemesaared',
      el: 'Πράσινο Ακρωτήριο',
      en: 'Cape Verde',
      es: 'Cabo Verde',
      fr: 'Cap-Vert',
      he: 'קייפ ורדה',
      it: 'capo Verde',
      jp: 'カーボベル デ',
      nl: 'Kaapverdië',
      pl: 'Wyspy Zielonego Przylądka',
      pt: 'Cabo Verde',
      ro: 'capul Verde',
      ru: 'Кабо-Верде',
      ua: 'Кабо-Верде',
      zh: '佛得角',
    },
    phoneMasks: ['### ## ##'],
  },
  {
    callingCode: '+61',
    cca2: 'CX',
    flag: '🇨🇽',
    name: {
      bg: 'Коледен остров',
      by: 'Востраў Каляд',
      cn: '圣诞岛',
      cz: 'Vánoční ostrov',
      de: 'Weihnachtsinsel',
      ee: 'Jõulusaar',
      el: 'Νήσος των Χριστουγέννων',
      en: 'Christmas Island',
      es: 'Isla de Navidad',
      fr: "L'île de noël",
      he: 'אי חג המולד',
      it: 'Isola di Natale',
      jp: 'クリスマス島',
      nl: 'Kersteiland',
      pl: 'Wyspa Bożego Narodzenia',
      pt: 'Ilha do Natal',
      ro: 'Insula Craciunului',
      ru: 'Остров Рождества',
      ua: 'Острів Різдва',
      zh: '聖誕島',
    },
    phoneMasks: ['8 9164 ####'],
  },
  {
    callingCode: '+357',
    cca2: 'CY',
    flag: '🇨🇾',
    name: {
      bg: 'Кипър',
      by: 'Кіпр',
      cn: '塞浦路斯',
      cz: 'Kypr',
      de: 'Zypern',
      ee: 'Küpros',
      el: 'Κύπρος',
      en: 'Cyprus',
      es: 'Chipre',
      fr: 'Chypre',
      he: 'קפריסין',
      it: 'Cipro',
      jp: ' キプロス',
      nl: 'Cyprus',
      pl: 'Cypr',
      pt: 'Chipre',
      ro: 'Cipru',
      ru: 'Кипр',
      ua: 'Кіпр',
      zh: '塞浦路斯',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+420',
    cca2: 'CZ',
    flag: '🇨🇿',
    name: {
      bg: 'Чехия',
      by: 'Чэская Рэспубліка',
      cn: '捷克共和国',
      cz: 'Česká republika',
      de: 'Tschechien',
      ee: 'Tšehhi Vabariik',
      el: 'Τσεχία',
      en: 'Czech Republic',
      es: 'República Checa',
      fr: 'République Tchèque',
      he: "הרפובליקה הצ'כית",
      it: 'Repubblica Ceca',
      jp: 'チェコ共和国',
      nl: 'Tsjechië',
      pl: 'Republika Czeska',
      pt: 'República Checa',
      ro: 'Republica Cehă',
      ru: 'Чехия',
      ua: 'Чеська Республіка',
      zh: '捷克共和國',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+49',
    cca2: 'DE',
    flag: '🇩🇪',
    name: {
      bg: 'Германия',
      by: 'Германія',
      cn: '德国',
      cz: 'Německo',
      de: 'Deutschland',
      ee: 'Saksamaa',
      el: 'Γερμανία',
      en: 'Germany',
      es: 'Alemania',
      fr: 'Allemagne',
      he: 'גרמניה',
      it: 'Germania',
      jp: 'ドイツ',
      nl: 'Duitsland',
      pl: 'Niemcy',
      pt: 'Alemanha',
      ro: 'Germania',
      ru: 'Германия',
      ua: 'Німеччина',
      zh: '德國',
    },
    phoneMasks: [
      '### ###',
      '### ## ##',
      '### ## ###',
      '### ## ####',
      '### ### ####',
      '#### ### ####',
    ],
  },
  {
    callingCode: '+253',
    cca2: 'DJ',
    flag: '🇩🇯',
    name: {
      bg: 'Джибути',
      by: 'Джыбуці',
      cn: ' 吉布地',
      cz: 'Džibuti',
      de: 'Dschibuti',
      ee: 'Djibouti',
      el: 'Τζιμπουτί',
      en: 'Djibouti',
      es: 'Yibuti',
      fr: 'Djibouti',
      he: "ג'יבוטי",
      it: 'Gibuti',
      jp: 'ジブチ',
      nl: 'Djibouti',
      pl: 'Dżibuti',
      pt: 'Jibuti',
      ro: 'Djibouti',
      ru: 'Джибути',
      ua: 'Джибуті',
      zh: '吉布地',
    },
    phoneMasks: ['## ## ## ##'],
  },
  {
    callingCode: '+45',
    cca2: 'DK',
    flag: '🇩🇰',
    name: {
      bg: 'Дания',
      by: 'Данія',
      cn: '丹麦',
      cz: 'Dánsko',
      de: 'Dänemark',
      ee: 'Taani',
      el: 'Δανία',
      en: 'Denmark',
      es: 'Dinamarca',
      fr: 'Danemark',
      he: 'דנמרק',
      it: 'Danimarca',
      jp: 'デンマーク',
      nl: 'Denemarken',
      pl: 'Dania',
      pt: 'Dinamarca',
      ro: 'Danemarca',
      ru: 'Дания',
      ua: 'Данія',
      zh: '丹麥',
    },
    phoneMasks: ['## ## ## ##'],
  },
  {
    callingCode: '+1',
    cca2: 'DM',
    flag: '🇩🇲',
    name: {
      bg: 'Доминика',
      by: 'Дамініка',
      cn: '多米尼加',
      cz: 'Dominika',
      de: 'Dominica',
      ee: 'Dominica',
      el: 'Δομινίκα',
      en: 'Dominica',
      es: 'Dominica',
      fr: 'Dominique',
      he: 'דומיניקה',
      it: 'Dominica',
      jp: 'ドミニカ',
      nl: 'Dominica',
      pl: 'Dominika',
      pt: 'Domínica',
      ro: 'Dominica',
      ru: 'Доминика',
      ua: 'Домініка',
      zh: '多米尼加',
    },
    phoneMasks: ['767 ### ####'],
  },
  {
    callingCode: '+1',
    cca2: 'DO',
    flag: '🇩🇴',
    name: {
      bg: 'Доминиканска република',
      by: 'Дамініканская Рэспубліка',
      cn: '多明尼加共和国',
      cz: 'Dominikánská republika',
      de: 'Dominikanische Republik',
      ee: 'Dominikaani Vabariik',
      el: 'Δομινικανή Δημοκρατία',
      en: 'Dominican Republic',
      es: 'República Dominicana',
      fr: 'République dominicaine',
      he: 'הרפובליקה הדומיניקנית',
      it: 'Repubblica Dominicana',
      jp: 'ドミニカ共和国',
      nl: 'Dominicaanse Republiek',
      pl: 'Republika Dominikany',
      pt: 'República Dominicana',
      ro: 'Republica Dominicana',
      ru: 'Доминиканская Респблика',
      ua: 'Домініканська республіка',
      zh: '多明尼加共和國',
    },
    phoneMasks: ['809 ### ####'],
  },
  {
    callingCode: '+213',
    cca2: 'DZ',
    flag: '🇩🇿',
    name: {
      bg: 'Алжир',
      by: 'Алжыр',
      cn: '阿尔及利亚',
      cz: 'Alžírsko',
      de: 'Algerien',
      ee: 'Alžeeria',
      el: 'Αλγερία',
      en: 'Algeria',
      es: 'Argelia',
      fr: 'Algérie',
      he: "אלג'יריה",
      it: 'Algeria',
      jp: 'アルジェリア',
      nl: 'Algerije',
      pl: 'Algieria',
      pt: 'Argélia',
      ro: 'Algeria',
      ru: 'Алжир',
      ua: 'Алжир',
      zh: '阿爾及利亞',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+593',
    cca2: 'EC',
    flag: '🇪🇨',
    name: {
      bg: 'Еквадор',
      by: 'Эквадор',
      cn: '厄瓜多尔',
      cz: 'Ekvádor',
      de: 'Ecuador',
      ee: 'Ecuador',
      el: 'Ισημερινός',
      en: 'Ecuador',
      es: 'Ecuador',
      fr: 'Equateur',
      he: 'אקוודור',
      it: 'Ecuador',
      jp: 'エクアドル',
      nl: 'Ecuador',
      pl: 'Ekwador',
      pt: 'Equador',
      ro: 'Ecuador',
      ru: 'Эквадор',
      ua: 'Еквадор',
      zh: '厄瓜多爾',
    },
    phoneMasks: ['# ### ####', '## ### ####'],
  },
  {
    callingCode: '+372',
    cca2: 'EE',
    flag: '🇪🇪',
    name: {
      bg: 'Естония',
      by: 'Эстонія',
      cn: '爱沙尼亚',
      cz: 'Estonsko',
      de: 'Estland',
      ee: 'Eesti',
      el: 'Εσθονία',
      en: 'Estonia',
      es: 'Estonia',
      fr: 'Estonie',
      he: 'אסטוניה',
      it: 'Estonia',
      jp: 'エストニア',
      nl: 'Estland',
      pl: 'Estonia',
      pt: 'Estónia',
      ro: 'Estonia',
      ru: 'Эстония',
      ua: 'Естонія',
      zh: '愛沙尼亞',
    },
    phoneMasks: ['#### ####', '### ####'],
  },
  {
    callingCode: '+20',
    cca2: 'EG',
    flag: '🇪🇬',
    name: {
      bg: 'Египет',
      by: 'Егіпет',
      cn: '埃及',
      cz: 'Egypt',
      de: 'Ägypten',
      ee: 'Egiptus',
      el: 'Αίγυπτος',
      en: 'Egypt',
      es: 'Egipto',
      fr: 'Egypte',
      he: 'מצרים',
      it: 'Egitto',
      jp: 'エジプト',
      nl: 'Egypte',
      pl: 'Egipt',
      pt: 'Egipto',
      ro: 'Egipt',
      ru: 'Египет',
      ua: 'Єгипет',
      zh: '埃及',
    },
    phoneMasks: ['### ### ####'],
  },
  {
    callingCode: '+291',
    cca2: 'ER',
    flag: '🇪🇷',
    name: {
      bg: 'Еритрея',
      by: 'Эрытрэя',
      cn: '厄立特里亚',
      cz: 'Eritrea',
      de: 'Eritrea',
      ee: 'Eritrea',
      el: 'Ερυθραία',
      en: 'Eritrea',
      es: 'Eritrea',
      fr: 'Érythrée',
      he: 'אריתריאה',
      it: 'Eritrea',
      jp: 'エリトリア',
      nl: 'Eritrea',
      pl: 'Erytrea',
      pt: 'Eritreia',
      ro: 'Eritreea',
      ru: 'Эритрея',
      ua: 'Еритрея',
      zh: '厄立特里亞',
    },
    phoneMasks: ['# ### ###'],
  },
  {
    callingCode: '+34',
    cca2: 'ES',
    flag: '🇪🇸',
    name: {
      bg: 'Испания',
      by: 'Іспанія',
      cn: '西班牙',
      cz: 'Španělsko',
      de: 'Spanien',
      ee: 'Hispaania',
      el: 'Ισπανία',
      en: 'Spain',
      es: 'España',
      fr: 'Espagne',
      he: 'ספרד',
      it: 'Spagna',
      jp: 'スペイン',
      nl: 'Spanje',
      pl: 'Hiszpania',
      pt: 'Espanha',
      ro: 'Spania',
      ru: 'Испания',
      ua: 'Іспанія',
      zh: '西班牙',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+251',
    cca2: 'ET',
    flag: '🇪🇹',
    name: {
      bg: 'Етиопия',
      by: 'Эфіопія',
      cn: '埃塞俄比亚',
      cz: 'Etiopie',
      de: 'Äthiopien',
      ee: 'Etioopia',
      el: 'Αιθιοπία',
      en: 'Ethiopia',
      es: 'Etiopía',
      fr: 'Ethiopie',
      he: 'אתיופיה',
      it: 'Etiopia',
      jp: 'エチオピア',
      nl: 'Ethiopië',
      pl: 'Etiopia',
      pt: 'Etiópia',
      ro: 'Etiopia',
      ru: 'Эфиопия',
      ua: 'Ефіопія',
      zh: '埃塞俄比亞',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+358',
    cca2: 'FI',
    flag: '🇫🇮',
    name: {
      bg: 'Финландия',
      by: 'Фінляндыя',
      cn: '芬兰',
      cz: 'Finsko',
      de: 'Finnland',
      ee: 'Soome',
      el: 'Φινλανδία',
      en: 'Finland',
      es: 'Finlandia',
      fr: 'Finlande',
      he: 'פינלנד',
      it: 'Finlandia',
      jp: 'フィンランド',
      nl: 'Finland',
      pl: 'Finlandia',
      pt: 'Finlândia',
      ro: 'Finlanda',
      ru: 'Финляндия',
      ua: 'Фінляндія',
      zh: '芬蘭',
    },
    phoneMasks: ['4## ### ## ##', '5## ### ## ##'],
  },
  {
    callingCode: '+679',
    cca2: 'FJ',
    flag: '🇫🇯',
    name: {
      bg: 'Фиджи',
      by: 'Фіджы',
      cn: '斐济',
      cz: 'Fidži',
      de: 'Fidschi',
      ee: 'Fidži',
      el: 'Φίτζι',
      en: 'Fiji',
      es: 'Fiyi',
      fr: 'Fidji',
      he: "פיג'י",
      it: 'Figi',
      jp: 'フィジー',
      nl: 'Fiji',
      pl: 'Fidżi',
      pt: 'Fiji',
      ro: 'Fiji',
      ru: 'Фиджи',
      ua: 'Фіджі',
      zh: '斐濟',
    },
    phoneMasks: ['## #####'],
  },
  {
    callingCode: '+500',
    cca2: 'FK',
    flag: '🇫🇰',
    name: {
      bg: 'Фолкландски острови (Малвински острови)',
      by: 'Фолклендскія астравы (Мальвіна)',
      cn: '福克兰群岛（马 尔维纳斯）',
      cz: 'Falklandské ostrovy (Malvíny)',
      de: 'Falklandinseln (Malvinas)',
      ee: 'Falklandi saared (Malvinas)',
      el: 'Νήσοι Φώκλαντ',
      en: 'Falkland Islands (Malvinas)',
      es: 'Islas Malvinas (Falkland Islands)',
      fr: 'Îles Falkland (Malvinas)',
      he: 'איי פוקלנד (מלווינאס)',
      it: 'Isole Falkland (Malvinas)',
      jp: 'フォークランド諸島（マルビナス）',
      nl: 'Falklandeilanden (Malvinas)',
      pl: 'Falklandy (Malwiny)',
      pt: 'Ilhas Falkland',
      ro: 'Insulele Falkland (Malvinas)',
      ru: 'Фолклендские острова (Malvinas)',
      ua: 'Фолклендські острови (Мальвінські острови)',
      zh: '福 克蘭群島（馬爾維納斯）',
    },
    phoneMasks: ['5# ###', '6# ###'],
  },
  {
    callingCode: '+691',
    cca2: 'FM',
    flag: '🇫🇲',
    name: {
      bg: 'Микронезия, Федеративни щати',
      by: 'Мікранезія, Федэратыўныя Штаты',
      cn: '密克罗尼西亚联邦',
      cz: 'Mikronésie, federativní státy',
      de: 'Mikronesien, Föderierte Staaten von',
      ee: 'Mikroneesia, Makedoonia Liiduriigid',
      el: 'Μικρονησία',
      en: 'Micronesia, Federated States of',
      es: 'Micronesia, Estados Federados de',
      fr: 'Micronésie, États fédérés de',
      he: 'מיקרונזיה',
      it: 'Micronesia, Stati federati di',
      jp: 'ミクロネシア連邦',
      nl: 'Micronesië, Federale Staten van',
      pl: 'Mikronezja, Sfederowane Stany',
      pt: 'Micronésia',
      ro: 'Micronezia, Statele Federate ale',
      ru: 'Микронезия, Федеративные Штаты',
      ua: 'Мікронезія, Федеративні Штати',
      zh: '密克羅尼西亞聯邦',
    },
    phoneMasks: ['### ####'],
  },
  {
    callingCode: '+298',
    cca2: 'FO',
    flag: '🇫🇴',
    name: {
      bg: 'Фарьорските острови',
      by: 'Фарэрскія астравы',
      cn: '法罗群岛',
      cz: 'Faerské ostrovy',
      de: 'Färöer Inseln',
      ee: 'Fääri saared',
      el: 'Νήσοι Φερόες',
      en: 'Faroe Islands',
      es: 'Islas Feroe',
      fr: 'Îles Féroé',
      he: 'איי פרו',
      it: 'Isole Faroe',
      jp: 'フェロー諸島',
      nl: 'Faeröer',
      pl: 'Wyspy Owcze',
      pt: 'Faroé',
      ro: 'Insulele Feroe',
      ru: 'Фарерские острова',
      ua: 'Фарерські острови',
      zh: '法羅群島',
    },
    phoneMasks: ['### ###'],
  },
  {
    callingCode: '+33',
    cca2: 'FR',
    flag: '🇫🇷',
    name: {
      bg: 'Франция',
      by: 'Францыя',
      cn: '法国',
      cz: 'Francie',
      de: 'Frankreich',
      ee: 'Prantsusmaa',
      el: 'Γαλλία',
      en: 'France',
      es: 'Francia',
      fr: 'France',
      he: 'צרפת',
      it: 'Francia',
      jp: 'フランス',
      nl: 'Frankrijk',
      pl: 'Francja',
      pt: 'França',
      ro: 'Franţa',
      ru: 'Франция',
      ua: 'Франція',
      zh: '法國',
    },
    phoneMasks: ['# ## ## ## ##', '## ## ## ## ##'],
  },
  {
    callingCode: '+241',
    cca2: 'GA',
    flag: '🇬🇦',
    name: {
      bg: 'Габон',
      by: 'Габон',
      cn: '加蓬',
      cz: 'Gabon',
      de: 'Gabun',
      ee: 'Gabon',
      el: 'Γκαμπόν',
      en: 'Gabon',
      es: 'Gabón',
      fr: 'Gabon',
      he: 'גאבון',
      it: 'Gabon',
      jp: 'ガボン',
      nl: 'Gabon',
      pl: 'Gabon',
      pt: 'Gabão',
      ro: 'Gabon',
      ru: 'Габон',
      ua: 'Габон',
      zh: '加蓬',
    },
    phoneMasks: ['# ## ## ##'],
  },
  {
    callingCode: '+44',
    cca2: 'GB',
    flag: '🇬🇧',
    name: {
      bg: 'Великобритания',
      by: 'Злучанае Каралеўства',
      cn: '英国',
      cz: 'Spojené království',
      de: 'Vereinigtes Königreich',
      ee: 'Ühendkuningriik',
      el: 'Ηνωμένο Βασίλειο',
      en: 'United Kingdom',
      es: 'Reino Unido',
      fr: 'Royaume-Uni',
      he: 'הממלכה המאוחדת',
      it: 'Regno Unito',
      jp: 'イギリス',
      nl: 'Verenigd Koningkrijk',
      pl: 'Zjednoczone Królestwo',
      pt: 'Reino Unido',
      ro: 'Regatul Unit',
      ru: 'Объединенное Королевство',
      ua: "Об'єднане Королівство",
      zh: '英國',
    },
    phoneMasks: [
      '160## ####',
      '169## ####',
      '160## #####',
      '169## #####',
      '2# #### ####',
      '11# ### ####',
      '12# ### ####',
      '13# ### ####',
      '141 ### ####',
      '151 ### ####',
      '161 ### ####',
      '12## ## ####',
      '13## ## ####',
      '1642 ## ####',
      '17## ## ####',
      '18## ## ####',
      '19## ## ####',
      '1539# ## ###',
    ],
  },
  {
    callingCode: '+1',
    cca2: 'GD',
    flag: '🇬🇩',
    name: {
      bg: 'Гренада',
      by: 'Грэнада',
      cn: '格林纳达',
      cz: 'Grenada',
      de: 'Grenada',
      ee: 'Grenada',
      el: 'Γρενάδα',
      en: 'Grenada',
      es: 'Granada',
      fr: 'Grenade',
      he: 'גרנדה',
      it: 'Grenada',
      jp: 'グレナダ',
      nl: 'Grenada',
      pl: 'Grenada',
      pt: 'Granada',
      ro: 'Grenada',
      ru: 'Гренада',
      ua: 'Гренада',
      zh: '格林納達',
    },
    phoneMasks: ['473 ### ####'],
  },
  {
    callingCode: '+995',
    cca2: 'GE',
    flag: '🇬🇪',
    name: {
      bg: 'Джорджия',
      by: 'Грузія',
      cn: '乔治亚州',
      cz: 'Gruzie',
      de: 'Georgia',
      ee: 'Gruusia',
      el: 'Γεωργία',
      en: 'Georgia',
      es: 'Georgia',
      fr: 'Géorgie',
      he: 'גאורגיה',
      it: 'Georgia',
      jp: 'ジョージア',
      nl: 'Georgië',
      pl: 'Gruzja',
      pt: 'Geórgia',
      ro: 'Georgia',
      ru: 'Грузия',
      ua: 'Грузія',
      zh: '喬治亞州',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+594',
    cca2: 'GF',
    flag: '🇬🇫',
    name: {
      bg: 'Френска Гвиана',
      by: 'Французская Гвіяна',
      cn: '法属圭亚那',
      cz: 'Francouzská Guyana',
      de: 'Französisch-Guayana',
      ee: 'Prantsuse Guajaana',
      el: 'Γαλλική Γουιάνα',
      en: 'French Guiana',
      es: 'Guayana Francesa',
      fr: 'Guyane Française',
      he: 'גיאנה הצרפתית',
      it: 'Guiana francese',
      jp: 'フランス領ギアナ',
      nl: 'Frans Guyana',
      pl: 'Gujana Francuska',
      pt: 'Guiana Francesa',
      ro: 'Guyana Franceză',
      ru: 'Французская Гвиана',
      ua: 'Французька Гвіана',
      zh: '法屬圭亞那',
    },
    phoneMasks: ['### ## ## ##'],
  },
  {
    callingCode: '+44',
    cca2: 'GG',
    flag: '🇬🇬',
    name: {
      bg: 'Гернси',
      by: 'Гернсі',
      cn: '根西岛',
      cz: 'Guernsey',
      de: 'Guernsey',
      ee: 'Guernsey',
      el: 'Γκέρνσεϊ',
      en: 'Guernsey',
      es: 'Guernsey',
      fr: 'Guernesey',
      he: 'גרנזי',
      it: 'Guernsey',
      jp: 'ガーンジー',
      nl: 'Guernsey',
      pl: 'Guernsey',
      pt: 'Guernsey',
      ro: 'Guernsey',
      ru: 'Гернси',
      ua: 'Гернсі',
      zh: '根西島',
    },
    phoneMasks: ['1481 ######'],
  },
  {
    callingCode: '+233',
    cca2: 'GH',
    flag: '🇬🇭',
    name: {
      bg: 'Гана',
      by: 'Гана',
      cn: '加纳',
      cz: 'Ghana',
      de: 'Ghana',
      ee: 'Ghana',
      el: 'Γκάνα',
      en: 'Ghana',
      es: 'Ghana',
      fr: 'Ghana',
      he: 'גאנה',
      it: 'Ghana',
      jp: 'ガーナ',
      nl: 'Ghana',
      pl: 'Ghana',
      pt: 'Gana',
      ro: 'Ghana',
      ru: 'Гана',
      ua: 'Гана',
      zh: '加納',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+350',
    cca2: 'GI',
    flag: '🇬🇮',
    name: {
      bg: 'Гибралтар',
      by: 'Гібралтар',
      cn: '直布 罗陀',
      cz: 'Gibraltar',
      de: 'Gibraltar',
      ee: 'Gibraltar',
      el: 'Γιβραλτάρ',
      en: 'Gibraltar',
      es: 'Gibraltar',
      fr: 'Gibraltar',
      he: 'גיברלטר',
      it: 'Gibilterra',
      jp: 'ジブラルタル',
      nl: 'Gibraltar',
      pl: 'Gibraltar',
      pt: 'Gibraltar',
      ro: 'Gibraltar',
      ru: 'Гибралтар',
      ua: 'Гібралтар',
      zh: '直布羅 陀',
    },
    phoneMasks: ['#### ####'],
  },
  {
    callingCode: '+299',
    cca2: 'GL',
    flag: '🇬🇱',
    name: {
      bg: 'Гренландия',
      by: 'Грэнландыя',
      cn: '格陵 兰',
      cz: 'Grenlandia',
      de: 'Grönland',
      ee: 'Gröönimaa',
      el: 'Γροιλανδία',
      en: 'Greenland',
      es: 'Groenlandia',
      fr: 'Groenland',
      he: 'גרינלנד',
      it: 'Groenlandia',
      jp: 'グリーンランド',
      nl: 'Groenland',
      pl: 'Grenlandia',
      pt: 'Gronelândia',
      ro: 'Groenlanda',
      ru: 'Гренландия',
      ua: 'Гренландія',
      zh: '格陵蘭',
    },
    phoneMasks: ['## ## ##'],
  },
  {
    callingCode: '+220',
    cca2: 'GM',
    flag: '🇬🇲',
    name: {
      bg: 'Гамбия',
      by: 'Гамбія',
      cn: '冈比亚',
      cz: 'Gambie',
      de: 'Gambia',
      ee: 'Gambia',
      el: 'Γκάμπια',
      en: 'Gambia',
      es: 'Gambia',
      fr: 'Gambie',
      he: 'גמביה',
      it: 'Gambia',
      jp: 'ガンビア',
      nl: 'Gambia',
      pl: 'Gambia',
      pt: 'Gâmbia',
      ro: 'Gambia',
      ru: 'Гамбия',
      ua: 'Гамбія',
      zh: '岡比亞',
    },
    phoneMasks: ['### ## ##'],
  },
  {
    callingCode: '+224',
    cca2: 'GN',
    flag: '🇬🇳',
    name: {
      bg: 'Гвинея',
      by: 'Гвінея',
      cn: '几内亚',
      cz: 'Guinea',
      de: 'Guinea',
      ee: 'Guinea',
      el: 'Γουινέα',
      en: 'Guinea',
      es: 'Guinea',
      fr: 'Guinée',
      he: 'גינאה',
      it: 'Guinea',
      jp: 'ギニア',
      nl: 'Guinea',
      pl: 'Gwinea',
      pt: 'Guiné',
      ro: 'Guineea',
      ru: 'Гвинея',
      ua: 'Гвінея',
      zh: '幾內亞',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+590',
    cca2: 'GP',
    flag: '🇬🇵',
    name: {
      bg: 'Гваделупа',
      by: 'Гвадэлупа',
      cn: '瓜德罗普岛',
      cz: 'Guadeloupe',
      de: 'Guadeloupe',
      ee: 'Guadeloupe',
      el: 'Γουαδελούπη',
      en: 'Guadeloupe',
      es: 'Guadalupe',
      fr: 'Guadeloupe',
      he: 'גוואדלופ',
      it: 'Guadalupa',
      jp: 'グアドループ',
      nl: 'Guadeloupe',
      pl: 'Gwadelupa',
      pt: 'Guadalupe',
      ro: 'Guadelupa',
      ru: 'Гваделупа',
      ua: 'Гваделупа',
      zh: '瓜德羅普島',
    },
    phoneMasks: ['69# ## ## ##'],
  },
  {
    callingCode: '+240',
    cca2: 'GQ',
    flag: '🇬🇶',
    name: {
      bg: 'Екваториална Гвинея',
      by: 'Экватарыяльная Гвінея',
      cn: '赤道几内亚',
      cz: 'Rovníková Guinea',
      de: 'Äquatorialguinea',
      ee: 'Ekvatoriaalne Guinea',
      el: 'Ισημερινή Γουινέα',
      en: 'Equatorial Guinea',
      es: 'Guinea Ecuatorial',
      fr: 'Guinée Équatoriale',
      he: 'גיניאה המשוונית',
      it: 'Guinea Equatoriale',
      jp: '赤道ギニア',
      nl: 'Equatoriaal-Guinea',
      pl: 'Gwinea Równikowa',
      pt: 'Guiné Equatorial',
      ro: 'Guineea Ecuatorială',
      ru: 'Экваториальная Гвинея',
      ua: 'Екваторіальна Гвінея',
      zh: '赤道幾內 亞',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+30',
    cca2: 'GR',
    flag: '🇬🇷',
    name: {
      bg: 'Гърция',
      by: 'Грэцыя',
      cn: '希腊',
      cz: 'Řecko',
      de: 'Griechenland',
      ee: 'Kreeka',
      el: 'Ελλάδα',
      en: 'Greece',
      es: 'Grecia',
      fr: 'Grèce',
      he: 'יוון',
      it: 'Grecia',
      jp: 'ギリシャ',
      nl: 'Griekenland',
      pl: 'Grecja',
      pt: 'Grécia',
      ro: 'Grecia',
      ru: 'Греция',
      ua: 'Греція',
      zh: '希臘',
    },
    phoneMasks: ['### ### ####'],
  },
  {
    callingCode: '+500',
    cca2: 'GS',
    flag: '🇬🇸',
    name: {
      bg: 'Южна Джорджия и Южните сандвичеви острови',
      by: 'Паўднёвая Джорджыя і Паўднёвыя Сандвічавы астравы',
      cn: '南乔治亚岛和南桑威奇群岛',
      cz: 'Jižní Georgie a Jižní Sandwichovy ostrovy',
      de: 'Süd-Georgien und die südlichen Sandwich-Inseln',
      ee: 'Lõuna-Georgia ja Lõuna-Sandwichi saared',
      el: 'Νότιος Γεωργία και Νότιοι Νήσοι Σάντουιτς',
      en: 'South Georgia and the South Sandwich Islands',
      es: 'Georgia del Sur y las Islas Sandwich del Sur',
      fr: 'Géorgie du Sud et îles Sandwich du Sud',
      he: "דרום ג'ורג'יה ואיי סנדוויץ' הדרומיים",
      it: 'Georgia del Sud e isole Sandwich meridionali',
      jp: 'サウスジョージア島とサウスサンドイッチ諸島',
      nl: 'Zuid-Georgië en de Zuidelijke Sandwicheilanden',
      pl: 'Georgia Południowa i Sandwich Południowy',
      pt: 'Geórgia do Sul e Sandwich do Sul',
      ro: 'Georgia de Sud și Insulele Sandwich de Sud',
      ru: 'Южная Георгия и Южные Сандвичевы острова',
      ua: 'Південна Джорджія та Південні Сандвічеві острови',
      zh: '南喬治亞島和南桑威奇群島',
    },
    phoneMasks: ['4####'],
  },
  {
    callingCode: '+502',
    cca2: 'GT',
    flag: '🇬🇹',
    name: {
      bg: 'Гватемала',
      by: 'Гватэмала',
      cn: '危地马拉',
      cz: 'Guatemala',
      de: 'Guatemala',
      ee: 'Guatemala',
      el: 'Γουατεμάλα',
      en: 'Guatemala',
      es: 'Guatemala',
      fr: 'Guatemala',
      he: 'גואטמלה',
      it: 'Guatemala',
      jp: 'グアテマラ',
      nl: 'Guatemala',
      pl: 'Gwatemala',
      pt: 'Guatemala',
      ro: 'Guatemala',
      ru: 'Гватемала',
      ua: 'Гватемала',
      zh: '危地馬拉',
    },
    phoneMasks: ['### ####'],
  },
  {
    callingCode: '+1',
    cca2: 'GU',
    flag: '🇬🇺',
    name: {
      bg: 'Гуам',
      by: 'Гуам',
      cn: '关岛',
      cz: 'Guam',
      de: 'Guam',
      ee: 'Guam',
      el: 'Γκουάμ',
      en: 'Guam',
      es: 'Guam',
      fr: 'Guam',
      he: 'גואם',
      it: 'Guam',
      jp: 'グアム',
      nl: 'Guam',
      pl: 'Guam',
      pt: 'Guame',
      ro: 'Guam',
      ru: 'Гуам',
      ua: 'Гуам',
      zh: '關島',
    },
    phoneMasks: ['671 ### ####'],
  },
  {
    callingCode: '+245',
    cca2: 'GW',
    flag: '🇬🇼',
    name: {
      bg: 'Гвинея-Бисау',
      by: 'Гвінея-Бісаў',
      cn: '几内 亚比绍',
      cz: 'Guinea-Bissau',
      de: 'Guinea-Bissau',
      ee: 'Guinea-Bissau',
      el: 'Γουινέα-Μπισσάου',
      en: 'Guinea-Bissau',
      es: 'Guinea-Bissau',
      fr: 'Guinée-Bissau',
      he: 'גינאה-ביסאו',
      it: 'Guinea-Bissau',
      jp: 'ギニアビサウ',
      nl: 'Guinee-Bissau',
      pl: 'Gwinea Bissau',
      pt: 'Guiné-Bissau',
      ro: 'Guineea-Bissau',
      ru: 'Гвинея-Бисау',
      ua: 'Гвінея-Бісау',
      zh: '幾內亞比紹',
    },
    phoneMasks: ['# ######'],
  },
  {
    callingCode: '+592',
    cca2: 'GY',
    flag: '🇬🇾',
    name: {
      bg: 'Гвиана',
      by: 'Гаяна',
      cn: '圭亚那',
      cz: 'Guyana',
      de: 'Guyana',
      ee: 'Guajaana',
      el: 'Γουιάνα',
      en: 'Guyana',
      es: 'Guyana',
      fr: 'Guyane',
      he: 'גיאנה',
      it: 'Guyana',
      jp: 'ガイアナ',
      nl: 'Guyana',
      pl: 'Gujana',
      pt: 'Guiana',
      ro: 'Guyana',
      ru: 'Гайана',
      ua: 'Гайана',
      zh: '圭亞那',
    },
    phoneMasks: ['### ####'],
  },
  {
    callingCode: '+852',
    cca2: 'HK',
    flag: '🇭🇰',
    name: {
      bg: 'Хонг Конг',
      by: 'Ганконг',
      cn: '香港',
      cz: 'Hongkong',
      de: 'Hongkong',
      ee: 'Hongkong',
      el: 'Χονγκ Κονγκ',
      en: 'Hong Kong',
      es: 'Hong Kong',
      fr: 'Hong Kong',
      he: 'הונג קונג',
      it: 'Hong Kong',
      jp: '香港',
      nl: 'Hong Kong',
      pl: 'Hongkong',
      pt: 'Hong Kong',
      ro: 'Hong Kong',
      ru: 'Гонконг',
      ua: 'Гонконг',
      zh: '香港',
    },
    phoneMasks: ['#### ####'],
  },
  {
    callingCode: '+504',
    cca2: 'HN',
    flag: '🇭🇳',
    name: {
      bg: 'Хондурас',
      by: 'Гандурас',
      cn: '洪都拉斯',
      cz: 'Honduras',
      de: 'Honduras',
      ee: 'Honduras',
      el: 'Ονδούρα',
      en: 'Honduras',
      es: 'Honduras',
      fr: 'Honduras',
      he: 'הונדורס',
      it: 'Honduras',
      jp: 'ホンジュラス',
      nl: 'Honduras',
      pl: 'Honduras',
      pt: 'Honduras',
      ro: 'Honduras',
      ru: 'Гондурас',
      ua: 'Гондурас',
      zh: '洪都拉斯',
    },
    phoneMasks: ['#### ####'],
  },
  {
    callingCode: '+385',
    cca2: 'HR',
    flag: '🇭🇷',
    name: {
      bg: 'Хърватия',
      by: 'Харватыя',
      cn: '克罗地亚',
      cz: 'Chorvatsko',
      de: 'Kroatien',
      ee: 'Horvaatia',
      el: 'Κροατία',
      en: 'Croatia',
      es: 'Croacia',
      fr: 'Croatie',
      he: 'קרואטיה',
      it: 'Croazia',
      jp: 'クロアチ ア',
      nl: 'Kroatië',
      pl: 'Chorwacja',
      pt: 'Croácia',
      ro: 'Croaţia',
      ru: 'Хорватия',
      ua: 'Хорватія',
      zh: '克羅地亞',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+509',
    cca2: 'HT',
    flag: '🇭🇹',
    name: {
      bg: 'Хаити',
      by: 'Гаіці',
      cn: '海地',
      cz: 'Haiti',
      de: 'Haiti',
      ee: 'Haiti',
      el: 'Αϊτή',
      en: 'Haiti',
      es: 'Haití',
      fr: 'Haïti',
      he: 'האיטי',
      it: 'Haiti',
      jp: 'ハイチ',
      nl: 'Haïti',
      pl: 'Haiti',
      pt: 'Haiti',
      ro: 'Haiti',
      ru: 'Гаити',
      ua: 'Гаїті',
      zh: '海地',
    },
    phoneMasks: ['## ## ####'],
  },
  {
    callingCode: '+36',
    cca2: 'HU',
    flag: '🇭🇺',
    name: {
      bg: 'Унгария',
      by: 'Венгрыя',
      cn: '匈牙利',
      cz: 'Maďarsko',
      de: 'Ungarn',
      ee: 'Ungari',
      el: 'Ουγγαρία',
      en: 'Hungary',
      es: 'Hungría',
      fr: 'Hongrie',
      he: 'הונגריה',
      it: 'Ungheria',
      jp: 'ハンガリー',
      nl: 'Hongarije',
      pl: 'Węgry',
      pt: 'Hungria',
      ro: 'Ungaria',
      ru: 'Венгрия',
      ua: 'Угорщина',
      zh: '匈牙利',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+62',
    cca2: 'ID',
    flag: '🇮🇩',
    name: {
      bg: 'Индонезия',
      by: 'Інданезія',
      cn: '印度尼西亚',
      cz: 'Indonésie',
      de: 'Indonesien',
      ee: 'Indoneesia',
      el: 'Ινδονησία',
      en: 'Indonesia',
      es: 'Indonesia',
      fr: 'Indonésie',
      he: 'אינדונזיה',
      it: 'Indonesia',
      jp: 'インドネシア',
      nl: 'Indonesië',
      pl: 'Indonezja',
      pt: 'Indonésia',
      ro: 'Indonezia',
      ru: 'Индонезия',
      ua: 'Індонезія',
      zh: '印度尼西亞',
    },
    phoneMasks: [
      '## ### ##',
      '## ### ###',
      '## ### ####',
      '### ### ####',
      '### ### ## ###',
    ],
  },
  {
    callingCode: '+353',
    cca2: 'IE',
    flag: '🇮🇪',
    name: {
      bg: 'Ирландия',
      by: 'Ірландыя',
      cn: '爱尔兰',
      cz: 'Irsko',
      de: 'Irland',
      ee: 'Iirimaa',
      el: 'Ιρλανδία',
      en: 'Ireland',
      es: 'Irlanda',
      fr: 'Irlande',
      he: 'אירלנד',
      it: 'Irlanda',
      jp: ' アイルランド',
      nl: 'Ierland',
      pl: 'Irlandia',
      pt: 'Irlanda',
      ro: 'Irlanda',
      ru: 'Ирландия',
      ua: 'Ірландія',
      zh: '愛爾蘭',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+972',
    cca2: 'IL',
    flag: '🇮🇱',
    name: {
      bg: 'Израел',
      by: 'Ізраіль',
      cn: '以色列',
      cz: 'Izrael',
      de: 'Israel',
      ee: 'Iisrael',
      el: 'Ισραήλ',
      en: 'Israel',
      es: 'Israel',
      fr: 'Israël',
      he: 'ישראל',
      it: 'Israele',
      jp: 'イスラエル',
      nl: 'Israël',
      pl: 'Izrael',
      pt: 'Israel',
      ro: 'Israel',
      ru: 'Израиль',
      ua: 'Ізраїль',
      zh: '以色列',
    },
    phoneMasks: ['# ### ####', '## ### ####'],
  },
  {
    callingCode: '+44',
    cca2: 'IM',
    flag: '🇮🇲',
    name: {
      bg: 'Остров Ман',
      by: 'Востраў Мэн',
      cn: '马恩岛',
      cz: 'Isle of Man',
      de: 'Isle of Man',
      ee: 'Mani saar',
      el: 'Νήσος Μαν',
      en: 'Isle of Man',
      es: 'Isla de Man',
      fr: 'île de Man',
      he: 'האי מאן',
      it: 'Isola di Man',
      jp: 'マン島',
      nl: 'Isle of Man',
      pl: 'Wyspa Man',
      pt: 'Ilha de Man',
      ro: 'insula Barbatului',
      ru: 'Остров Мэн',
      ua: 'Острів Мен',
      zh: '馬恩島',
    },
    phoneMasks: ['1624 ######'],
  },
  {
    callingCode: '+91',
    cca2: 'IN',
    flag: '🇮🇳',
    name: {
      bg: 'Индия',
      by: 'Індыя',
      cn: '印度',
      cz: 'Indie',
      de: 'Indien',
      ee: 'India',
      el: 'Ινδία',
      en: 'India',
      es: 'India',
      fr: 'Inde',
      he: 'הוֹדוּ',
      it: 'India',
      jp: 'インド',
      nl: 'India',
      pl: 'Indie',
      pt: 'Índia',
      ro: 'India',
      ru: 'Индия',
      ua: 'Індія',
      zh: '印度',
    },
    phoneMasks: ['#### ### ###'],
  },
  {
    callingCode: '+246',
    cca2: 'IO',
    flag: '🇮🇴',
    name: {
      bg: 'Британска територия в Индийския океан',
      by: 'Брытанская тэрыторыя ў Індыйскім акіяне',
      cn: '英属印度洋领地',
      cz: 'Britské území v Indickém oceánu',
      de: 'Britisches Territorium des Indischen Ozeans',
      ee: 'Briti India ookeani territoorium',
      el: 'Βρετανικά Εδάφη Ινδικού Ωκεανού',
      en: 'British Indian Ocean Territory',
      es: 'Territorio Británico del Océano Índico',
      fr: "Territoire britannique de l'océan Indien",
      he: 'טריטוריית האוקיינוס ​​ההודי הבריטי',
      it: "Territorio britannico dell'Oceano Indiano",
      jp: 'イギリス領インド洋地域',
      nl: 'Brits-Indisch oceaan gebied',
      pl: 'Brytyjskie Terytorium Oceanu Indyjskiego',
      pt: 'Território Britânico do Oceano Índico',
      ro: 'Teritoriul Britanic al Oceanului Indian',
      ru: 'Британская территория Индийского океана',
      ua: 'Британська територія в Індійському океані',
      zh: '英屬印度洋領地',
    },
    phoneMasks: ['## ##### ####'],
  },
  {
    callingCode: '+964',
    cca2: 'IQ',
    flag: '🇮🇶',
    name: {
      bg: 'Ирак',
      by: 'Ірак',
      cn: '伊拉克',
      cz: 'Irák',
      de: 'Irak',
      ee: 'Iraak',
      el: 'Ιράκ',
      en: 'Iraq',
      es: 'Irak',
      fr: 'Irak',
      he: 'עיראק',
      it: 'Iraq',
      jp: 'イラク',
      nl: 'Irak',
      pl: 'Irak',
      pt: 'Iraque',
      ro: 'Irak',
      ru: 'Ирак',
      ua: 'Ірак',
      zh: '伊拉克',
    },
    phoneMasks: ['### ### ####'],
  },
  {
    callingCode: '+98',
    cca2: 'IR',
    flag: '🇮🇷',
    name: {
      bg: 'Иран, Ислямска република',
      by: 'Іран, Ісламская Рэспубліка',
      cn: '伊朗伊斯兰共和国',
      cz: 'Írán, Islámská Republika',
      de: 'Iran, Islamische Republik',
      ee: 'Iraan, Iraagi Islamivabariik',
      el: 'Ιράν',
      en: 'Iran, Islamic Republic of',
      es: 'Irán, República Islámica de',
      fr: 'Iran (République islamique d',
      he: 'איראן',
      it: 'Iran (Repubblica Islamica del',
      jp: 'イラン、イスラム共和国',
      nl: 'Iran, Islamitische Republiek',
      pl: 'Iran, Republika Islamska',
      pt: 'Irão',
      ro: 'Iran (Republica Islamica a',
      ru: 'Иран, Исламская Республика',
      ua: 'Іран, Ісламська Республіка',
      zh: '伊朗伊斯蘭共和國',
    },
    phoneMasks: ['### ### ####'],
  },
  {
    callingCode: '+354',
    cca2: 'IS',
    flag: '🇮🇸',
    name: {
      bg: 'Исландия',
      by: 'Ісландыя',
      cn: '冰岛',
      cz: 'Island',
      de: 'Island',
      ee: 'Island',
      el: 'Ισλανδία',
      en: 'Iceland',
      es: 'Islandia',
      fr: 'Islande',
      he: 'איסלנד',
      it: 'Islanda',
      jp: 'アイスランド',
      nl: 'IJsland',
      pl: 'Islandia',
      pt: 'Islândia',
      ro: 'Islanda',
      ru: 'Исландия',
      ua: 'Ісландія',
      zh: '冰島',
    },
    phoneMasks: ['### ####'],
  },
  {
    callingCode: '+39',
    cca2: 'IT',
    flag: '🇮🇹',
    name: {
      bg: 'Италия',
      by: 'Італія',
      cn: '意大利',
      cz: 'Itálie',
      de: 'Italien',
      ee: 'Itaalia',
      el: 'Ιταλία',
      en: 'Italy',
      es: 'Italia',
      fr: 'Italie',
      he: 'איטליה',
      it: 'Italia',
      jp: 'イタリア',
      nl: 'Italië',
      pl: 'Włochy',
      pt: 'Itália',
      ro: 'Italia',
      ru: 'Италия',
      ua: 'Італія',
      zh: '意大利',
    },
    phoneMasks: ['# ### #####', '### #### ###'],
  },
  {
    callingCode: '+44',
    cca2: 'JE',
    flag: '🇯🇪',
    name: {
      bg: 'Джърси',
      by: 'Джэрсі',
      cn: '球衣',
      cz: 'Trikot',
      de: 'Jersey',
      ee: 'Jersey',
      el: 'Τζέρσεϊ',
      en: 'Jersey',
      es: 'Jersey',
      fr: 'Jersey',
      he: "ג'רזי",
      it: 'Jersey',
      jp: 'ジャージー',
      nl: 'Jersey',
      pl: 'Jersey',
      pt: 'Jersey',
      ro: 'Jersey',
      ru: 'Джерси',
      ua: 'Джерсі',
      zh: '球衣',
    },
    phoneMasks: ['1534 ######'],
  },
  {
    callingCode: '+1',
    cca2: 'JM',
    flag: '🇯🇲',
    name: {
      bg: 'Ямайка',
      by: 'Ямайка',
      cn: '的牙买加',
      cz: 'Jamaica',
      de: 'Jamaika',
      ee: 'Jamaica',
      el: 'Τζαμάικα',
      en: 'Jamaica',
      es: 'Jamaica',
      fr: 'Jamaïque',
      he: "ג'מייקה",
      it: 'Giamaica',
      jp: 'ジャマイカ',
      nl: 'Jamaica',
      pl: 'Jamajka',
      pt: 'Jamaica',
      ro: 'Jamaica',
      ru: 'Ямайка',
      ua: 'Ямайка',
      zh: '的牙買加',
    },
    phoneMasks: ['876 ### ####'],
  },
  {
    callingCode: '+962',
    cca2: 'JO',
    flag: '🇯🇴',
    name: {
      bg: 'Йордания',
      by: 'Іарданія',
      cn: '约旦',
      cz: 'Jordán',
      de: 'Jordanien',
      ee: 'Jordaania',
      el: 'Ιορδανία',
      en: 'Jordan',
      es: 'Jordania',
      fr: 'Jordan',
      he: 'ירדן',
      it: 'Giordania',
      jp: 'ヨルダン',
      nl: 'Jordanië',
      pl: 'Jordania',
      pt: 'Jordânia',
      ro: 'Iordania',
      ru: 'Иордания',
      ua: 'Йорданія',
      zh: '約旦',
    },
    phoneMasks: ['# #### ####'],
  },
  {
    callingCode: '+81',
    cca2: 'JP',
    flag: '🇯🇵',
    name: {
      bg: 'Япония',
      by: 'Японія',
      cn: '日本',
      cz: 'Japonsko',
      de: 'Japan',
      ee: 'Jaapan',
      el: 'Ιαπωνία',
      en: 'Japan',
      es: 'Japón',
      fr: 'Japon',
      he: 'יפן',
      it: 'Giappone',
      jp: '日本',
      nl: 'Japan',
      pl: 'Japonia',
      pt: 'Japão',
      ro: 'Japonia',
      ru: 'Япония',
      ua: 'Японія',
      zh: '日本',
    },
    phoneMasks: ['### ### ###', '## #### ####'],
  },
  {
    callingCode: '+254',
    cca2: 'KE',
    flag: '🇰🇪',
    name: {
      bg: 'Кения',
      by: 'Кенія',
      cn: '肯尼亚',
      cz: 'Keňa',
      de: 'Kenia',
      ee: 'Keenia',
      el: 'Κένυα',
      en: 'Kenya',
      es: 'Kenia',
      fr: 'Kenya',
      he: 'קניה',
      it: 'Kenya',
      jp: 'ケニア',
      nl: 'Kenia',
      pl: 'Kenia',
      pt: 'Quénia',
      ro: 'Kenya',
      ru: 'Кения',
      ua: 'Кенія',
      zh: '肯尼亞',
    },
    phoneMasks: ['### ######'],
  },
  {
    callingCode: '+996',
    cca2: 'KG',
    flag: '🇰🇬',
    name: {
      bg: 'Киргизстан',
      by: 'Кыргызстан',
      cn: '吉尔吉斯斯坦',
      cz: 'Kyrgyzstán',
      de: 'Kirgisistan',
      ee: 'Kõrgõzstan',
      el: 'Κιργιζία',
      en: 'Kyrgyzstan',
      es: 'Kirguistán',
      fr: 'Kirghizistan',
      he: 'קירגיזסטן',
      it: 'Kirghizistan',
      jp: 'キルギスタン',
      nl: 'Kirgizië',
      pl: 'Kirgistan',
      pt: 'Quirguizistão',
      ro: 'Kârgâzstan',
      ru: 'Kyrgyzstan',
      ua: 'Киргизстан',
      zh: '吉爾吉斯斯坦',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+855',
    cca2: 'KH',
    flag: '🇰🇭',
    name: {
      bg: 'Камбоджа',
      by: 'Камбоджа',
      cn: '柬埔寨',
      cz: 'Kambodža',
      de: 'Kambodscha',
      ee: 'Kambodža',
      el: 'Καμπότζη',
      en: 'Cambodia',
      es: 'Camboya',
      fr: 'Cambodge',
      he: 'קמבודיה',
      it: 'Cambogia',
      jp: 'カンボジア',
      nl: 'Cambodja',
      pl: 'Kambodża',
      pt: 'Camboja',
      ro: 'Cambodgia',
      ru: 'Камбоджа',
      ua: 'Камбоджа',
      zh: '柬埔寨',
    },
    phoneMasks: ['## ### ###', '## ### ####'],
  },
  {
    callingCode: '+686',
    cca2: 'KI',
    flag: '🇰🇮',
    name: {
      bg: 'Кирибати',
      by: 'Кірыбаці',
      cn: '基里巴斯',
      cz: 'Kiribati',
      de: 'Kiribati',
      ee: 'Kiribati',
      el: 'Κιριμπάτι',
      en: 'Kiribati',
      es: 'Kiribati',
      fr: 'Kiribati',
      he: 'קיריבטי',
      it: 'Kiribati',
      jp: 'キリバス',
      nl: 'Kiribati',
      pl: 'Kiribati',
      pt: 'Quiribáti',
      ro: 'Kiribati',
      ru: 'Кирибати',
      ua: 'Kiribati',
      zh: '基里巴斯',
    },
    phoneMasks: ['## ###'],
  },
  {
    callingCode: '+269',
    cca2: 'KM',
    flag: '🇰🇲',
    name: {
      bg: 'Коморски острови',
      by: 'Каморскія выспы',
      cn: '科摩罗',
      cz: 'Komory',
      de: 'Komoren',
      ee: 'Komoorid',
      el: 'Κομόρες',
      en: 'Comoros',
      es: 'Comoras',
      fr: 'Comores',
      he: 'קומורו',
      it: 'Comore',
      jp: 'コモロ',
      nl: 'Comoren',
      pl: 'Komory',
      pt: 'Comores',
      ro: 'Comore',
      ru: 'Коморские острова',
      ua: 'Коморські острови',
      zh: '科 摩羅',
    },
    phoneMasks: ['## #####'],
  },
  {
    callingCode: '+1',
    cca2: 'KN',
    flag: '🇰🇳',
    name: {
      bg: 'Сейнт Китс и Невис',
      by: 'Сэнт-Кітс і Нэвіс',
      cn: '圣基茨和尼维斯',
      cz: 'Svatý Kryštof a Nevis',
      de: 'St. Kitts und Nevis',
      ee: 'Saint Kitts ja Nevis',
      el: 'Άγιος Χριστόφορος και Νέβις',
      en: 'Saint Kitts and Nevis',
      es: 'San Cristóbal y Nieves',
      fr: 'Saint-Christophe-et-Niévès',
      he: 'סנט קיטס ונוויס',
      it: 'Saint Kitts e Nevis',
      jp: 'セントクリストファーネ イビス',
      nl: 'Saint Kitts en Nevis',
      pl: 'Saint Kitts i Nevis',
      pt: 'São Cristóvão e Neves',
      ro: 'Sfântul Kitts și Nevis',
      ru: 'Сент-Китс и Невис',
      ua: 'Сент-Кітс і Невіс',
      zh: '聖基茨和尼維斯',
    },
    phoneMasks: ['869 ### ####'],
  },
  {
    callingCode: '+850',
    cca2: 'KP',
    flag: '🇰🇵',
    name: {
      bg: 'Корея, Демократична народна република',
      by: 'Карэя, Народна-Дэмакратычная Рэспубліка',
      cn: '韩国，朝鲜民主主义人民共和国',
      cz: 'Korea, Demokratická lidová republika',
      de: 'Korea, Demokratische Volksrepublik',
      ee: 'Korea, Korea Rahvademokraatlik Vabariik',
      el: 'Βόρεια Κορέα',
      en: "Korea, Democratic People's Republic of",
      es: 'Corea, República Popular Democrática de',
      fr: 'République populaire démocratique de Corée',
      he: 'קוריאה הצפונית',
      it: 'Corea, Repubblica Popolare Democratica di',
      jp: '韓国、朝鮮民主主義人民共和国',
      nl: 'Korea, Democratische Volksrepubliek',
      pl: 'Korea, Republika Ludowo-Demokratyczna',
      pt: 'Coreia do Norte',
      ro: 'Coreea, Republica Populară Democrată din',
      ru: 'Корея, Народно-Демократическая Республика',
      ua: 'Корея, Народно-Демократична Республіка',
      zh: '韓國，朝 鮮民主主義人民共和國',
    },
    phoneMasks: [
      '### ###',
      '#### ####',
      '## ### ###',
      '### #### ###',
      '#### #############',
    ],
  },
  {
    callingCode: '+82',
    cca2: 'KR',
    flag: '🇰🇷',
    name: {
      bg: 'Република Корея',
      by: 'Карэя, Рэспубліка',
      cn: '韩国',
      cz: 'Korea, republika',
      de: 'Korea, Republik von',
      ee: 'Korea Vabariik',
      el: 'Νότια Κορέα',
      en: 'Korea, Republic of',
      es: 'Corea, República de',
      fr: 'Corée, République de',
      he: 'קוריאה הדרומית',
      it: 'Corea, Repubblica di',
      jp: '大韓民国',
      nl: 'Korea, republiek van',
      pl: 'Republika Korei',
      pt: 'Coreia do Sul',
      ro: 'Republica Coreea',
      ru: 'Республика Корея',
      ua: 'Корея, Республіка',
      zh: '韓國',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+965',
    cca2: 'KW',
    flag: '🇰🇼',
    name: {
      bg: 'Кувейт',
      by: 'Кувейт',
      cn: ' 科威特',
      cz: 'Kuvajt',
      de: 'Kuwait',
      ee: 'Kuveit',
      el: 'Κουβέιτ',
      en: 'Kuwait',
      es: 'Kuwait',
      fr: 'Koweit',
      he: 'כווית',
      it: 'Kuwait',
      jp: 'クウェート',
      nl: 'Koeweit',
      pl: 'Kuwejt',
      pt: 'Kuwait',
      ro: 'Kuweit',
      ru: 'Кувейт',
      ua: 'Кувейт',
      zh: '科威特',
    },
    phoneMasks: ['#### ####'],
  },
  {
    callingCode: '+345',
    cca2: 'KY',
    flag: '🇰🇾',
    name: {
      bg: 'Кайманови острови',
      by: 'Кайманавы выспы',
      cn: '开曼 群岛',
      cz: 'Kajmanské ostrovy',
      de: 'Cayman Inseln',
      ee: 'Kaimani saared',
      el: 'Νήσοι Κέιμαν',
      en: 'Cayman Islands',
      es: 'Islas Caimán',
      fr: 'Îles Caïmans',
      he: 'איי קיימן',
      it: 'Isole Cayman',
      jp: 'ケイマン諸島',
      nl: 'Kaaiman Eilanden',
      pl: 'Kajmany',
      pt: 'Ilhas Caimão',
      ro: 'Insulele Cayman',
      ru: 'Каймановы острова',
      ua: 'Кайманові острови',
      zh: ' 開曼群島',
    },
    phoneMasks: ['### ####'],
  },
  {
    callingCode: '+77',
    cca2: 'KZ',
    flag: '🇰🇿',
    name: {
      bg: 'Казахстан',
      by: 'Казахстан',
      cn: '哈萨克斯坦',
      cz: 'Kazachstán',
      de: 'Kasachstan',
      ee: 'Kasahstan',
      el: 'Καζακστάν',
      en: 'Kazakhstan',
      es: 'Kazajstán',
      fr: 'Kazakhstan',
      he: 'קזחסטן',
      it: 'Kazakistan',
      jp: 'カザフスタン',
      nl: 'Kazachstan',
      pl: 'Kazachstan',
      pt: 'Cazaquistão',
      ro: 'Kazahstan',
      ru: 'Казахстан',
      ua: 'Казахстан',
      zh: '哈薩克斯坦',
    },
    phoneMasks: ['## ### ## ##'],
  },
  {
    callingCode: '+856',
    cca2: 'LA',
    flag: '🇱🇦',
    name: {
      bg: 'Лаосска народнодемократична република',
      by: 'Лаоская Народна-Дэмакратычная Рэспубліка',
      cn: '老挝人民民主共和国',
      cz: 'Laoská lidově demokratická republika',
      de: 'Demokratische Volksrepublik Laos',
      ee: 'Laose Demokraatlik Rahvavabariik',
      el: 'Λάος',
      en: "Lao People's Democratic Republic",
      es: 'República Democrática Popular de Laos',
      fr: 'République démocratique populaire lao',
      he: 'הרפובליקה הדמוקרטית העממית של לאו',
      it: 'Repubblica Democratica Popolare del Laos',
      jp: 'ラオス人民民主共和国',
      nl: 'Lao Democratische Volksrepubliek',
      pl: 'Laotańska Republika Ludowo-Demokratyczna',
      pt: 'Laos',
      ro: 'Republica Populară Democrată Laos',
      ru: 'Лаосская Народно-Демократическая Республика',
      ua: 'Лаоська Народно-Демократична Республіка',
      zh: '老撾人民民主共和國',
    },
    phoneMasks: ['## ### ###', '#### ### ###'],
  },
  {
    callingCode: '+961',
    cca2: 'LB',
    flag: '🇱🇧',
    name: {
      bg: 'Ливан',
      by: 'Ліван',
      cn: '黎巴嫩的',
      cz: 'Libanon',
      de: 'Libanon',
      ee: 'Liibanon',
      el: 'Λίβανος',
      en: 'Lebanon',
      es: 'Líbano',
      fr: 'Liban',
      he: 'לבנון',
      it: 'Libano',
      jp: 'レバノン',
      nl: 'Libanon',
      pl: 'Liban',
      pt: 'Líbano',
      ro: 'Liban',
      ru: 'Ливан',
      ua: 'Ліван',
      zh: '黎巴嫩的',
    },
    phoneMasks: ['## ### ###', '# ### ###'],
  },
  {
    callingCode: '+1',
    cca2: 'LC',
    flag: '🇱🇨',
    name: {
      bg: 'Сейнт Лусия',
      by: 'Святая Люсія',
      cn: '圣卢西亚',
      cz: 'Svatá Lucie',
      de: 'St. Lucia',
      ee: 'Saint Lucia',
      el: 'Αγία Λουκία',
      en: 'Saint Lucia',
      es: 'Santa Lucía',
      fr: 'Sainte-Lucie',
      he: 'סנט לוסיה',
      it: 'Santa Lucia',
      jp: 'セントルシア',
      nl: 'Saint Lucia',
      pl: 'święta Lucia',
      pt: 'Santa Lúcia',
      ro: 'Sfânta Lucia',
      ru: 'Санкт-Люсия',
      ua: 'Сент-Люсія',
      zh: '聖盧西亞',
    },
    phoneMasks: ['758 ### ####'],
  },
  {
    callingCode: '+423',
    cca2: 'LI',
    flag: '🇱🇮',
    name: {
      bg: 'Лихтенщайн',
      by: 'Ліхтэнштэйн',
      cn: '列支敦士 登',
      cz: 'Lichtenštejnsko',
      de: 'Liechtenstein',
      ee: 'Liechtenstein',
      el: 'Λίχτενσταϊν',
      en: 'Liechtenstein',
      es: 'Liechtenstein',
      fr: 'Liechtenstein',
      he: 'ליכטנשטיין',
      it: 'Liechtenstein',
      jp: 'リヒテンシュタイン',
      nl: 'Liechtenstein',
      pl: 'Liechtenstein',
      pt: 'Listenstaine',
      ro: 'Liechtenstein',
      ru: 'Лихтенштейн',
      ua: 'Ліхтенштейн',
      zh: '列支敦士登',
    },
    phoneMasks: ['### ### ####'],
  },
  {
    callingCode: '+94',
    cca2: 'LK',
    flag: '🇱🇰',
    name: {
      bg: 'Шри Ланка',
      by: 'Шры Ланка',
      cn: '斯里兰卡',
      cz: 'Srí Lanka',
      de: 'Sri Lanka',
      ee: 'Sri Lanka',
      el: 'Σρι Λάνκα',
      en: 'Sri Lanka',
      es: 'Sri Lanka',
      fr: 'Sri Lanka',
      he: 'סרי לנקה',
      it: 'Sri Lanka',
      jp: ' スリランカ',
      nl: 'Sri Lanka',
      pl: 'Sri Lanka',
      pt: 'Sri Lanca',
      ro: 'Sri Lanka',
      ru: 'Шри-Ланка',
      ua: 'Шрі Ланка',
      zh: '斯里蘭卡',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+231',
    cca2: 'LR',
    flag: '🇱🇷',
    name: {
      bg: 'Либерия',
      by: 'Ліберыя',
      cn: '利比里亚',
      cz: 'Libérie',
      de: 'Liberia',
      ee: 'Libeeria',
      el: 'Λιβερία',
      en: 'Liberia',
      es: 'Liberia',
      fr: 'Libéria',
      he: 'ליבריה',
      it: 'Liberia',
      jp: 'リベリア',
      nl: 'Liberia',
      pl: 'Liberia',
      pt: 'Libéria',
      ro: 'Liberia',
      ru: 'Либерия',
      ua: 'Ліберія',
      zh: '利比里亞',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+266',
    cca2: 'LS',
    flag: '🇱🇸',
    name: {
      bg: 'Лесото',
      by: 'Лесота',
      cn: '莱索托',
      cz: 'Lesotho',
      de: 'Lesotho',
      ee: 'Lesotho',
      el: 'Λεσότο',
      en: 'Lesotho',
      es: 'Lesotho',
      fr: 'Lesotho',
      he: 'לסוטו',
      it: 'Lesotho',
      jp: 'レソト',
      nl: 'Lesotho',
      pl: 'Lesoto',
      pt: 'Lesoto',
      ro: 'Lesotho',
      ru: 'Лесото',
      ua: 'Лесото',
      zh: '萊索托',
    },
    phoneMasks: ['# ### ####'],
  },
  {
    callingCode: '+370',
    cca2: 'LT',
    flag: '🇱🇹',
    name: {
      bg: 'Литва',
      by: 'Літва',
      cn: '立陶宛',
      cz: 'Litva',
      de: 'Litauen',
      ee: 'Leedu',
      el: 'Λιθουανία',
      en: 'Lithuania',
      es: 'Lituania',
      fr: 'Lituanie',
      he: 'ליטא',
      it: 'Lituania',
      jp: 'リトアニア',
      nl: 'Litouwen',
      pl: 'Litwa',
      pt: 'Lituânia',
      ro: 'Lituania',
      ru: 'Литва',
      ua: 'Литва',
      zh: '立陶宛',
    },
    phoneMasks: ['### ## ###'],
  },
  {
    callingCode: '+352',
    cca2: 'LU',
    flag: '🇱🇺',
    name: {
      bg: 'Люксембург',
      by: 'Люксембург',
      cn: '卢森堡',
      cz: 'Lucembursko',
      de: 'Luxemburg',
      ee: 'Luksemburg',
      el: 'Λουξεμβούργο',
      en: 'Luxembourg',
      es: 'Luxemburgo',
      fr: 'Luxembourg',
      he: 'לוקסמבורג',
      it: 'Lussemburgo',
      jp: 'ルクセンブルク',
      nl: 'Luxemburg',
      pl: 'Luksemburg',
      pt: 'Luxemburgo',
      ro: 'Luxemburg',
      ru: 'Люксембург',
      ua: 'Люксембург',
      zh: '盧森堡',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+371',
    cca2: 'LV',
    flag: '🇱🇻',
    name: {
      bg: 'Латвия',
      by: 'Латвія',
      cn: '拉脱维亚',
      cz: 'Lotyšsko',
      de: 'Lettland',
      ee: 'Läti',
      el: 'Λετονία',
      en: 'Latvia',
      es: 'Letonia',
      fr: 'Lettonie',
      he: 'לטביה',
      it: 'Lettonia',
      jp: 'ラトビア',
      nl: 'Letland',
      pl: 'Łotwa',
      pt: 'Letónia',
      ro: 'Letonia',
      ru: 'Латвия',
      ua: 'Латвія',
      zh: '拉脫維亞',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+218',
    cca2: 'LY',
    flag: '🇱🇾',
    name: {
      bg: 'Либийска арабска Джамахирия',
      by: 'Лівійская Арабская Джамахірыя',
      cn: '阿拉伯利比亚民众国',
      cz: 'Libyjský Arab Jamahiriya',
      de: 'Libyscher arabischer Jamahiriya',
      ee: 'Liibüa Araabia Jamahiriya',
      el: 'Λιβύη',
      en: 'Libyan Arab Jamahiriya',
      es: 'Jamahiriya Árabe Libia',
      fr: 'Jamahiriya arabe libyenne',
      he: "ג'מהיריה הערבית הלובית",
      it: 'Giamahiria araba libica',
      jp: 'リビアアラ ブジャマヒリヤ',
      nl: 'Libië',
      pl: 'Libijska Arabska Dżamahirija',
      pt: 'Líbia',
      ro: 'Jamahiriya arabă libiană',
      ru: 'Ливийская Арабская Джамахирия',
      ua: 'Лівійська Арабська Джамахірія',
      zh: '阿拉伯利比亞民眾國',
    },
    phoneMasks: ['## ### ###', '## ### ####'],
  },
  {
    callingCode: '+212',
    cca2: 'MA',
    flag: '🇲🇦',
    name: {
      bg: 'Мароко',
      by: 'Марока',
      cn: '摩洛哥',
      cz: 'Maroko',
      de: 'Marokko',
      ee: 'Maroko',
      el: 'Μαρόκο',
      en: 'Morocco',
      es: 'Marruecos',
      fr: 'Maroc',
      he: 'מרוקו',
      it: 'Marocco',
      jp: 'モロッコ',
      nl: 'Marokko',
      pl: 'Maroko',
      pt: 'Marrocos',
      ro: 'Maroc',
      ru: 'Марокко',
      ua: 'Марокко',
      zh: '摩洛哥',
    },
    phoneMasks: ['## #### ###'],
  },
  {
    callingCode: '+377',
    cca2: 'MC',
    flag: '🇲🇨',
    name: {
      bg: 'Монако',
      by: 'Манака',
      cn: '摩纳哥',
      cz: 'Monako',
      de: 'Monaco',
      ee: 'Monaco',
      el: 'Μονακό',
      en: 'Monaco',
      es: 'Mónaco',
      fr: 'Monaco',
      he: 'מונקו',
      it: 'Monaco',
      jp: ' モナコ',
      nl: 'Monaco',
      pl: 'Monako',
      pt: 'Mónaco',
      ro: 'Monaco',
      ru: 'Монако',
      ua: 'Монако',
      zh: '摩納哥',
    },
    phoneMasks: ['## ### ###', '### ### ###'],
  },
  {
    callingCode: '+373',
    cca2: 'MD',
    flag: '🇲🇩',
    name: {
      bg: 'Молдова, Република',
      by: 'Малдова, Рэспубліка',
      cn: '摩尔多瓦共和国',
      cz: 'Moldavsko, republika',
      de: 'Moldawien, Republik',
      ee: 'Moldova Vabariik',
      el: 'Μολδαβία',
      en: 'Moldova, Republic of',
      es: 'Moldavia, República de',
      fr: 'Moldova, République de',
      he: 'מולדובה',
      it: 'Moldova, Repubblica di',
      jp: 'モルドバ共和国',
      nl: 'Moldavië, Republiek',
      pl: 'Mołdawia, Republika',
      pt: 'Moldávia',
      ro: 'Moldova, Republica',
      ru: 'Молдова, Республика',
      ua: 'Молдова, республіка',
      zh: '摩爾多瓦共和國',
    },
    phoneMasks: ['#### ####'],
  },
  {
    callingCode: '+382',
    cca2: 'ME',
    flag: '🇲🇪',
    name: {
      bg: 'Черна гора',
      by: 'Чарнагорыя',
      cn: '黑山共和国',
      cz: 'Černá Hora',
      de: 'Montenegro',
      ee: 'Montenegro',
      el: 'Μαυροβούνιο',
      en: 'Montenegro',
      es: 'Montenegro',
      fr: 'Monténégro',
      he: 'מונטנגרו',
      it: 'Montenegro',
      jp: 'モンテネグロ',
      nl: 'Montenegro',
      pl: 'Czarnogóra',
      pt: 'Montenegro',
      ro: 'Muntenegru',
      ru: 'Черногория',
      ua: 'Чорногорія',
      zh: '黑山共和國',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+590',
    cca2: 'MF',
    flag: '🇲🇫',
    name: {
      bg: 'Свети Мартин',
      by: 'Святы Марцін',
      cn: '圣马丁',
      cz: 'Svatý Martin',
      de: 'Sankt Martin',
      ee: 'Püha Martin',
      el: 'Άγιος Μαρτίνος',
      en: 'Saint Martin',
      es: 'San Martín',
      fr: 'Saint Martin',
      he: 'מרטין הקדוש',
      it: 'Saint Martin',
      jp: 'サンマルタン',
      nl: 'Sint-Maarten',
      pl: 'święty Marcin',
      pt: 'São Martinho',
      ro: 'Sfântul Martin',
      ru: 'Сен-Мартен',
      ua: 'Святий Мартін',
      zh: '聖馬丁',
    },
    phoneMasks: ['690 ## ## ##'],
  },
  {
    callingCode: '+261',
    cca2: 'MG',
    flag: '🇲🇬',
    name: {
      bg: 'Мадагаскар',
      by: 'Мадагаскар',
      cn: '马达加斯加',
      cz: 'Madagaskar',
      de: 'Madagaskar',
      ee: 'Madagaskar',
      el: 'Μαδαγασκάρη',
      en: 'Madagascar',
      es: 'Madagascar',
      fr: 'Madagascar',
      he: 'מדגסקר',
      it: 'Madagascar',
      jp: 'マダガスカル',
      nl: 'Madagascar',
      pl: 'Madagaskar',
      pt: 'Madagáscar',
      ro: 'Madagascar',
      ru: 'Мадагаскар',
      ua: 'Мадагаскар',
      zh: '馬達加斯加',
    },
    phoneMasks: ['## ## ### ##'],
  },
  {
    callingCode: '+692',
    cca2: 'MH',
    flag: '🇲🇭',
    name: {
      bg: 'Маршалови острови',
      by: 'Маршалавы выспы',
      cn: '马绍尔群岛',
      cz: 'Marshallovy ostrovy',
      de: 'Marshallinseln',
      ee: 'Marshalli saared',
      el: 'Νήσοι Μάρσαλ',
      en: 'Marshall Islands',
      es: 'Islas Marshall',
      fr: 'Iles Marshall',
      he: 'איי מרשל',
      it: 'Isole Marshall',
      jp: 'マーシャル諸島',
      nl: 'Marshall eilanden',
      pl: 'Wyspy Marshalla',
      pt: 'Ilhas Marshall',
      ro: 'Insulele Marshall',
      ru: 'Маршалловы острова',
      ua: 'Маршаллові острови',
      zh: '馬紹爾群島',
    },
    phoneMasks: ['### ####'],
  },
  {
    callingCode: '+389',
    cca2: 'MK',
    flag: '🇲🇰',
    name: {
      bg: 'Македония, бившата югославска република',
      by: 'Македонія, былая югаслаўская рэспубліка',
      cn: '前南斯拉夫共和国马其顿',
      cz: 'Makedonie, Bývalá jugoslávská republika',
      de: 'Mazedonien, die ehemalige jugoslawische Republik',
      ee: 'Makedoonia, endine Jugoslaavia Makedoonia Vabariik',
      el: 'Βόρεια Μακεδονία',
      en: 'Macedonia, The Former Yugoslav Republic of',
      es: 'Macedonia, Antigua República Yugoslava de',
      fr: 'Macédoine, ancienne République yougoslave de',
      he: 'מקדוניה',
      it: 'Macedonia, ex Repubblica iugoslava di',
      jp: 'マケドニア、旧ユーゴスラビア共和国',
      nl: 'Macedonië, de voormalige Joegoslavische Republiek',
      pl: 'Macedonia, Była Jugosłowiańska Republika',
      pt: 'Macedónia',
      ro: 'Macedonia, Fosta Republică Iugoslavă a',
      ru: 'Македония, Бывшая Югославская Республика',
      ua: 'Македонія, колишня югославська республіка',
      zh: '前南斯拉夫共和國馬其頓',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+223',
    cca2: 'ML',
    flag: '🇲🇱',
    name: {
      bg: 'Мали',
      by: 'Малі',
      cn: '马里',
      cz: 'Mali',
      de: 'Mali',
      ee: 'Mali',
      el: 'Μάλι',
      en: 'Mali',
      es: 'Malí',
      fr: 'Mali',
      he: 'מלי',
      it: 'Mali',
      jp: 'マリ',
      nl: 'Mali',
      pl: 'Mali',
      pt: 'Mali',
      ro: 'Mali',
      ru: 'Мали',
      ua: 'Малі',
      zh: '馬里',
    },
    phoneMasks: ['## ## ####'],
  },
  {
    callingCode: '+95',
    cca2: 'MM',
    flag: '🇲🇲',
    name: {
      bg: 'Мианмар',
      by: "М'янма",
      cn: '缅甸',
      cz: 'Myanmar',
      de: 'Myanmar',
      ee: 'Myanmar',
      el: 'Μιανμάρ',
      en: 'Myanmar',
      es: 'Myanmar',
      fr: 'Myanmar',
      he: 'מיאנמר',
      it: 'Myanmar',
      jp: 'ミャン マー',
      nl: 'Myanmar',
      pl: 'Myanmar',
      pt: 'Birmânia',
      ro: 'Myanmar',
      ru: 'Мьянма',
      ua: "М'янма",
      zh: '緬甸',
    },
    phoneMasks: ['### ###', '# ### ###', '## ### ###'],
  },
  {
    callingCode: '+976',
    cca2: 'MN',
    flag: '🇲🇳',
    name: {
      bg: 'Монголия',
      by: 'Манголія',
      cn: '蒙古',
      cz: 'Mongolsko',
      de: 'Mongolei',
      ee: 'Mongoolia',
      el: 'Μογγολία',
      en: 'Mongolia',
      es: 'Mongolia',
      fr: 'Mongolie',
      he: 'מונגוליה',
      it: 'Mongolia',
      jp: 'モンゴル',
      nl: 'Mongolië',
      pl: 'Mongolia',
      pt: 'Mongólia',
      ro: 'Mongolia',
      ru: 'Монголия',
      ua: 'Монголія',
      zh: '蒙古',
    },
    phoneMasks: ['## ## ####'],
  },
  {
    callingCode: '+853',
    cca2: 'MO',
    flag: '🇲🇴',
    name: {
      bg: 'Макао',
      by: 'Макао',
      cn: '澳门',
      cz: 'Macao',
      de: 'Macao',
      ee: 'Aomen',
      el: 'Μακάου',
      en: 'Macao',
      es: 'Macao',
      fr: 'Macao',
      he: 'מקאו',
      it: 'Macao',
      jp: 'マカオ',
      nl: 'Macao',
      pl: 'Makao',
      pt: 'Macau',
      ro: 'Macao',
      ru: 'Макао',
      ua: 'Макао',
      zh: '澳門',
    },
    phoneMasks: ['#### ####'],
  },
  {
    callingCode: '+1',
    cca2: 'MP',
    flag: '🇲🇵',
    name: {
      bg: 'Северни Мариански острови',
      by: 'Паўночныя Марыянскія астравы',
      cn: '北马里亚纳群岛',
      cz: 'Severní Mariany',
      de: 'Nördliche Marianneninseln',
      ee: 'Põhja-Mariaanid',
      el: 'Βόρειες Μαριάνες Νήσοι',
      en: 'Northern Mariana Islands',
      es: 'Islas Marianas del Norte',
      fr: 'Îles Mariannes du Nord',
      he: 'איי מריאנה הצפוניים',
      it: 'Isole Marianne settentrionali',
      jp: '北マリアナ諸島',
      nl: 'noordelijke Mariana eilanden',
      pl: 'Mariany Północne',
      pt: 'Marianas do Norte',
      ro: 'Insulele Marianelor de Nord',
      ru: 'Северные Марианские острова',
      ua: 'Північні Маріанські острови',
      zh: '北馬里亞納群島',
    },
    phoneMasks: ['670 ### ####'],
  },
  {
    callingCode: '+596',
    cca2: 'MQ',
    flag: '🇲🇶',
    name: {
      bg: 'Мартиника',
      by: 'Марцініка',
      cn: '马提尼克岛',
      cz: 'Martinik',
      de: 'Martinique',
      ee: 'Martinique',
      el: 'Μαρτινίκα',
      en: 'Martinique',
      es: 'Martinica',
      fr: 'Martinique',
      he: 'מרטיניק',
      it: 'Martinica',
      jp: 'マルティニー ク',
      nl: 'Martinique',
      pl: 'Martynika',
      pt: 'Martinica',
      ro: 'Martinica',
      ru: 'Мартиника',
      ua: 'Мартініка',
      zh: '馬提尼克島',
    },
    phoneMasks: ['### ## ## ##'],
  },
  {
    callingCode: '+222',
    cca2: 'MR',
    flag: '🇲🇷',
    name: {
      bg: 'Мавритания',
      by: 'Маўрытанія',
      cn: '毛里塔尼亚',
      cz: 'Mauretánie',
      de: 'Mauretanien',
      ee: 'Mauritaania',
      el: 'Μαυριτανία',
      en: 'Mauritania',
      es: 'Mauritania',
      fr: 'Mauritanie',
      he: 'מאוריטניה',
      it: 'Mauritania',
      jp: 'モー リタニア',
      nl: 'Mauritanië',
      pl: 'Mauretania',
      pt: 'Mauritânia',
      ro: 'Mauritania',
      ru: 'Мавритания',
      ua: 'Мавританія',
      zh: '毛里塔尼亞',
    },
    phoneMasks: ['## ## ####'],
  },
  {
    callingCode: '+1',
    cca2: 'MS',
    flag: '🇲🇸',
    name: {
      bg: 'Монсерат',
      by: 'Мансерат',
      cn: '蒙特塞拉特',
      cz: 'Montserrat',
      de: 'Montserrat',
      ee: 'Montserrat',
      el: 'Μοντσερά',
      en: 'Montserrat',
      es: 'Montserrat',
      fr: 'Montserrat',
      he: 'מונטסראט',
      it: 'Montserrat',
      jp: 'モントセラト',
      nl: 'Montserrat',
      pl: 'Montserrat',
      pt: 'Monserrate',
      ro: 'Montserrat',
      ru: 'Монтсеррат',
      ua: 'Монтсеррат',
      zh: '蒙特塞拉特',
    },
    phoneMasks: ['664 ### ####'],
  },
  {
    callingCode: '+356',
    cca2: 'MT',
    flag: '🇲🇹',
    name: {
      bg: 'Малта',
      by: 'Мальта',
      cn: '马耳他',
      cz: 'Malta',
      de: 'Malta',
      ee: 'Malta',
      el: 'Μάλτα',
      en: 'Malta',
      es: 'Malta',
      fr: 'Malte',
      he: 'מלטה',
      it: 'Malta',
      jp: 'マルタ',
      nl: 'Malta',
      pl: 'Malta',
      pt: 'Malta',
      ro: 'Malta',
      ru: 'Мальта',
      ua: 'Мальта',
      zh: '馬耳他',
    },
    phoneMasks: ['#### ####'],
  },
  {
    callingCode: '+230',
    cca2: 'MU',
    flag: '🇲🇺',
    name: {
      bg: 'Мавриций',
      by: 'Маўрыкій',
      cn: '毛里求斯',
      cz: 'Mauricius',
      de: 'Mauritius',
      ee: 'Mauritius',
      el: 'Μαυρίκιος',
      en: 'Mauritius',
      es: 'Mauricio',
      fr: 'Ile Maurice',
      he: 'מאוריציוס',
      it: 'Maurizio',
      jp: 'モーリシャス',
      nl: 'Mauritius',
      pl: 'Mauritius',
      pt: 'Maurícia',
      ro: 'Mauritius',
      ru: 'Маврикий',
      ua: 'Маврикій',
      zh: '毛里求斯',
    },
    phoneMasks: ['### ####'],
  },
  {
    callingCode: '+960',
    cca2: 'MV',
    flag: '🇲🇻',
    name: {
      bg: 'Малдивите',
      by: 'Мальдывы',
      cn: '马尔代夫',
      cz: 'Maledivy',
      de: 'Malediven',
      ee: 'Maldiivid',
      el: 'Μαλδίβες',
      en: 'Maldives',
      es: 'Maldivas',
      fr: 'Maldives',
      he: 'המלדיביים',
      it: 'Maldive',
      jp: 'モルディブ',
      nl: 'Maldiven',
      pl: 'Malediwy',
      pt: 'Maldivas',
      ro: 'Maldive',
      ru: 'Мальдивы',
      ua: 'Мальдіви',
      zh: '馬爾代夫',
    },
    phoneMasks: ['### ####'],
  },
  {
    callingCode: '+265',
    cca2: 'MW',
    flag: '🇲🇼',
    name: {
      bg: 'Малави',
      by: 'Малаві',
      cn: '马拉维',
      cz: 'Malawi',
      de: 'Malawi',
      ee: 'Malawi',
      el: 'Μαλάουι',
      en: 'Malawi',
      es: 'Malawi',
      fr: 'Malawi',
      he: 'מלאווי',
      it: 'Malawi',
      jp: 'マラウイ',
      nl: 'Malawi',
      pl: 'Malawi',
      pt: 'Malávi',
      ro: 'Malawi',
      ru: 'Малави',
      ua: 'Малаві',
      zh: '馬拉維',
    },
    phoneMasks: ['# ### ###', '# #### ####'],
  },
  {
    callingCode: '+52',
    cca2: 'MX',
    flag: '🇲🇽',
    name: {
      bg: 'Мексико',
      by: 'Мексіка',
      cn: '墨西哥',
      cz: 'Mexiko',
      de: 'Mexiko',
      ee: 'Mehhiko',
      el: 'Μεξικό',
      en: 'Mexico',
      es: 'México',
      fr: 'Mexique',
      he: 'מקסיקו',
      it: 'Messico',
      jp: 'メキシコ',
      nl: 'Mexico',
      pl: 'Meksyk',
      pt: 'México',
      ro: 'Mexic',
      ru: 'Мексика',
      ua: 'Мексика',
      zh: '墨西哥',
    },
    phoneMasks: ['### ### ####'],
  },
  {
    callingCode: '+60',
    cca2: 'MY',
    flag: '🇲🇾',
    name: {
      bg: 'Малайзия',
      by: 'Малайзія',
      cn: '马来西亚',
      cz: 'Malajsie',
      de: 'Malaysia',
      ee: 'Malaisia',
      el: 'Μαλαισία',
      en: 'Malaysia',
      es: 'Malasia',
      fr: 'Malaisie',
      he: 'מלזיה',
      it: 'Malaysia',
      jp: 'マレーシア',
      nl: 'Maleisië',
      pl: 'Malezja',
      pt: 'Malásia',
      ro: 'Malaezia',
      ru: 'Малайзия',
      ua: 'Малайзія',
      zh: '馬來西亞',
    },
    phoneMasks: ['# ### ###', '## ### ###', '## ### ####'],
  },
  {
    callingCode: '+258',
    cca2: 'MZ',
    flag: '🇲🇿',
    name: {
      bg: 'Мозамбик',
      by: 'Мазамбік',
      cn: '莫桑比克',
      cz: 'Mosambik',
      de: 'Mosambik',
      ee: 'Mosambiik',
      el: 'Μοζαμβίκη',
      en: 'Mozambique',
      es: 'Mozambique',
      fr: 'Mozambique',
      he: 'מוזמביק',
      it: 'Mozambico',
      jp: 'モザンビーク',
      nl: 'Mozambique',
      pl: 'Mozambik',
      pt: 'Moçambique',
      ro: 'Mozambic',
      ru: 'Мозамбик',
      ua: 'Мозамбік',
      zh: '莫桑比克',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+264',
    cca2: 'NA',
    flag: '🇳🇦',
    name: {
      bg: 'Намибия',
      by: 'Намібія',
      cn: '纳米比亚',
      cz: 'Namibie',
      de: 'Namibia',
      ee: 'Namiibia',
      el: 'Ναμίμπια',
      en: 'Namibia',
      es: 'Namibia',
      fr: 'Namibie',
      he: 'נמיביה',
      it: 'Namibia',
      jp: 'ナミビア',
      nl: 'Namibië',
      pl: 'Namibia',
      pt: 'Namíbia',
      ro: 'Namibia',
      ru: 'Намибия',
      ua: 'Намібія',
      zh: '納米比亞',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+687',
    cca2: 'NC',
    flag: '🇳🇨',
    name: {
      bg: 'Нова Каледония',
      by: 'Новая Каледонія',
      cn: '新喀里多尼亚',
      cz: 'Nová Kaledonie',
      de: 'Neu-Kaledonien',
      ee: 'Uus-Kaledoonia',
      el: 'Νέα Καληδονία',
      en: 'New Caledonia',
      es: 'Nueva Caledonia',
      fr: 'Nouvelle Calédonie',
      he: 'קלדוניה החדשה',
      it: 'Nuova Caledonia',
      jp: 'ニューカレドニア',
      nl: 'Nieuw-Caledonië',
      pl: 'Nowa Kaledonia',
      pt: 'Nova Caledónia',
      ro: 'Noua Caledonie',
      ru: 'Новая Каледония',
      ua: 'Нова Каледонія',
      zh: '新喀里多尼亞',
    },
    phoneMasks: ['### ###'],
  },
  {
    callingCode: '+227',
    cca2: 'NE',
    flag: '🇳🇪',
    name: {
      bg: 'Нигер',
      by: 'Нігер',
      cn: '尼日尔',
      cz: 'Niger',
      de: 'Niger',
      ee: 'Niger',
      el: 'Νίγηρας',
      en: 'Niger',
      es: 'Níger',
      fr: 'Niger',
      he: "ניז'ר",
      it: 'Niger',
      jp: 'ニジェール',
      nl: 'Niger',
      pl: 'Niger',
      pt: 'Níger',
      ro: 'Niger',
      ru: 'Нигер',
      ua: 'Нігер',
      zh: '尼日爾',
    },
    phoneMasks: ['## ## ####'],
  },
  {
    callingCode: '+672',
    cca2: 'NF',
    flag: '🇳🇫',
    name: {
      bg: 'Остров Норфолк',
      by: 'Востраў Норфолк',
      cn: '诺福克岛',
      cz: 'Ostrov Norfolk',
      de: 'Norfolkinsel',
      ee: 'Norfolki saar',
      el: 'Νησί Νόρφολκ',
      en: 'Norfolk Island',
      es: 'Isla de Norfolk',
      fr: "l'ile de Norfolk",
      he: 'האי נורפוק',
      it: 'Isola Norfolk',
      jp: 'ノーフォーク島',
      nl: 'Norfolkeiland',
      pl: 'Wyspa Norfolk',
      pt: 'Ilha Norfolk',
      ro: 'Insula Norfolk',
      ru: 'Остров Норфолк',
      ua: 'Острів Норфолк',
      zh: '諾福克島',
    },
    phoneMasks: ['3 2# ###', '3 5# ###'],
  },
  {
    callingCode: '+234',
    cca2: 'NG',
    flag: '🇳🇬',
    name: {
      bg: 'Нигерия',
      by: 'Нігерыя',
      cn: '奈及利亚',
      cz: 'Nigérie',
      de: 'Nigeria',
      ee: 'Nigeeria',
      el: 'Νιγηρία',
      en: 'Nigeria',
      es: 'Nigeria',
      fr: 'Nigeria',
      he: 'ניגריה',
      it: 'Nigeria',
      jp: 'ナイジェリア',
      nl: 'Nigeria',
      pl: 'Nigeria',
      pt: 'Nigéria',
      ro: 'Nigeria',
      ru: 'Нигерия',
      ua: 'Нігерія',
      zh: '奈及利亞',
    },
    phoneMasks: ['## ### ##', '## ### ###', '### ### ####'],
  },
  {
    callingCode: '+505',
    cca2: 'NI',
    flag: '🇳🇮',
    name: {
      bg: 'Никарагуа',
      by: 'Нікарагуа',
      cn: '尼加拉瓜',
      cz: 'Nikaragua',
      de: 'Nicaragua',
      ee: 'Nicaragua',
      el: 'Νικαράγουα',
      en: 'Nicaragua',
      es: 'Nicaragua',
      fr: 'Nicaragua',
      he: 'ניקרגואה',
      it: 'Nicaragua',
      jp: 'ニカラグア',
      nl: 'Nicaragua',
      pl: 'Nikaragua',
      pt: 'Nicarágua',
      ro: 'Nicaragua',
      ru: 'Никарагуа',
      ua: 'Нікарагуа',
      zh: '尼加拉瓜',
    },
    phoneMasks: ['#### ####'],
  },
  {
    callingCode: '+31',
    cca2: 'NL',
    flag: '🇳🇱',
    name: {
      bg: 'Холандия',
      by: 'Нідэрланды',
      cn: '荷兰',
      cz: 'Holandsko',
      de: 'Niederlande',
      ee: 'Holland',
      el: 'Ολλανδία',
      en: 'Netherlands',
      es: 'Países Bajos',
      fr: 'Pays-Bas',
      he: 'הולנד',
      it: 'Olanda',
      jp: 'オランダ',
      nl: 'Nederland',
      pl: 'Holandia',
      pt: 'Países Baixos',
      ro: 'Olanda',
      ru: 'Нидерланды',
      ua: 'Нідерланди',
      zh: '荷蘭',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+47',
    cca2: 'NO',
    flag: '🇳🇴',
    name: {
      bg: 'Норвегия',
      by: 'Нарвегія',
      cn: '挪威',
      cz: 'Norsko',
      de: 'Norwegen',
      ee: 'Norra',
      el: 'Νορβηγία',
      en: 'Norway',
      es: 'Noruega',
      fr: 'Norvège',
      he: 'נורווגיה',
      it: 'Norvegia',
      jp: 'ノルウェー',
      nl: 'Noorwegen',
      pl: 'Norwegia',
      pt: 'Noruega',
      ro: 'Norvegia',
      ru: 'Норвегия',
      ua: 'Норвегія',
      zh: '挪威',
    },
    phoneMasks: [
      '4## ## ###',
      '9## ## ###',
      '2# ## ## ##',
      '3# ## ## ##',
      '5# ## ## ##',
      '6# ## ## ##',
      '7# ## ## ##',
      '2# ## ## ##',
    ],
  },
  {
    callingCode: '+977',
    cca2: 'NP',
    flag: '🇳🇵',
    name: {
      bg: 'Непал',
      by: 'Непал',
      cn: '尼尼泊尔',
      cz: 'Nepál',
      de: 'Nepal',
      ee: 'Nepal',
      el: 'Νεπάλ',
      en: 'Nepal',
      es: 'Nepal',
      fr: 'Népal',
      he: 'נפאל',
      it: 'Nepal',
      jp: 'ネパール',
      nl: 'Nepal',
      pl: 'Nepal',
      pt: 'Nepal',
      ro: 'Nepal',
      ru: 'Непал',
      ua: 'Непал',
      zh: '尼尼泊爾',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+674',
    cca2: 'NR',
    flag: '🇳🇷',
    name: {
      bg: 'Науру',
      by: 'Науру',
      cn: '瑙鲁',
      cz: 'Nauru',
      de: 'Nauru',
      ee: 'Nauru',
      el: 'Ναουρού',
      en: 'Nauru',
      es: 'Nauru',
      fr: 'Nauru',
      he: 'נאורו',
      it: 'Nauru',
      jp: 'ナウル',
      nl: 'Nauru',
      pl: 'Nauru',
      pt: 'Nauru',
      ro: 'Nauru',
      ru: 'Науру',
      ua: 'Науру',
      zh: '瑙魯',
    },
    phoneMasks: ['### ####'],
  },
  {
    callingCode: '+683',
    cca2: 'NU',
    flag: '🇳🇺',
    name: {
      bg: 'Ниуе',
      by: 'Ніуе',
      cn: '纽埃',
      cz: 'Niue',
      de: 'Niue',
      ee: 'Niue',
      el: 'Νιούε',
      en: 'Niue',
      es: 'Niue',
      fr: 'Niue',
      he: 'ניואה',
      it: 'Niue',
      jp: 'ニウエ',
      nl: 'Niue',
      pl: 'Niue',
      pt: 'Niue',
      ro: 'Niue',
      ru: 'Ниуэ',
      ua: 'Ніуе',
      zh: '紐埃',
    },
    phoneMasks: ['####'],
  },
  {
    callingCode: '+64',
    cca2: 'NZ',
    flag: '🇳🇿',
    name: {
      bg: 'Нова Зеландия',
      by: 'Новая Зеландыя',
      cn: '新西兰',
      cz: 'Nový Zéland',
      de: 'Neuseeland',
      ee: 'Uus-Meremaa',
      el: 'Νέα Ζηλανδία',
      en: 'New Zealand',
      es: 'Nueva Zelanda',
      fr: 'Nouvelle-Zélande',
      he: 'ניו זילנד',
      it: 'Nuova Zelanda',
      jp: 'ニュージーランド',
      nl: 'Nieuw-Zeeland',
      pl: 'Nowa Zelandia',
      pt: 'Nova Zelândia',
      ro: 'Noua Zeelanda',
      ru: 'Новая Зеландия',
      ua: 'Нова Зеландія',
      zh: '新西蘭',
    },
    phoneMasks: ['## ### ###', '### ### ###', '### ### ####'],
  },
  {
    callingCode: '+968',
    cca2: 'OM',
    flag: '🇴🇲',
    name: {
      bg: 'Оман',
      by: 'Аман',
      cn: '阿曼',
      cz: 'Omán',
      de: 'Oman',
      ee: 'Omaan',
      el: 'Ομάν',
      en: 'Oman',
      es: 'Omán',
      fr: 'Oman',
      he: 'עומאן',
      it: 'Oman',
      jp: 'オマーン',
      nl: 'Oman',
      pl: 'Oman',
      pt: 'Omã',
      ro: 'Oman',
      ru: 'Оман',
      ua: 'Оман',
      zh: '阿曼',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+507',
    cca2: 'PA',
    flag: '🇵🇦',
    name: {
      bg: 'Панама',
      by: 'Панама',
      cn: '巴拿马',
      cz: 'Panama',
      de: 'Panama',
      ee: 'Panama',
      el: 'Παναμάς',
      en: 'Panama',
      es: 'Panamá',
      fr: 'Panama',
      he: 'פנמה',
      it: 'Panama',
      jp: 'パナマ',
      nl: 'Panama',
      pl: 'Panama',
      pt: 'Panamá',
      ro: 'Panama',
      ru: 'Панама',
      ua: 'Панама',
      zh: '巴拿馬',
    },
    phoneMasks: ['### ####'],
  },
  {
    callingCode: '+51',
    cca2: 'PE',
    flag: '🇵🇪',
    name: {
      bg: 'Перу',
      by: 'Перу',
      cn: '秘鲁',
      cz: 'Peru',
      de: 'Peru',
      ee: 'Peruu',
      el: 'Περού',
      en: 'Peru',
      es: 'Perú',
      fr: 'Pérou',
      he: 'פרו',
      it: 'Perù',
      jp: 'ペルー',
      nl: 'Peru',
      pl: 'Peru',
      pt: 'Peru',
      ro: 'Peru',
      ru: 'Перу',
      ua: 'Перу',
      zh: '秘魯',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+689',
    cca2: 'PF',
    flag: '🇵🇫',
    name: {
      bg: 'Френска полинезия',
      by: 'Французская Палінезія',
      cn: '法属波利尼西亚',
      cz: 'Francouzská Polynésie',
      de: 'Französisch Polynesien',
      ee: 'Prantsuse Polüneesia',
      el: 'Γαλλική Πολυνησία',
      en: 'French Polynesia',
      es: 'Polinesia Francesa',
      fr: 'Polynésie française',
      he: 'פולינזיה הצרפתית',
      it: 'Polinesia francese',
      jp: 'フランス領ポリネシア',
      nl: 'Frans-Polynesië',
      pl: 'Polinezja Francuska',
      pt: 'Polinésia Francesa',
      ro: 'Polinezia Franceză',
      ru: 'Французская Полинезия',
      ua: 'Французька Полінезія',
      zh: '法屬波利尼西 亞',
    },
    phoneMasks: ['## ## ## ##'],
  },
  {
    callingCode: '+675',
    cca2: 'PG',
    flag: '🇵🇬',
    name: {
      bg: 'Папуа-Нова Гвинея',
      by: 'Папуа-Новая Гвінея',
      cn: '巴布亚新几内亚',
      cz: 'Papua-Nová Guinea',
      de: 'Papua Neu-Guinea',
      ee: 'Paapua Uus-Guinea',
      el: 'Παπούα Νέα Γουινέα',
      en: 'Papua New Guinea',
      es: 'Papúa Nueva Guinea',
      fr: 'Papouasie Nouvelle Guinée',
      he: 'פפואה גינאה החדשה',
      it: 'Papua Nuova Guinea',
      jp: 'パ プアニューギニア',
      nl: 'Papoea-Nieuw-Guinea',
      pl: 'Papua Nowa Gwinea',
      pt: 'Papua-Nova Guiné',
      ro: 'Papua Noua Guinee',
      ru: 'Папуа - Новая Гвинея',
      ua: 'Папуа-Нова Гвінея',
      zh: '巴布亞新幾內亞',
    },
    phoneMasks: ['### ## ###'],
  },
  {
    callingCode: '+63',
    cca2: 'PH',
    flag: '🇵🇭',
    name: {
      bg: 'Филипините',
      by: 'Філіпіны',
      cn: '菲律宾',
      cz: 'Filipíny',
      de: 'Philippinen',
      ee: 'Filipiinid',
      el: 'Φιλιππίνες',
      en: 'Philippines',
      es: 'Filipinas',
      fr: 'Philippines',
      he: 'פיליפינים',
      it: 'Filippine',
      jp: 'フィリピン',
      nl: 'Filippijnen',
      pl: 'Filipiny',
      pt: 'Filipinas',
      ro: 'Filipine',
      ru: 'Филиппины',
      ua: 'Філіппіни',
      zh: '菲律賓',
    },
    phoneMasks: ['### ### ####'],
  },
  {
    callingCode: '+92',
    cca2: 'PK',
    flag: '🇵🇰',
    name: {
      bg: 'Пакистан',
      by: 'Пакістан',
      cn: '巴基斯坦',
      cz: 'Pákistán',
      de: 'Pakistan',
      ee: 'Pakistan',
      el: 'Πακιστάν',
      en: 'Pakistan',
      es: 'Pakistán',
      fr: 'Pakistan',
      he: 'פקיסטן',
      it: 'Pakistan',
      jp: 'パキスタン',
      nl: 'Pakistan',
      pl: 'Pakistan',
      pt: 'Paquistão',
      ro: 'Pakistan',
      ru: 'Пакистан',
      ua: 'Пакистан',
      zh: '巴基斯坦',
    },
    phoneMasks: ['### ### ####'],
  },
  {
    callingCode: '+48',
    cca2: 'PL',
    flag: '🇵🇱',
    name: {
      bg: 'Полша',
      by: 'Польшча',
      cn: '波兰',
      cz: 'Polsko',
      de: 'Polen',
      ee: 'Poola',
      el: 'Πολωνία',
      en: 'Poland',
      es: 'Polonia',
      fr: 'Pologne',
      he: 'פולין',
      it: 'Polonia',
      jp: 'ポーランド',
      nl: 'Polen',
      pl: 'Polska',
      pt: 'Polónia',
      ro: 'Polonia',
      ru: 'Польша',
      ua: 'Польща',
      zh: '波蘭',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+508',
    cca2: 'PM',
    flag: '🇵🇲',
    name: {
      bg: 'Сен Пиер и Микелон',
      by: "Сен-П'ер і Мікелон",
      cn: '圣皮埃尔和密克隆群岛',
      cz: 'Svatý Pierre a Miquelon',
      de: 'Saint Pierre und Miquelon',
      ee: 'Saint Pierre ja Miquelon',
      el: 'Σεντ Πιέρ και Μικελόν',
      en: 'Saint Pierre and Miquelon',
      es: 'San Pedro y Miquelón',
      fr: 'Saint-Pierre-et-Miquelon',
      he: 'סנט פייר ומיקלון',
      it: 'Saint Pierre e Miquelon',
      jp: 'サンピエール島とミクロン島',
      nl: 'Saint Pierre en Miquelon',
      pl: 'Saint-Pierre i Miquelon',
      pt: 'São Pedro e Miquelon',
      ro: 'Saint Pierre și Miquelon',
      ru: 'Сен-Пьер и Микелон',
      ua: "Сен-П'єр і Мікелон",
      zh: '聖皮埃爾和密克隆群島',
    },
    phoneMasks: ['## ## ##', '708 ## ## ##'],
  },
  {
    callingCode: '+872',
    cca2: 'PN',
    flag: '🇵🇳',
    name: {
      bg: 'Питкерн',
      by: 'Піткэрн',
      cn: '皮特凯恩',
      cz: 'Pitcairn',
      de: 'Pitcairn',
      ee: 'Pitcairn',
      el: 'Πίτκαιρν',
      en: 'Pitcairn',
      es: 'Pitcairn',
      fr: 'Pitcairn',
      he: 'פיטקרן',
      it: 'Pitcairn',
      jp: 'ピトケ アン',
      nl: 'Pitcairn',
      pl: 'Pitcairn',
      pt: 'Pitcairn',
      ro: 'Pitcairn',
      ru: 'Питкэрн',
      ua: 'Піткерн',
      zh: '皮特凱恩',
    },
    phoneMasks: ['#########'],
  },
  {
    callingCode: '+1',
    cca2: 'PR',
    flag: '🇵🇷',
    name: {
      bg: 'Пуерто Рико',
      by: 'Пуэрта-Рыка',
      cn: '波多黎各',
      cz: 'Portoriko',
      de: 'Puerto Rico',
      ee: 'Puerto Rico',
      el: 'Πουέρτο Ρίκο',
      en: 'Puerto Rico',
      es: 'Puerto Rico',
      fr: 'Porto Rico',
      he: 'פוארטו ריקו',
      it: 'Porto Rico',
      jp: 'プエルトリコ',
      nl: 'Puerto Rico',
      pl: 'Portoryko',
      pt: 'Porto Rico',
      ro: 'Puerto Rico',
      ru: 'Пуэрто-Рико',
      ua: 'Пуерто Ріко',
      zh: '波多黎各',
    },
    phoneMasks: ['787 ### ####', '939 ### ####'],
  },
  {
    callingCode: '+970',
    cca2: 'PS',
    flag: '🇵🇸',
    name: {
      bg: 'Палестинска територия, окупирана',
      by: 'Палестынская тэрыторыя, акупаваная',
      cn: '被占领的 巴勒斯坦领土',
      cz: 'Palestinské území, obsazené',
      de: 'Besetzte palästinensische Gebiete',
      ee: 'Palestiina territoorium, okupeeritud',
      el: 'Κράτος της Παλαιστίνης',
      en: 'Palestinian Territory, Occupied',
      es: 'Territorio Palestino Ocupado',
      fr: 'Territoire palestinien, occupé',
      he: 'הרשות הפלסטינית',
      it: 'Territori palestinesi occupati',
      jp: 'パレスチナ自治区、占領下',
      nl: 'Palestijns gebied, bezet',
      pl: 'Teretorium Paleństynskie, Okupowane',
      pt: 'Território Palestino, Ocupado',
      ro: 'Teritoriul Palestinian, Ocupat',
      ru: 'Палестинская Территория, Оккупированная',
      ua: 'Палестинська територія, окупована',
      zh: '被占領的巴勒斯坦領土',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+351',
    cca2: 'PT',
    flag: '🇵🇹',
    name: {
      bg: 'Португалия',
      by: 'Партугалія',
      cn: '葡萄牙',
      cz: 'Portugalsko',
      de: 'Portugal',
      ee: 'Portugal',
      el: 'Πορτογαλία',
      en: 'Portugal',
      es: 'Portugal',
      fr: 'Le Portugal',
      he: 'פורטוגל',
      it: 'Portogallo',
      jp: 'ポルトガ ル',
      nl: 'Portugal',
      pl: 'Portugalia',
      pt: 'Portugal',
      ro: 'Portugalia',
      ru: 'Португалия',
      ua: 'Португалія',
      zh: '葡萄牙',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+680',
    cca2: 'PW',
    flag: '🇵🇼',
    name: {
      bg: 'Палау',
      by: 'Палаў',
      cn: 'u琉',
      cz: 'Palau',
      de: 'Palau',
      ee: 'Palau',
      el: 'Παλάου',
      en: 'Palau',
      es: 'Palau',
      fr: 'Palau',
      he: 'פלאו',
      it: 'Palau',
      jp: 'パラオ',
      nl: 'Palau',
      pl: 'Palau',
      pt: 'Palau',
      ro: 'Palau',
      ru: 'Палау',
      ua: 'Палау',
      zh: 'u琉',
    },
    phoneMasks: ['### ####'],
  },
  {
    callingCode: '+595',
    cca2: 'PY',
    flag: '🇵🇾',
    name: {
      bg: 'Парагвай',
      by: 'Парагвай',
      cn: '巴拉圭',
      cz: 'Paraguay',
      de: 'Paraguay',
      ee: 'Paraguay',
      el: 'Παραγουάη',
      en: 'Paraguay',
      es: 'Paraguay',
      fr: 'Paraguay',
      he: 'פרגוואי',
      it: 'Paraguay',
      jp: 'パラグアイ',
      nl: 'Paraguay',
      pl: 'Paragwaj',
      pt: 'Paraguai',
      ro: 'Paraguay',
      ru: 'Парагвай',
      ua: 'Парагвай',
      zh: '巴拉圭',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+974',
    cca2: 'QA',
    flag: '🇶🇦',
    name: {
      bg: 'Катар',
      by: 'Катар',
      cn: '卡塔尔',
      cz: 'Katar',
      de: 'Katar',
      ee: 'Katar',
      el: 'Κατάρ',
      en: 'Qatar',
      es: 'Qatar',
      fr: 'Qatar',
      he: 'קטאר',
      it: 'Qatar',
      jp: 'カタール',
      nl: 'Qatar',
      pl: 'Katar',
      pt: 'Catar',
      ro: 'Qatar',
      ru: 'Катар',
      ua: 'Катар',
      zh: '卡塔爾',
    },
    phoneMasks: ['#### ####'],
  },
  {
    callingCode: '+262',
    cca2: 'RE',
    flag: '🇷🇪',
    name: {
      bg: 'Съединение',
      by: "Уз'яднанне",
      cn: '团圆',
      cz: 'Réunion',
      de: 'Wiedervereinigung',
      ee: 'Taaskohtumine',
      el: 'Ρεουνιόν',
      en: 'Réunion',
      es: 'Reunión',
      fr: 'Réunion',
      he: 'איחוד',
      it: 'Riunione',
      jp: '再会',
      nl: 'Bijeenkomst',
      pl: 'Réunion',
      pt: 'Reunião',
      ro: 'Reuniune',
      ru: 'Реюньон',
      ua: 'Реюньйон',
      zh: '團圓',
    },
    phoneMasks: ['6## ## ## ##'],
  },
  {
    callingCode: '+40',
    cca2: 'RO',
    flag: '🇷🇴',
    name: {
      bg: 'Румъния',
      by: 'Румынія',
      cn: '罗马尼亚',
      cz: 'Rumunsko',
      de: 'Rumänien',
      ee: 'Rumeenia',
      el: 'Ρουμανία',
      en: 'Romania',
      es: 'Rumanía',
      fr: 'Roumanie',
      he: 'רומניה',
      it: 'Romania',
      jp: 'ルーマニア',
      nl: 'Roemenië',
      pl: 'Rumunia',
      pt: 'Roménia',
      ro: 'România',
      ru: 'Румыния',
      ua: 'Румунія',
      zh: '羅馬尼亞',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+381',
    cca2: 'RS',
    flag: '🇷🇸',
    name: {
      bg: 'Сърбия',
      by: 'Сербія',
      cn: '塞尔维亚',
      cz: 'Srbsko',
      de: 'Serbien',
      ee: 'Serbia',
      el: 'Σερβία',
      en: 'Serbia',
      es: 'Serbia',
      fr: 'Serbie',
      he: 'סרביה',
      it: 'Serbia',
      jp: 'セルビア',
      nl: 'Servië',
      pl: 'Serbia',
      pt: 'Sérvia',
      ro: 'Serbia',
      ru: 'Сербия',
      ua: 'Сербія',
      zh: '塞爾維亞',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+7',
    cca2: 'RU',
    flag: '🇷🇺',
    name: {
      bg: 'Руска федерация',
      by: 'Расійская Федэрацыя',
      cn: '俄罗斯联邦',
      cz: 'Rusko',
      de: 'Russische Föderation',
      ee: 'Venemaa Föderatsioon',
      el: 'Ρωσία',
      en: 'Russia',
      es: 'Rusia',
      fr: 'Fédération Russe',
      he: 'רוסיה',
      it: 'Federazione Russa',
      jp: 'ロシア連邦',
      nl: 'Russische Federatie',
      pl: 'Rosja',
      pt: 'Rússia',
      ro: 'Federația Rusă',
      ru: 'Россия',
      ua: 'Росія',
      zh: '俄羅 斯聯邦',
    },
    phoneMasks: ['### ### ## ##'],
  },
  {
    callingCode: '+250',
    cca2: 'RW',
    flag: '🇷🇼',
    name: {
      bg: 'Руанда',
      by: 'Руанда',
      cn: '卢旺达',
      cz: 'Rwanda',
      de: 'Ruanda',
      ee: 'Rwanda',
      el: 'Ρουάντα',
      en: 'Rwanda',
      es: 'Ruanda',
      fr: 'Rwanda',
      he: 'רואנדה',
      it: 'Ruanda',
      jp: 'ルワンダ',
      nl: 'Rwanda',
      pl: 'Rwanda',
      pt: 'Ruanda',
      ro: 'Rwanda',
      ru: 'Руанда',
      ua: 'Руанда',
      zh: '盧旺達',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+966',
    cca2: 'SA',
    flag: '🇸🇦',
    name: {
      bg: 'Саудитска Арабия',
      by: 'Саўдаўская Аравія',
      cn: '沙特阿拉伯',
      cz: 'Saudská arábie',
      de: 'Saudi-Arabien',
      ee: 'Saudi Araabia',
      el: 'Σαουδική Αραβία',
      en: 'Saudi Arabia',
      es: 'San Vicente y las Granadinas',
      fr: 'Arabie Saoudite',
      he: 'ערב הסעודית',
      it: 'Arabia Saudita',
      jp: 'サウジア ラビア',
      nl: 'Saoedi-Arabië',
      pl: 'Arabia Saudyjska',
      pt: 'Arábia Saudita',
      ro: 'Arabia Saudită',
      ru: 'Саудовская Аравия',
      ua: 'Саудівська Аравія',
      zh: '沙特阿拉伯',
    },
    phoneMasks: ['# ### ####', '# #### ####'],
  },
  {
    callingCode: '+677',
    cca2: 'SB',
    flag: '🇸🇧',
    name: {
      bg: 'Соломонови острови',
      by: 'Саламонавы астравы',
      cn: '所罗门群岛',
      cz: 'Solomonovy ostrovy',
      de: 'Salomon-Inseln',
      ee: 'Saalomoni Saared',
      el: 'Νησιά Σολομώντα',
      en: 'Solomon Islands',
      es: 'Islas Salomón',
      fr: 'Les îles Salomon',
      he: 'איי שלמה',
      it: 'Isole Salomone',
      jp: 'ソロモン諸島',
      nl: 'Solomon eilanden',
      pl: 'Wyspy Salomona',
      pt: 'Ilhas Salomão',
      ro: 'Insulele Solomon',
      ru: 'Соломоновы острова',
      ua: 'Соломонові острови',
      zh: '所羅門群島',
    },
    phoneMasks: ['#####', '### ####'],
  },
  {
    callingCode: '+248',
    cca2: 'SC',
    flag: '🇸🇨',
    name: {
      bg: 'Сейшелски острови',
      by: 'Сейшэльскія астравы',
      cn: '塞舌尔',
      cz: 'Seychely',
      de: 'Seychellen',
      ee: 'Seišellid',
      el: 'Σεϋχέλλες',
      en: 'Seychelles',
      es: 'Seychelles',
      fr: 'les Seychelles',
      he: 'איי סיישל',
      it: 'Seychelles',
      jp: 'セイシェル',
      nl: 'Seychellen',
      pl: 'Seszele',
      pt: 'Seicheles',
      ro: 'Seychelles',
      ru: 'Сейшельские острова',
      ua: 'Сейшельські острови',
      zh: '塞舌爾',
    },
    phoneMasks: ['# ### ###'],
  },
  {
    callingCode: '+249',
    cca2: 'SD',
    flag: '🇸🇩',
    name: {
      bg: 'Судан',
      by: 'Судан',
      cn: '苏丹',
      cz: 'Súdán',
      de: 'Sudan',
      ee: 'Sudaan',
      el: 'Σουδάν',
      en: 'Sudan',
      es: 'Sudán',
      fr: 'Soudan',
      he: 'סודן',
      it: 'Sudan',
      jp: 'スーダ ン',
      nl: 'Soedan',
      pl: 'Sudan',
      pt: 'Sudão',
      ro: 'Sudan',
      ru: 'Судан',
      ua: 'Судан',
      zh: '蘇丹',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+46',
    cca2: 'SE',
    flag: '🇸🇪',
    name: {
      bg: 'Швеция',
      by: 'Швецыя',
      cn: '瑞典',
      cz: 'Švédsko',
      de: 'Schweden',
      ee: 'Rootsi',
      el: 'Σουηδία',
      en: 'Sweden',
      es: 'Suecia',
      fr: 'Suède',
      he: 'שבדיה',
      it: 'Svezia',
      jp: 'スウェーデン',
      nl: 'Zweden',
      pl: 'Szwecja',
      pt: 'Suécia',
      ro: 'Suedia',
      ru: 'Швеция',
      ua: 'Швеція',
      zh: '瑞典',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+65',
    cca2: 'SG',
    flag: '🇸🇬',
    name: {
      bg: 'Сингапур',
      by: 'Сінгапур',
      cn: '新加坡',
      cz: 'Singapur',
      de: 'Singapur',
      ee: 'Singapur',
      el: 'Σιγκαπούρη',
      en: 'Singapore',
      es: 'Singapur',
      fr: 'Singapour',
      he: 'סינגפור',
      it: 'Singapore',
      jp: 'シンガポール',
      nl: 'Singapore',
      pl: 'Singapur',
      pt: 'Singapura',
      ro: 'Singapore',
      ru: 'Сингапур',
      ua: 'Сінгапур',
      zh: '新加坡',
    },
    phoneMasks: ['#### ####'],
  },
  {
    callingCode: '+290',
    cca2: 'SH',
    flag: '🇸🇭',
    name: {
      bg: 'Света Елена',
      by: 'Святая Алена',
      cn: '圣海伦娜',
      cz: 'Svatá Helena, Nanebevstoupení a Tristan Da Cunha',
      de: 'Heilige Helena',
      ee: 'Püha Helena',
      el: 'Αγία Ελένη',
      en: 'Saint Helena, Ascension and Tristan Da Cunha',
      es: 'Santa Elena, Ascensión y Tristán Da Cunha',
      fr: 'Sainte-Hélène',
      he: 'סנט הלנה',
      it: "Sant'Elena",
      jp: 'セントヘレナ',
      nl: 'Sint-Helena',
      pl: 'Święta Helena, Wniebowstąpienie i Tristan Da Cunha',
      pt: 'Santa Helena',
      ro: 'Sfânta Elena',
      ru: 'Святой Елены, Вознесения и Тристан-да-Кунья',
      ua: 'Свята Олена, Вознесіння і Трістан Да Кунья',
      zh: '聖海倫娜',
    },
    phoneMasks: ['#####'],
  },
  {
    callingCode: '+386',
    cca2: 'SI',
    flag: '🇸🇮',
    name: {
      bg: 'Словения',
      by: 'Славенія',
      cn: '斯洛文尼亚',
      cz: 'Slovinsko',
      de: 'Slowenien',
      ee: 'Sloveenia',
      el: 'Σλοβενία',
      en: 'Slovenia',
      es: 'Eslovenia',
      fr: 'Slovénie',
      he: 'סלובניה',
      it: 'Slovenia',
      jp: 'スロベニア',
      nl: 'Slovenië',
      pl: 'Słowenia',
      pt: 'Eslovénia',
      ro: 'Slovenia',
      ru: 'Словения',
      ua: 'Словенія',
      zh: '斯洛文 尼亞',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+47',
    cca2: 'SJ',
    flag: '🇸🇯',
    name: {
      bg: 'Шпицберген и Ян Майен',
      by: 'Шпіцберген і Ян Маен',
      cn: '斯瓦尔巴和扬·马延',
      cz: 'Špicberky a Jan Mayen',
      de: 'Spitzbergen und Jan Mayen',
      ee: 'Svalbard ja Jan Mayen',
      el: 'Σβάλμπαρντ και Γιαν Μάγεν',
      en: 'Svalbard and Jan Mayen',
      es: 'Svalbard y Jan Mayen',
      fr: 'Svalbard et Jan Mayen',
      he: 'סבאלברד ויאן מאין',
      it: 'Svalbard e Jan Mayen',
      jp: 'スバールバル諸島およびヤンマイエン',
      nl: 'Svalbard en Jan Mayen',
      pl: 'Svalbard i Jan Mayen',
      pt: 'Svalbard e Jan Mayen',
      ro: 'Svalbard și Jan Mayen',
      ru: 'Шпицберген и Ян Майен',
      ua: 'Шпіцберген та Ян Маєн',
      zh: '斯瓦爾巴和揚· 馬延',
    },
    phoneMasks: ['79 ## ## ##'],
  },
  {
    callingCode: '+421',
    cca2: 'SK',
    flag: '🇸🇰',
    name: {
      bg: 'Словакия',
      by: 'Славакія',
      cn: '斯洛伐克',
      cz: 'Slovensko',
      de: 'Slowakei',
      ee: 'Slovakkia',
      el: 'Σλοβακία',
      en: 'Slovakia',
      es: 'Eslovaquia',
      fr: 'Slovaquie',
      he: 'סלובקיה',
      it: 'Slovacchia',
      jp: 'スロバキア',
      nl: 'Slowakije',
      pl: 'Słowacja',
      pt: 'Eslováquia',
      ro: 'Slovacia',
      ru: 'Словакия',
      ua: 'Словаччина',
      zh: '斯洛伐克',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+232',
    cca2: 'SL',
    flag: '🇸🇱',
    name: {
      bg: 'Сиера Леоне',
      by: 'Сьера-Леонэ',
      cn: '塞拉利昂',
      cz: 'Sierra Leone',
      de: 'Sierra Leone',
      ee: 'Sierra Leone',
      el: 'Σιέρα Λεόνε',
      en: 'Sierra Leone',
      es: 'Sierra Leona',
      fr: 'Sierra Leone',
      he: 'סיירה לאון',
      it: 'Sierra Leone',
      jp: 'シエラレオネ',
      nl: 'Sierra Leone',
      pl: 'Sierra Leone',
      pt: 'Serra Leoa',
      ro: 'Sierra Leone',
      ru: 'Сьерра-Леоне',
      ua: 'Сьєрра-Леоне',
      zh: '塞拉利昂',
    },
    phoneMasks: ['## ######'],
  },
  {
    callingCode: '+378',
    cca2: 'SM',
    flag: '🇸🇲',
    name: {
      bg: 'Сан Марино',
      by: 'Сан-Марына',
      cn: '圣马力诺',
      cz: 'San Marino',
      de: 'San Marino',
      ee: 'San Marino',
      el: 'Άγιος Μαρίνος',
      en: 'San Marino',
      es: 'San Marino',
      fr: 'Saint Marin',
      he: 'סן מרינו',
      it: 'San Marino',
      jp: 'サンマリノ',
      nl: 'San Marino',
      pl: 'San Marino',
      pt: 'São Marinho',
      ro: 'San Marino',
      ru: 'Сан-Марино',
      ua: 'Сан-Марино',
      zh: '聖馬力諾',
    },
    phoneMasks: ['#### ######'],
  },
  {
    callingCode: '+221',
    cca2: 'SN',
    flag: '🇸🇳',
    name: {
      bg: 'Сенегал',
      by: 'Сенегал',
      cn: '塞内加尔',
      cz: 'Senegal',
      de: 'Senegal',
      ee: 'Senegal',
      el: 'Σενεγάλη',
      en: 'Senegal',
      es: 'Senegal',
      fr: 'Sénégal',
      he: 'סנגל',
      it: 'Senegal',
      jp: 'セネガル',
      nl: 'Senegal',
      pl: 'Senegal',
      pt: 'Senegal',
      ro: 'Senegal',
      ru: 'Сенегал',
      ua: 'Сенегал',
      zh: '塞內加爾',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+252',
    cca2: 'SO',
    flag: '🇸🇴',
    name: {
      bg: 'Сомалия',
      by: 'Самалі',
      cn: '索马里',
      cz: 'Somálsko',
      de: 'Somalia',
      ee: 'Somaalia',
      el: 'Σομαλία',
      en: 'Somalia',
      es: 'Somalia',
      fr: 'Somalie',
      he: 'סומליה',
      it: 'Somalia',
      jp: 'ソマリア',
      nl: 'Somalië',
      pl: 'Somali',
      pt: 'Somália',
      ro: 'Somalia',
      ru: 'Сомали',
      ua: 'Сомалі',
      zh: '索馬里',
    },
    phoneMasks: ['## ### ###', '# ### ###'],
  },
  {
    callingCode: '+597',
    cca2: 'SR',
    flag: '🇸🇷',
    name: {
      bg: 'Суринам',
      by: 'Сурынам',
      cn: '苏里南',
      cz: 'Surinam',
      de: 'Suriname',
      ee: 'Suriname',
      el: 'Σουρινάμ',
      en: 'Suriname',
      es: 'Surinam',
      fr: 'Suriname',
      he: 'סורינאם',
      it: 'Suriname',
      jp: 'スリナム',
      nl: 'Suriname',
      pl: 'Surinam',
      pt: 'Suriname',
      ro: 'Surinam',
      ru: 'Суринам',
      ua: 'Суринам',
      zh: '蘇裡南',
    },
    phoneMasks: ['### ###', '### ####'],
  },
  {
    callingCode: '+239',
    cca2: 'ST',
    flag: '🇸🇹',
    name: {
      bg: 'Сао Томе и Принсипи',
      by: 'Сан-Томе і Прынсіпі',
      cn: '圣多美和普林西比',
      cz: 'Svatý Tomáš a Princův ostrov',
      de: 'Sao Tome und Principe',
      ee: 'Sao Tome ja Principe',
      el: 'Σάο Τομέ και Πρίνσιπε',
      en: 'Sao Tome and Principe',
      es: 'Santo Tomé y Príncipe',
      fr: 'Sao Tomé et Principe',
      he: 'סאו טומה ופרינסיפה',
      it: 'Sao Tome e Principe',
      jp: 'サントメ・プリンシペ',
      nl: 'Sao Tomé en Principe',
      pl: 'Wyspy Świętego Tomasza i Książęca',
      pt: 'São Tomé e Príncipe',
      ro: 'Sao Tome și Principe',
      ru: 'Сан-Томе и Принсипи',
      ua: 'Сан-Томе і Принсіпі',
      zh: '聖多美和普 林西比',
    },
    phoneMasks: ['## #####'],
  },
  {
    callingCode: '+503',
    cca2: 'SV',
    flag: '🇸🇻',
    name: {
      bg: 'Ел Салвадор',
      by: 'Сальвадор',
      cn: '萨尔瓦多',
      cz: 'El Salvador',
      de: 'El Salvador',
      ee: 'El Salvador',
      el: 'Ελ Σαλβαδόρ',
      en: 'El Salvador',
      es: 'El Salvador',
      fr: 'Le Salvador',
      he: 'אל סלבדור',
      it: 'El Salvador',
      jp: 'エルサルバドル',
      nl: 'El Salvador',
      pl: 'Salwador',
      pt: 'Salvador',
      ro: 'El Salvador',
      ru: 'Сальвадор',
      ua: 'Сальвадор',
      zh: '薩爾瓦多',
    },
    phoneMasks: ['## ## ####'],
  },
  {
    callingCode: '+963',
    cca2: 'SY',
    flag: '🇸🇾',
    name: {
      bg: 'Сирийска Арабска Република',
      by: 'Сірыйская Арабская Рэспубліка',
      cn: '阿拉伯叙利亚共和国',
      cz: 'Syrská Arabská republika',
      de: 'Syrische Arabische Republik',
      ee: 'Süüria Araabia Vabariik',
      el: 'Συρία',
      en: 'Syrian Arab Republic',
      es: 'República Árabe Siria',
      fr: 'République arabe syrienne',
      he: 'סוריה',
      it: 'Repubblica Araba Siriana',
      jp: 'シリアアラブ共和国',
      nl: 'Syrische Arabische Republiek',
      pl: 'Republika Syryjsko-Arabska',
      pt: 'Síria',
      ro: 'Republica Araba Siriana',
      ru: 'Сирийская Арабская Республика',
      ua: 'Сирійська Арабська Республіка',
      zh: '阿拉伯敘利亞共和國',
    },
    phoneMasks: ['## #### ###'],
  },
  {
    callingCode: '+268',
    cca2: 'SZ',
    flag: '🇸🇿',
    name: {
      bg: 'Свазиленд',
      by: 'Свазіленд',
      cn: '斯威士兰',
      cz: 'Svazijsko',
      de: 'Swasiland',
      ee: 'Svaasimaa',
      el: 'Σουαζιλάνδη',
      en: 'Swaziland',
      es: 'Suazilandia',
      fr: 'Swaziland',
      he: 'סווזילנד',
      it: 'Swaziland',
      jp: 'スワジランド',
      nl: 'Swaziland',
      pl: 'Suazi',
      pt: 'Suazilândia',
      ro: 'Swaziland',
      ru: 'Свазиленд',
      ua: 'Свазіленд',
      zh: '斯威士蘭',
    },
    phoneMasks: ['## ## ####'],
  },
  {
    callingCode: '+1',
    cca2: 'TC',
    flag: '🇹🇨',
    name: {
      bg: 'Острови Търкс и Кайкос',
      by: 'Астравы Тэркс і Кайкас',
      cn: '特克斯和凯科斯群岛',
      cz: 'ostrovy Turks a Cacois',
      de: 'Turks- und Caicosinseln',
      ee: 'Turksi ja Caicose saared',
      el: 'Νήσοι Τουρκ και Κάικος',
      en: 'Turks and Caicos Islands',
      es: 'Islas Turcas y Caicos',
      fr: 'îles Turques-et-Caïques',
      he: 'איי טורקס וקאיקוס',
      it: 'Isole Turks e Caicos',
      jp: 'タークス・カイコス諸島',
      nl: 'Turks- en Caicoseilanden',
      pl: 'Wyspy Turks i Caicos',
      pt: 'Ilhas Turcas e Caicos',
      ro: 'Insulele Turks și Caicos',
      ru: 'Острова Теркс и Кайкос',
      ua: 'Острови Теркс і Кайкос',
      zh: '特克斯和凱科斯群島',
    },
    phoneMasks: ['649 ### ####'],
  },
  {
    callingCode: '+235',
    cca2: 'TD',
    flag: '🇹🇩',
    name: {
      bg: 'Чад',
      by: 'Чад',
      cn: '乍得',
      cz: 'Čad',
      de: 'Tschad',
      ee: 'Tšaad',
      el: 'Τσαντ',
      en: 'Chad',
      es: 'Chad',
      fr: 'Tchad',
      he: "צ'אד",
      it: 'Chad',
      jp: 'チャド',
      nl: 'Tsjaad',
      pl: 'Czad',
      pt: 'Chade',
      ro: 'Ciad',
      ru: 'Чад',
      ua: 'Чад',
      zh: '乍得',
    },
    phoneMasks: ['## ## ## ##'],
  },
  {
    callingCode: '+228',
    cca2: 'TG',
    flag: '🇹🇬',
    name: {
      bg: 'Да отида',
      by: 'Ісці',
      cn: '多哥',
      cz: 'Togo',
      de: 'Gehen',
      ee: 'Minema',
      el: 'Τόγκο',
      en: 'Togo',
      es: 'Togo',
      fr: 'Aller',
      he: 'טוגו',
      it: 'Andare',
      jp: 'トーゴ',
      nl: 'Gaan',
      pl: 'Togo',
      pt: 'Togo',
      ro: 'A merge',
      ru: 'Того',
      ua: 'Того',
      zh: '多哥',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+66',
    cca2: 'TH',
    flag: '🇹🇭',
    name: {
      bg: 'Тайланд',
      by: 'Тайланд',
      cn: '泰国',
      cz: 'Thajsko',
      de: 'Thailand',
      ee: 'Tai',
      el: 'Ταϊλάνδη',
      en: 'Thailand',
      es: 'Tailandia',
      fr: 'Thaïlande',
      he: 'תאילנד',
      it: 'Tailandia',
      jp: 'タイ',
      nl: 'Thailand',
      pl: 'Tajlandia',
      pt: 'Tailândia',
      ro: 'Tailanda',
      ru: 'Таиланд',
      ua: 'Таїланд',
      zh: '泰國',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+992',
    cca2: 'TJ',
    flag: '🇹🇯',
    name: {
      bg: 'Таджикистан',
      by: 'Таджыкістан',
      cn: '塔吉克斯坦',
      cz: 'Tádžikistán',
      de: 'Tadschikistan',
      ee: 'Tadžikistan',
      el: 'Τατζικιστάν',
      en: 'Tajikistan',
      es: 'Tayikistán',
      fr: 'Tadjikistan',
      he: "טג'יקיסטן",
      it: 'Tagikistan',
      jp: 'タジキスタン',
      nl: 'Tadzjikistan',
      pl: 'Tadżykistan',
      pt: 'Tajiquistão',
      ro: 'Tadjikistan',
      ru: 'Таджикистан',
      ua: 'Таджикистан',
      zh: '塔吉克斯坦',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+690',
    cca2: 'TK',
    flag: '🇹🇰',
    name: {
      bg: 'Токелау',
      by: 'Такелаў',
      cn: '托克劳',
      cz: 'Tokelau',
      de: 'Tokelau',
      ee: 'Tokelau',
      el: 'Τοκελάου',
      en: 'Tokelau',
      es: 'Tokelau',
      fr: 'Tokelau',
      he: 'טוקלאו',
      it: 'Tokelau',
      jp: 'トケラウ',
      nl: 'Tokelau',
      pl: 'Tokelau',
      pt: 'Tokelau',
      ro: 'Tokelau',
      ru: 'Токелау',
      ua: 'Токелау',
      zh: '托克勞',
    },
    phoneMasks: ['####'],
  },
  {
    callingCode: '+670',
    cca2: 'TL',
    flag: '🇹🇱',
    name: {
      bg: 'Тимор-Лешти',
      by: 'Востраў Тымор',
      cn: '东帝汶',
      cz: 'Východní Timor',
      de: 'Timor-Leste',
      ee: 'Ida-Timor',
      el: 'Ανατολικό Τιμόρ',
      en: 'Timor-Leste',
      es: 'Timor-Leste',
      fr: 'Timor-Leste',
      he: 'טימור-לסטה',
      it: 'Timor-Leste',
      jp: '東ティモール',
      nl: 'Oost-Timor',
      pl: 'Timor Wschodni',
      pt: 'Timor Leste',
      ro: 'Timor-Leste',
      ru: 'Восточный Тимор',
      ua: 'Восточний Тимор',
      zh: '東帝汶',
    },
    phoneMasks: ['### ####', '### #####'],
  },
  {
    callingCode: '+993',
    cca2: 'TM',
    flag: '🇹🇲',
    name: {
      bg: 'Туркменистан',
      by: 'Туркменістан',
      cn: '土库曼斯坦',
      cz: 'Turkmenistán',
      de: 'Turkmenistan',
      ee: 'Türkmenistan',
      el: 'Τουρκμενιστάν',
      en: 'Turkmenistan',
      es: 'Turkmenistán',
      fr: 'Turkménistan',
      he: 'טורקמניסטן',
      it: 'Turkmenistan',
      jp: 'トルクメ ニスタン',
      nl: 'Turkmenistan',
      pl: 'Turkmenia',
      pt: 'Turquemenistão',
      ro: 'Turkmenistan',
      ru: 'Туркменистан',
      ua: 'Туркменістан',
      zh: '土庫曼斯坦',
    },
    phoneMasks: ['# ### ####'],
  },
  {
    callingCode: '+216',
    cca2: 'TN',
    flag: '🇹🇳',
    name: {
      bg: 'Тунис',
      by: 'Туніс',
      cn: '突尼斯',
      cz: 'Tunisko',
      de: 'Tunesien',
      ee: 'Tuneesia',
      el: 'Τυνησία',
      en: 'Tunisia',
      es: 'Túnez',
      fr: 'Tunisie',
      he: 'תוניסיה',
      it: 'Tunisia',
      jp: 'チュニジア',
      nl: 'Tunesië',
      pl: 'Tunezja',
      pt: 'Tunísia',
      ro: 'Tunisia',
      ru: 'Тунис',
      ua: 'Туніс',
      zh: '突尼斯',
    },
    phoneMasks: ['## ### ###'],
  },
  {
    callingCode: '+676',
    cca2: 'TO',
    flag: '🇹🇴',
    name: {
      bg: 'Тонга',
      by: 'Тонга',
      cn: '汤加',
      cz: 'Tonga',
      de: 'Tonga',
      ee: 'Tonga',
      el: 'Τόνγκα',
      en: 'Tonga',
      es: 'Tonga',
      fr: 'Tonga',
      he: 'טונגה',
      it: 'Tonga',
      jp: 'トンガ',
      nl: 'Tonga',
      pl: 'Tonga',
      pt: 'Tonga',
      ro: 'Tonga',
      ru: 'Тонга',
      ua: 'Тонга',
      zh: '湯加',
    },
    phoneMasks: ['#####'],
  },
  {
    callingCode: '+90',
    cca2: 'TR',
    flag: '🇹🇷',
    name: {
      bg: 'Турция',
      by: 'Турцыя',
      cn: '火鸡',
      cz: 'Turecko',
      de: 'Türkei',
      ee: 'Türgi',
      el: 'Τουρκία',
      en: 'Turkey',
      es: 'Turquía',
      fr: 'dinde',
      he: 'טורקיה',
      it: 'tacchino',
      jp: '七面鳥',
      nl: 'kalkoen',
      pl: 'Turcja',
      pt: 'Turquia',
      ro: 'Curcan',
      ru: 'Турция',
      ua: 'Туреччина',
      zh: '火雞',
    },
    phoneMasks: ['### ### ####'],
  },
  {
    callingCode: '+1',
    cca2: 'TT',
    flag: '🇹🇹',
    name: {
      bg: 'Тринидад и Тобаго',
      by: 'Трынідад і Табага',
      cn: '特立尼达和多巴哥',
      cz: 'Trinidad a Tobago',
      de: 'Trinidad und Tobago',
      ee: 'Trinidad ja Tobago',
      el: 'Τρίνινταντ και Tομπάγκο',
      en: 'Trinidad and Tobago',
      es: 'Trinidad y Tobago',
      fr: 'Trinité-et-Tobago',
      he: 'טרינידד וטובגו',
      it: 'Trinidad e Tobago',
      jp: 'トリニダード・トバゴ',
      nl: 'Trinidad en Tobago',
      pl: 'Trynidad i Tobago',
      pt: 'Trindade e Tobago',
      ro: 'Trinidad și Tobago',
      ru: 'Тринидад и Тобаго',
      ua: 'Тринідад і Тобаго',
      zh: '特立尼達和多巴 哥',
    },
    phoneMasks: ['868 ### ####'],
  },
  {
    callingCode: '+688',
    cca2: 'TV',
    flag: '🇹🇻',
    name: {
      bg: 'Тувалу',
      by: 'Тувалу',
      cn: '图瓦卢',
      cz: 'Tuvalu',
      de: 'Tuvalu',
      ee: 'Tuvalu',
      el: 'Τουβαλού',
      en: 'Tuvalu',
      es: 'Tuvalu',
      fr: 'Tuvalu',
      he: 'טובאלו',
      it: 'Tuvalu',
      jp: 'ツバル',
      nl: 'Tuvalu',
      pl: 'Tuvalu',
      pt: 'Tuvalu',
      ro: 'Tuvalu',
      ru: 'Тувалу',
      ua: 'Тувалу',
      zh: '圖瓦盧',
    },
    phoneMasks: ['#####', '######'],
  },
  {
    callingCode: '+886',
    cca2: 'TW',
    flag: '🇹🇼',
    name: {
      bg: 'Тайван, провинция Китай',
      by: 'Тайвань, Кітайская правінцыя',
      cn: '中国台湾省',
      cz: 'Tchaj-wan, provincie Číny',
      de: 'Taiwan, Provinz Chinas',
      ee: 'Taiwan, Hiina provints',
      el: 'Ταϊβάν',
      en: 'Taiwan, Province of China',
      es: 'Taiwán, provincia de China',
      fr: 'Taiwan, Province de Chine',
      he: 'טיוואן',
      it: 'Taiwan, provincia della Cina',
      jp: '台湾、中国省',
      nl: 'Taiwan, provincie China',
      pl: 'Tajwan, prowincja Chin',
      pt: 'Taiwan',
      ro: 'Taiwan, provincia Chinei',
      ru: 'Тайвань, провинция Китая',
      ua: 'Тайвань, провінція Китаю',
      zh: '中國台灣省',
    },
    phoneMasks: ['### #### ##'],
  },
  {
    callingCode: '+255',
    cca2: 'TZ',
    flag: '🇹🇿',
    name: {
      bg: 'Танзания, Обединена република',
      by: "Танзанія, Аб'яднаная Рэспубліка",
      cn: '坦桑尼亚联合共和国',
      cz: 'Tanzanie, Velká republika',
      de: 'Tansania, Vereinigte Republik',
      ee: 'Tansaania, Tšehhi Vabariik',
      el: 'Τανζανία',
      en: 'Tanzania, United Republic of',
      es: 'Tanzania, República Unida de',
      fr: 'Tanzanie, République-Unie de',
      he: 'טנזניה',
      it: 'Tanzania, Repubblica Unita di',
      jp: ' タンザニア、連合共和国',
      nl: 'Tanzania, Verenigde Republiek',
      pl: 'Tanzania, Zjednoczona Republika',
      pt: 'Tanzânia',
      ro: 'Tanzania, Republica Unită a',
      ru: 'Танзания, Объединенная Республика',
      ua: "Танзанія, Об'єднана республіка",
      zh: '坦桑尼亞聯合共和國',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+380',
    cca2: 'UA',
    flag: '🇺🇦',
    name: {
      bg: 'Украйна',
      by: 'Украіна',
      cn: '乌克兰',
      cz: 'Ukrajina',
      de: 'Ukraine',
      ee: 'Ukraina',
      el: 'Ουκρανία',
      en: 'Ukraine',
      es: 'Ucrania',
      fr: 'Ukraine',
      he: 'אוקראינה',
      it: 'Ucraina',
      jp: 'ウクライナ',
      nl: 'Oekraïne',
      pl: 'Ukraina',
      pt: 'Ucrânia',
      ro: 'Ucraina',
      ru: 'Украина',
      ua: 'Україна',
      zh: '烏克蘭',
    },
    phoneMasks: ['## ### ## ##'],
  },
  {
    callingCode: '+256',
    cca2: 'UG',
    flag: '🇺🇬',
    name: {
      bg: 'Уганда',
      by: 'Уганда',
      cn: '乌干达',
      cz: 'Uganda',
      de: 'Uganda',
      ee: 'Uganda',
      el: 'Ουγκάντα',
      en: 'Uganda',
      es: 'Uganda',
      fr: 'Ouganda',
      he: 'אוגנדה',
      it: 'Uganda',
      jp: 'ウガ ンダ',
      nl: 'Oeganda',
      pl: 'Uganda',
      pt: 'Uganda',
      ro: 'Uganda',
      ru: 'Уганда',
      ua: 'Уганда',
      zh: '烏干達',
    },
    phoneMasks: ['### ### ###'],
  },
  {
    callingCode: '+1',
    cca2: 'US',
    flag: '🇺🇸',
    name: {
      bg: 'Съединени щати',
      by: 'Злучаныя Штаты',
      cn: '美国',
      cz: 'Spojené státy',
      de: 'Vereinigte Staaten',
      ee: 'Ühendriigid',
      el: 'Ηνωμένες Πολιτείες Αμερικής',
      en: 'United States',
      es: 'Estados Unidos',
      fr: 'États-Unis',
      he: 'ארצות הברית',
      it: 'stati Uniti',
      jp: 'アメリカ',
      nl: 'Verenigde Staten',
      pl: 'Stany Zjednoczone',
      pt: 'Estados Unidos',
      ro: 'Statele Unite',
      ru: 'Соединенные Штаты',
      ua: 'Сполучені Штати',
      zh: '美國',
    },
    phoneMasks: [
      '201 ### ####',
      '202 ### ####',
      '203 ### ####',
      '205 ### ####',
      '206 ### ####',
      '207 ### ####',
      '208 ### ####',
      '209 ### ####',
      '210 ### ####',
      '212 ### ####',
      '213 ### ####',
      '214 ### ####',
      '215 ### ####',
      '216 ### ####',
      '217 ### ####',
      '218 ### ####',
      '219 ### ####',
      '220 ### ####',
      '223 ### ####',
      '224 ### ####',
      '225 ### ####',
      '228 ### ####',
      '229 ### ####',
      '231 ### ####',
      '234 ### ####',
      '239 ### ####',
      '240 ### ####',
      '248 ### ####',
      '251 ### ####',
      '252 ### ####',
      '253 ### ####',
      '254 ### ####',
      '256 ### ####',
      '260 ### ####',
      '262 ### ####',
      '267 ### ####',
      '269 ### ####',
      '270 ### ####',
      '272 ### ####',
      '276 ### ####',
      '279 ### ####',
      '281 ### ####',
      '301 ### ####',
      '302 ### ####',
      '303 ### ####',
      '304 ### ####',
      '305 ### ####',
      '307 ### ####',
      '308 ### ####',
      '309 ### ####',
      '310 ### ####',
      '312 ### ####',
      '313 ### ####',
      '314 ### ####',
      '315 ### ####',
      '316 ### ####',
      '317 ### ####',
      '318 ### ####',
      '319 ### ####',
      '320 ### ####',
      '321 ### ####',
      '323 ### ####',
      '325 ### ####',
      '326 ### ####',
      '330 ### ####',
      '331 ### ####',
      '332 ### ####',
      '334 ### ####',
      '336 ### ####',
      '337 ### ####',
      '339 ### ####',
      '341 ### ####',
      '346 ### ####',
      '347 ### ####',
      '350 ### ####',
      '351 ### ####',
      '352 ### ####',
      '360 ### ####',
      '361 ### ####',
      '363 ### ####',
      '364 ### ####',
      '380 ### ####',
      '385 ### ####',
      '386 ### ####',
      '401 ### ####',
      '402 ### ####',
      '404 ### ####',
      '405 ### ####',
      '406 ### ####',
      '407 ### ####',
      '408 ### ####',
      '409 ### ####',
      '410 ### ####',
      '412 ### ####',
      '413 ### ####',
      '414 ### ####',
      '415 ### ####',
      '417 ### ####',
      '419 ### ####',
      '423 ### ####',
      '424 ### ####',
      '425 ### ####',
      '430 ### ####',
      '432 ### ####',
      '434 ### ####',
      '435 ### ####',
      '440 ### ####',
      '442 ### ####',
      '443 ### ####',
      '445 ### ####',
      '447 ### ####',
      '448 ### ####',
      '458 ### ####',
      '463 ### ####',
      '464 ### ####',
      '469 ### ####',
      '470 ### ####',
      '472 ### ####',
      '475 ### ####',
      '478 ### ####',
      '479 ### ####',
      '480 ### ####',
      '484 ### ####',
      '501 ### ####',
      '502 ### ####',
      '503 ### ####',
      '504 ### ####',
      '505 ### ####',
      '507 ### ####',
      '508 ### ####',
      '509 ### ####',
      '510 ### ####',
      '512 ### ####',
      '513 ### ####',
      '515 ### ####',
      '516 ### ####',
      '517 ### ####',
      '518 ### ####',
      '520 ### ####',
      '530 ### ####',
      '531 ### ####',
      '534 ### ####',
      '539 ### ####',
      '540 ### ####',
      '541 ### ####',
      '551 ### ####',
      '557 ### ####',
      '559 ### ####',
      '561 ### ####',
      '562 ### ####',
      '563 ### ####',
      '564 ### ####',
      '567 ### ####',
      '570 ### ####',
      '571 ### ####',
      '572 ### ####',
      '573 ### ####',
      '574 ### ####',
      '575 ### ####',
      '580 ### ####',
      '582 ### ####',
      '585 ### ####',
      '586 ### ####',
      '601 ### ####',
      '602 ### ####',
      '603 ### ####',
      '605 ### ####',
      '606 ### ####',
      '607 ### ####',
      '608 ### ####',
      '609 ### ####',
      '610 ### ####',
      '612 ### ####',
      '614 ### ####',
      '615 ### ####',
      '616 ### ####',
      '617 ### ####',
      '618 ### ####',
      '619 ### ####',
      '620 ### ####',
      '623 ### ####',
      '626 ### ####',
      '628 ### ####',
      '629 ### ####',
      '630 ### ####',
      '631 ### ####',
      '636 ### ####',
      '640 ### ####',
      '641 ### ####',
      '646 ### ####',
      '650 ### ####',
      '651 ### ####',
      '656 ### ####',
      '657 ### ####',
      '659 ### ####',
      '660 ### ####',
      '661 ### ####',
      '662 ### ####',
      '667 ### ####',
      '669 ### ####',
      '678 ### ####',
      '680 ### ####',
      '681 ### ####',
      '682 ### ####',
      '689 ### ####',
      '701 ### ####',
      '702 ### ####',
      '703 ### ####',
      '704 ### ####',
      '706 ### ####',
      '707 ### ####',
      '708 ### ####',
      '712 ### ####',
      '713 ### ####',
      '714 ### ####',
      '715 ### ####',
      '716 ### ####',
      '717 ### ####',
      '718 ### ####',
      '719 ### ####',
      '720 ### ####',
      '724 ### ####',
      '725 ### ####',
      '726 ### ####',
      '727 ### ####',
      '731 ### ####',
      '732 ### ####',
      '734 ### ####',
      '737 ### ####',
      '740 ### ####',
      '743 ### ####',
      '747 ### ####',
      '754 ### ####',
      '757 ### ####',
      '760 ### ####',
      '762 ### ####',
      '763 ### ####',
      '765 ### ####',
      '769 ### ####',
      '770 ### ####',
      '771 ### ####',
      '772 ### ####',
      '773 ### ####',
      '774 ### ####',
      '775 ### ####',
      '779 ### ####',
      '781 ### ####',
      '785 ### ####',
      '786 ### ####',
      '801 ### ####',
      '802 ### ####',
      '803 ### ####',
      '804 ### ####',
      '805 ### ####',
      '806 ### ####',
      '808 ### ####',
      '810 ### ####',
      '812 ### ####',
      '813 ### ####',
      '814 ### ####',
      '815 ### ####',
      '816 ### ####',
      '817 ### ####',
      '818 ### ####',
      '820 ### ####',
      '826 ### ####',
      '828 ### ####',
      '830 ### ####',
      '831 ### ####',
      '832 ### ####',
      '835 ### ####',
      '838 ### ####',
      '839 ### ####',
      '840 ### ####',
      '843 ### ####',
      '845 ### ####',
      '847 ### ####',
      '848 ### ####',
      '850 ### ####',
      '854 ### ####',
      '856 ### ####',
      '857 ### ####',
      '858 ### ####',
      '859 ### ####',
      '860 ### ####',
      '862 ### ####',
      '863 ### ####',
      '864 ### ####',
      '865 ### ####',
      '870 ### ####',
      '872 ### ####',
      '878 ### ####',
      '901 ### ####',
      '903 ### ####',
      '904 ### ####',
      '906 ### ####',
      '907 ### ####',
      '908 ### ####',
      '909 ### ####',
      '910 ### ####',
      '912 ### ####',
      '913 ### ####',
      '914 ### ####',
      '915 ### ####',
      '916 ### ####',
      '917 ### ####',
      '918 ### ####',
      '919 ### ####',
      '920 ### ####',
      '925 ### ####',
      '928 ### ####',
      '929 ### ####',
      '930 ### ####',
      '931 ### ####',
      '934 ### ####',
      '936 ### ####',
      '937 ### ####',
      '938 ### ####',
      '940 ### ####',
      '941 ### ####',
      '943 ### ####',
      '945 ### ####',
      '947 ### ####',
      '948 ### ####',
      '949 ### ####',
      '951 ### ####',
      '952 ### ####',
      '954 ### ####',
      '956 ### ####',
      '959 ### ####',
      '970 ### ####',
      '971 ### ####',
      '972 ### ####',
      '973 ### ####',
      '978 ### ####',
      '979 ### ####',
      '980 ### ####',
      '983 ### ####',
      '984 ### ####',
      '985 ### ####',
      '986 ### ####',
      '989 ### ####',
    ],
  },
  {
    callingCode: '+598',
    cca2: 'UY',
    flag: '🇺🇾',
    name: {
      bg: 'Уругвай',
      by: 'Уругвай',
      cn: '乌拉圭',
      cz: 'Uruguay',
      de: 'Uruguay',
      ee: 'Uruguay',
      el: 'Ουρουγουάη',
      en: 'Uruguay',
      es: 'Uruguay',
      fr: 'Uruguay',
      he: 'אורוגוואי',
      it: 'Uruguay',
      jp: 'ウルグアイ',
      nl: 'Uruguay',
      pl: 'Urugwaj',
      pt: 'Uruguai',
      ro: 'Uruguay',
      ru: 'Уругвай',
      ua: 'Уругвай',
      zh: '烏拉圭',
    },
    phoneMasks: ['# ### ## ##'],
  },
  {
    callingCode: '+998',
    cca2: 'UZ',
    flag: '🇺🇿',
    name: {
      bg: 'Узбекистан',
      by: 'Узбекістан',
      cn: '乌兹别克斯坦',
      cz: 'Uzbekistán',
      de: 'Usbekistan',
      ee: 'Usbekistan',
      el: 'Ουζμπεκιστάν',
      en: 'Uzbekistan',
      es: 'Uzbekistán',
      fr: 'Ouzbékistan',
      he: 'אוזבקיסטן',
      it: 'Uzbekistan',
      jp: 'ウズベキスタン',
      nl: 'Oezbekistan',
      pl: 'Uzbekistan',
      pt: 'Usbequistão',
      ro: 'Uzbekistan',
      ru: 'Узбекистан',
      ua: 'Узбекистан',
      zh: '烏茲別克斯坦',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+379',
    cca2: 'VA',
    flag: '🇻🇦',
    name: {
      bg: 'Свети престол (Ватикан)',
      by: 'Святы Пасад (дзяржава Ватыкан)',
      cn: '罗马 教廷（梵蒂冈城国）',
      cz: 'Svatý stolec (Vatikánský městský stát)',
      de: 'Heiliger Stuhl (Staat der Vatikanstadt)',
      ee: 'Püha Tool (Vatikani linnriik)',
      el: 'Βατικανό',
      en: 'Holy See (Vatican City State)',
      es: 'Santa Sede (Estado de la Ciudad del Vaticano)',
      fr: 'Saint-Siège (État de la Cité du Vatican)',
      he: 'הכס הקדוש (מדינת הוותיקן)',
      it: 'Santa Sede (Stato della Città del Vaticano)',
      jp: 'ホーリーシー（バチカン市国）',
      nl: 'Heilige Stoel (Vaticaanstad)',
      pl: 'Stolica Apostolska (Państwo Watykańskie)',
      pt: 'Vaticano',
      ro: 'Sfântul Scaun (Statul Vatican)',
      ru: 'Святой Престол (Vatican City State)',
      ua: 'Святий Престол (Ватикан)',
      zh: '羅馬教廷（梵蒂岡城國）',
    },
    phoneMasks: ['06 698#####'],
  },
  {
    callingCode: '+1',
    cca2: 'VC',
    flag: '🇻🇨',
    name: {
      bg: 'Сейнт Винсент и Гренадини',
      by: 'Сэнт-Вінсэнт і Грэнадыны',
      cn: '圣文森特和格林纳丁斯',
      cz: 'Svatý Vincenc a Grenadiny',
      de: 'St. Vincent und die Grenadinen',
      ee: 'Saint Vincent ja Grenadiinid',
      el: 'Άγιος Βικέντιος και Γρεναδίνες',
      en: 'Saint Vincent and the Grenadines',
      es: 'San Vicente y las Granadinas',
      fr: 'Saint-Vincent-et-les-Grenadines',
      he: 'סנט וינסנט והגרנדינים',
      it: 'Saint Vincent e Grenadine',
      jp: 'セントビンセントおよびグレナディーン諸島',
      nl: 'Saint Vincent en de Grenadines',
      pl: 'Saint Vincent i Grenadyny',
      pt: 'São Vicente e Granadinas',
      ro: 'Sfântul Vincent și Grenadine',
      ru: 'Святой Винсент и Гренадины',
      ua: 'Сент-Вінсент і Гренадини',
      zh: '聖文森特和格林納丁斯',
    },
    phoneMasks: ['784 ### ####'],
  },
  {
    callingCode: '+58',
    cca2: 'VE',
    flag: '🇻🇪',
    name: {
      bg: 'Венецуела',
      by: 'Венесуэла',
      cn: '委内瑞 拉',
      cz: 'Venezuela, Bolívarovská republika',
      de: 'Venezuela',
      ee: 'Venezuela',
      el: 'Βενεζουέλα',
      en: 'Venezuela, Bolivarian Republic of',
      es: 'Venezuela, República Bolivariana de',
      fr: 'Venezuela',
      he: 'ונצואלה',
      it: 'Venezuela',
      jp: 'ベネズエラ',
      nl: 'Venezuela',
      pl: 'Wenezuela, Republika Boliwariańska',
      pt: 'Venezuela',
      ro: 'Venezuela',
      ru: 'Венесуэла, Боливарианская Республика',
      ua: 'Венесуела, Боліваріанська Республіка',
      zh: '委內瑞拉',
    },
    phoneMasks: ['### ### ####'],
  },
  {
    callingCode: '+1',
    cca2: 'VG',
    flag: '🇻🇬',
    name: {
      bg: 'Вирджински острови, британски',
      by: 'Віргінскія астравы, Брытанія',
      cn: '英属维尔京群岛',
      cz: 'Panenské ostrovy, Britové',
      de: 'Virgin Inseln, Britisch',
      ee: 'Neitsisaared, Suurbritannia',
      el: 'Βρετανικές Παρθένοι Νήσοι',
      en: 'Virgin Islands, British',
      es: 'Islas Vírgenes Británicas',
      fr: 'Îles Vierges britanniques',
      he: 'איי הבתולה הבריטיים',
      it: 'Isole Vergini britanniche',
      jp: '英領バージン諸島',
      nl: 'Britse Maagdeneilanden',
      pl: 'Wyspy Dziewicze, Brytyjskie',
      pt: 'Ilhas Virgens Britânicas',
      ro: 'Insulele Virgine, britanice',
      ru: 'Виргинские острова, Британские',
      ua: 'Віргінські острови, Британські',
      zh: '英屬維爾京群島',
    },
    phoneMasks: ['284 ### ####'],
  },
  {
    callingCode: '+1',
    cca2: 'VI',
    flag: '🇻🇮',
    name: {
      bg: 'Вирджински острови, САЩ',
      by: 'Віргінскія астравы, ЗША',
      cn: '美国维尔京群岛',
      cz: 'Panenské ostrovy, USA',
      de: 'Jungferninseln, USA',
      ee: 'Neitsisaared, USA',
      el: 'Αμερικανικές Παρθένοι Νήσοι',
      en: 'Virgin Islands, U.S.',
      es: 'Islas Vírgenes, EE.UU.',
      fr: 'Îles Vierges américaines, États-Unis',
      he: 'איי הבתולה של ארצות הברית',
      it: 'Isole Vergini, Stati Uniti',
      jp: 'アメリカ領バージン諸島',
      nl: 'Maagdeneilanden, U.s.',
      pl: 'Wyspy Dziewicze Stanów Zjednoczonych',
      pt: 'Ilhas Virgens Americanas',
      ro: 'Insulele Virgine, S.U.A.',
      ru: 'Американские Виргинские острова',
      ua: 'Віргінські острови, США',
      zh: '美國維爾京群島',
    },
    phoneMasks: ['340 ### ####'],
  },
  {
    callingCode: '+84',
    cca2: 'VN',
    flag: '🇻🇳',
    name: {
      bg: 'Виетнам',
      by: "В'етнам",
      cn: '越南',
      cz: 'Vietnam',
      de: 'Vietnam',
      ee: 'Vietnam',
      el: 'Βιετνάμ',
      en: 'Viet Nam',
      es: 'Vietnam',
      fr: 'Viet Nam',
      he: 'וייטנאם',
      it: 'Viet Nam',
      jp: 'ベトナム',
      nl: 'Vietnam',
      pl: 'Wietnam',
      pt: 'Vietname',
      ro: 'Vietnam',
      ru: 'Вьетнам',
      ua: "В'єтнам",
      zh: '越南',
    },
    phoneMasks: ['### #### ###'],
  },
  {
    callingCode: '+678',
    cca2: 'VU',
    flag: '🇻🇺',
    name: {
      bg: 'Вануату',
      by: 'Вануату',
      cn: '瓦努阿图',
      cz: 'Vanuatu',
      de: 'Vanuatu',
      ee: 'Vanuatu',
      el: 'Βανουάτου',
      en: 'Vanuatu',
      es: 'Vanuatu',
      fr: 'Vanuatu',
      he: 'ונואטו',
      it: 'Vanuatu',
      jp: 'バヌアツ',
      nl: 'Vanuatu',
      pl: 'Vanuatu',
      pt: 'Vanuatu',
      ro: 'Vanuatu',
      ru: 'Вануату',
      ua: 'Вануату',
      zh: '瓦努阿圖',
    },
    phoneMasks: ['#####', '## #####'],
  },
  {
    callingCode: '+681',
    cca2: 'WF',
    flag: '🇼🇫',
    name: {
      bg: 'Уолис и Футуна',
      by: 'Уоліс і Футуна',
      cn: '瓦利 斯和富图纳群岛',
      cz: 'Wallis a Futuna',
      de: 'Wallis und Futuna',
      ee: 'Wallis ja Futuna',
      el: 'Ουώλλις και Φουτούνα',
      en: 'Wallis and Futuna',
      es: 'Wallis y Futuna',
      fr: 'Wallis et Futuna',
      he: 'וואליס ופוטונה',
      it: 'Wallis e Futuna',
      jp: 'ウォリス・フ ツナ',
      nl: 'Wallis en Futuna',
      pl: 'Wallis i Futuna',
      pt: 'Wallis e Futuna',
      ro: 'Wallis și Futuna',
      ru: 'Уоллис и Футуна',
      ua: 'Уолліс і Футуна',
      zh: '瓦利斯和富圖納群島',
    },
    phoneMasks: ['### ###'],
  },
  {
    callingCode: '+685',
    cca2: 'WS',
    flag: '🇼🇸',
    name: {
      bg: 'Самоа',
      by: 'Самоа',
      cn: '萨摩亚',
      cz: 'Samoa',
      de: 'Samoa',
      ee: 'Samoa',
      el: 'Σαμόα',
      en: 'Samoa',
      es: 'Samoa',
      fr: 'Samoa',
      he: 'סמואה',
      it: 'Samoa',
      jp: 'サモア',
      nl: 'Samoa',
      pl: 'Samoa',
      pt: 'Samoa',
      ro: 'Samoa',
      ru: 'Самоа',
      ua: 'Самоа',
      zh: '薩摩亞',
    },
    phoneMasks: ['## ####'],
  },
  {
    callingCode: '+967',
    cca2: 'YE',
    flag: '🇾🇪',
    name: {
      bg: 'Йемен',
      by: 'Емен',
      cn: '也门',
      cz: 'Jemen',
      de: 'Jemen',
      ee: 'Jeemen',
      el: 'Υεμένη',
      en: 'Yemen',
      es: 'Yemen',
      fr: 'Yémen',
      he: 'תימן',
      it: 'Yemen',
      jp: ' イエメン',
      nl: 'Jemen',
      pl: 'Jemen',
      pt: 'Iémen',
      ro: 'Yemen',
      ru: 'Йемен',
      ua: 'Ємен',
      zh: '也門',
    },
    phoneMasks: ['# ### ###', '## ### ###', '### ### ###'],
  },
  {
    callingCode: '+262',
    cca2: 'YT',
    flag: '🇾🇹',
    name: {
      bg: 'Майот',
      by: 'Маёта',
      cn: '马约特岛',
      cz: 'Mayotte',
      de: 'Mayotte',
      ee: 'Mayotte',
      el: 'Μαγιότ',
      en: 'Mayotte',
      es: 'Mayotte',
      fr: 'Mayotte',
      he: 'מיוט',
      it: 'Mayotte',
      jp: 'マヨット',
      nl: 'Mayotte',
      pl: 'Majotta',
      pt: 'Mayotte',
      ro: 'Mayotte',
      ru: 'Майотта',
      ua: 'Майотта',
      zh: '馬約特島',
    },
    phoneMasks: ['269 ### ###', '639 ### ###'],
  },
  {
    callingCode: '+27',
    cca2: 'ZA',
    flag: '🇿🇦',
    name: {
      bg: 'Южна Африка',
      by: 'Паўднёвая Афрыка',
      cn: '南非',
      cz: 'Jižní Afrika',
      de: 'Südafrika',
      ee: 'Lõuna-Aafrika',
      el: 'Νότια Αφρική',
      en: 'South Africa',
      es: 'Sudáfrica',
      fr: 'Afrique du Sud',
      he: 'דרום אפריקה',
      it: 'Sud Africa',
      jp: '南アフリカ',
      nl: 'Zuid-Afrika',
      pl: 'Afryka Południowa',
      pt: 'África do Sul',
      ro: 'Africa de Sud',
      ru: 'Южная Африка',
      ua: 'Південна Африка',
      zh: '南非',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+260',
    cca2: 'ZM',
    flag: '🇿🇲',
    name: {
      bg: 'Замбия',
      by: 'Замбія',
      cn: '赞比亚',
      cz: 'Zambie',
      de: 'Sambia',
      ee: 'Sambia',
      el: 'Ζάμπια',
      en: 'Zambia',
      es: 'Zambia',
      fr: 'Zambie',
      he: 'זמביה',
      it: 'Zambia',
      jp: 'ザンビア',
      nl: 'Zambia',
      pl: 'Zambia',
      pt: 'Zâmbia',
      ro: 'Zambia',
      ru: 'Замбия',
      ua: 'Замбія',
      zh: '讚比亞',
    },
    phoneMasks: ['## ### ####'],
  },
  {
    callingCode: '+263',
    cca2: 'ZW',
    flag: '🇿🇼',
    name: {
      bg: 'Зимбабве',
      by: 'Зімбабвэ',
      cn: '津巴布韦',
      cz: 'Zimbabwe',
      de: 'Zimbabwe',
      ee: 'Zimbabwe',
      el: 'Ζιμπάμπουε',
      en: 'Zimbabwe',
      es: 'Zimbabue',
      fr: 'Zimbabwe',
      he: 'זימבבואה',
      it: 'Zimbabwe',
      jp: 'ジンバブエ',
      nl: 'Zimbabwe',
      pl: 'Zimbabwe',
      pt: 'Zimbabué',
      ro: 'Zimbabwe',
      ru: 'Зимбабве',
      ua: 'Зімбабве',
      zh: '津巴布韋',
    },
    phoneMasks: ['# ######'],
  },
];
